import React from "react";
import { Link } from "react-router-dom";
import "./button.css";

function Button({ props }) {
  const { content, color, rounded, noBackground, functionAfterClick, size } =
    props;

  const btnSizeStyle = size && size == "mini" ? { padding: "2px 5px" } : {};

  return (
    <button
      className={
        (noBackground
          ? "btnNoBackground btnNoBackground" + color
          : "btnBg btnBg" + color) +
        " " +
        (rounded ? "btnRounded" : "btnSimple")
      }
      style={btnSizeStyle}
      onClick={() => {
        functionAfterClick && functionAfterClick(true);
      }}
    >
      {content}
    </button>
  );
}

export function ButtonLink({ props }) {
  const { content, link } = props;
  return (
    <Link to={link} className={"btnLinks"}>
      {content}
    </Link>
  );
}

export function DashGreenButton({ content, link, ...props }) {
  return (
    <Link to={link} className={"btnGreenLins"} {...props}>
      {content}
    </Link>
  );
}

export function BorderedDashGreenButton({ content, link, ...props }) {
  return (
    <Link to={link} className={"BorderGreenLins"} {...props}>
      {content}
    </Link>
  );
}

export function SeeDocumentButton({ content }) {
  return (
    <button
      style={{
        background: "#e1edff",
        width: "100%",
      }}
    >
      {content}
    </button>
  );
}
export function ApprouvedButton({ content }) {
  return (
    <button
      style={{
        background: "#0d6e29",
        color: "white",
        border: "none",
        width: "100%",
      }}
    >
      Approuver
    </button>
  );
}
export function ActionButton({ content, background, color, ...props }) {
  return (
    <button
      style={{
        border: "none",
        // width: "70%",
        borderRadius: "3px",
      }}
      {...props}
    >
      {content}
    </button>
  );
}

export default Button;
