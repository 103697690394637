import { createContext, useContext, useEffect, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIO] = useState(false);
  const [content, setContent] = useState(null);
  const [offCanvas, setOC] = useState(false);

  const showModal = (content, offCanvas = false) => {
    setIO(true);
    setContent(content);
    setOC(offCanvas);
  };
  const hideModal = () => {
    setIO(false);
    setContent(null);
  };

  useEffect(() => {
    if (isOpen === true) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "initial";
    }
  }, [isOpen]);

  const values = {
    showModal,
    hideModal,
    content,
    isOpen,
    offCanvas,
  };

  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
