import React, { useState, useEffect } from "react";
import { BorderedDashGreenButton } from "../../../ui-kits/button/button";
import { useParams } from "react-router";
import { Spinner } from "../../../assets/icons/Icon";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import withAuth from "../../../hoc/WithAuth";

const NoticesWritter = () => {
  const { requestId } = useParams();
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorChange] = useState("");

  // const navigate = useNavigate();

  const handleChange = (value) => {
    setEditorChange(value);
  };
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"],
    [
      {
        color: [
          "#000000",
          "#FF0000",
          "#00FF00",
          "#0000FF",
          "#FFFF00",
          "#FF00FF",
          "#00FFFF",
          "#800000",
          "#008000",
          "#000080",
          "#808080",
        ],
      },
    ],
  ];

  const handeAddNotes = async () => {
    setLoading(true);
    try {
      await axios.post(`client-requests/note/add`, {
        clientRequestId: requestId,
        content: editorState,
      });
      toast.success("Votre note a été enregistrer");
      setEditorChange("");
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  return (
    <div className="add-request-components dashboard-base-param">
      <h2 style={{ textAlign: "center" }}>
        Rédiger Un compte de rendu de votre travail
      </h2>
      <div className="editor-container">
        <ReactQuill
          value={editorState}
          onChange={handleChange}
          modules={{ toolbar: toolbarOptions }}
        />
      </div>
      <BorderedDashGreenButton
        content={loading === true ? <Spinner /> : "Enregistrer la note"}
        link=""
        onClick={() => handeAddNotes()}
      />
    </div>
  );
};

export default withAuth(NoticesWritter, ["agent", "investigator"]);
