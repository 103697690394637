import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Img } from "../../ui-kits/kits";
import { TableofData } from "../../ui-kits/table";
import { PhPlusCircleBold } from "../../assets/icons/Icon";
import { ConfirmModal, OperationStatusModal } from "../../ui-kits/Modal";
import withAuth from "../../hoc/WithAuth";
import { useAuth } from "../../context/Auth";
import { useModal } from "../../context/Modal";

const InvestigationDemandesByMunicipalitie = () => {
  const { user } = useAuth();
  const { municipalitie } = useParams();
  const [loading, setLoading] = useState(true);
  const [availableMission, setAvailableMission] = useState([]);
  const auth = user;
  const { showModal } = useModal();

  const getInvestigationsMission = async () => {
    try {
      const response = await axios.get(
        `/client-requests/all?paid=true&type=Investigations immobilières&investigationMunicipalitie=${municipalitie}`
      );
      const response2 = await axios.get(
        `client-requests/participation-request`
      );

      // const mission = response.data.data.filter((item) =>
      //   item.hasOwnProperty("agentId")
      // );
      const filteredResponse2Data = response2.data.data
        .filter((item) => item.investigatorId === auth._id)
        .map((item) => ({
          requestId: item.clientRequestId._id,
          ...item,
        }));
      setAvailableMission(
        response2.data.data.length === 0
          ? response.data.data
          : filteredResponse2Data.length === 0
          ? response.data.data
          : response.data.data.filter((item) => {
              return filteredResponse2Data.every(
                (obj) => obj.requestId !== item._id
              );
            })
      );
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  const makeMissionRequest = async (folderId) => {
    try {
      const response = await axios.post(
        `/client-requests/investigator/participation-request`,
        {
          clientRequestId: folderId,
          investigatorId: auth._id,
        }
      );
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message:
              "La demande a été envoyer vous recevrez un mail après confirmation de votre demande",
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, [5000]);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
    }
  };

  const handleAddParticipation = (type, item, e) => {
    if (type === "dot") {
      showModal(
        <ConfirmModal
          text={`Confirmer la demande de participation ?`}
          handle={() => makeMissionRequest(item._id)}
        />
      );
    }
  };
  const tableHead = [
    // {
    //   name: "clientFullname",
    //   label: "Nom & Prénoms",
    // },
    {
      name: "investigationMunicipalitie",
      label: "Commune de l'investigation",
    },
    // {
    //   name: "type",
    //   label: "Type de demande",
    // },
    // {
    //   name: "createdAt",
    //   label: "Date de création",
    // },
    { name: "option", label: "Option" },
  ];
  const action = [
    {
      action: "dot",
      icon: (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            color: "white",
            gap: "10px",
            background: "green",
            padding: "5px 10px",
          }}
        >
          <PhPlusCircleBold /> Participer
        </span>
      ),
    },
  ];
  useEffect(() => {
    getInvestigationsMission();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          {availableMission.length > 0 ? (
            <>
              <h2>Les Investigations disponible sur {municipalitie}</h2>
              <TableofData
                tableHead={tableHead}
                tableBody={availableMission}
                handle={handleAddParticipation}
                option={action}
                hideFilter
              />
            </>
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Les missions ne réclament pas d'investigateur pour le moment.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(InvestigationDemandesByMunicipalitie, ["investigator"]);
