import React from "react";
import HomeActivity from "../routesComponents/home/HomeActivity";
import HomeBanner from "../routesComponents/home/HomeBanner";
import HomeBecomeAgent from "../routesComponents/home/HomeBecomeAgent";
import HomeInformation from "../routesComponents/home/HomeInformation";
import withoutAuth from "../hoc/WithoutAuth";

const Home = () => {
  return (
    <div>
      <HomeBanner />
      <HomeActivity />
      <HomeInformation />
      <HomeBecomeAgent />
    </div>
  );
};

export default withoutAuth(Home);
