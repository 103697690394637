import React from "react";
import withoutAuth from "../hoc/WithoutAuth";

const Terms = () => {
  return (
    <div className="termsParagraphe">
      <div className="afterAboutBanner" style={{ marginBottom: "40px" }}></div>
      <h1>CONDITIONS GÉNÉRALES D'UTILISATION</h1>

      <section>
        <h2>1 - INTRODUCTION</h2>
        <p>
          Les présentes conditions générales régissent la relation contractuelle
          entre l'entreprise REAL ESTATES FRAUD INVESTIGATOR connue sous le
          sigle REFI et les utilisateurs de sa plateforme{" "}
          <a href="www.refi.bj">www.refi.bj</a> . En engageant les services de
          REFI, vous acceptez d'être lié par ces conditions générales.
        </p>
      </section>

      <section>
        <h2>2 - SERVICES OFFERTS</h2>
        <p>
          REFI propose trois services essentiellement utiles pour garantir la
          réalisation d'un investissement immobilier sécurisé. Il s’agit
          essentiellement de :
        </p>
        <ul>
          <li>- INVESTIGATIONS IMMOBILIÈRES</li>
          <li>- CONSEILS & ORIENTATIONS</li>
          <li>- VEILLE IMMOBILIÈRE</li>
        </ul>
      </section>

      <section>
        <h2>3 - QUALITÉ DES SERVICES</h2>
        <p>
          REFI s'engage à fournir des services de qualité supérieure en matière
          de sécurisation d'investissement immobilier, en utilisant des moyens
          et des technologies adaptées pour répondre aux besoins spécifiques de
          chaque client conformément aux règlementations légales en vigueurs au
          Benin.
        </p>
      </section>

      <section>
        <h2>4 - PERSONNEL QUALIFIÉ</h2>
        <p>
          REFI dispose d’une ressource humaine qualifiée et entièrement dédiée à
          la satisfaction des utilisateurs de la plateforme.
        </p>
      </section>
      <section>
        <h2>5 - CONFIDENTIALITÉ</h2>
        <p>
          REFI s’engage à protéger votre vie privée en assurant la sécurité et
          la protection des informations que vous nous communiquez lors de votre
          expérience avec nous.
        </p>
      </section>
      <section>
        <h2>6 - INFORMATIONS EXACTES</h2>
        <ul>
          <li>
            - L'utilisateur s'engage à fournir des informations exactes le
            concernant, si les formulaires de REFI l'exigent.
          </li>
          <li>
            - L'utilisateur s'engage directement ou indirectement à fournir des
            informations exactes et complètes sur la propriété immobilière pour
            laquelle il sollicite les services de REFI.
          </li>
          <li>
            - L'utilisateur, étant majeur et jouissant de toutes ses facultés
            mentales, reconnaît être entièrement responsable des conséquences
            juridiques en cas de poursuites pour fausses informations, faux
            documents et usage de faux lors de son expérience avec REFI.
          </li>
        </ul>
      </section>
      <section>
        <h2>7 - RESPECT DES CONSIGNES</h2>
        <p>Le client s'engage :</p>
        <ul>
          <li>
            - À sécuriser l’adresse e-mail qui lui donne accès à la plateforme{" "}
            <a href="www.refi.bj">www.refi.bj</a>
          </li>
          <li>- À fournir des documents bien lisibles.</li>
          <li>
            - À effectuer les paiements des frais liés à un dossier ouvert
            auprès de REFI et à en conserver les preuves pour servir et valoir
            ce que de droit en cas de difficulté.
          </li>
        </ul>
      </section>
      <section>
        <h2>8 - LIMITATION DE RESPONSABILITÉ</h2>
        <ul>
          <li>
            - En plus de respecter les mesures et directives spécifiées dans les
            documents fournis par REFI à ses utilisateurs, REFI ne pourra en
            aucun cas être tenue responsable des dommages indirects, consécutifs
            ou spéciaux résultant de l'utilisation de ses services.
          </li>
          <li>
            - Tout événement dû à un cas de force majeure ayant pour conséquence
            un dysfonctionnement du réseau ou du serveur n'engage pas la
            responsabilité de REAL ESTATES FRAUD INVESTIGATOR.
          </li>
          <li>
            - L'accès aux services du site <a href="www.refi.bj">www.refi.bj</a>{" "}
            peut à tout moment faire l'objet d'une interruption, d'une
            suspension, d'une modification sans préavis pour une maintenance ou
            pour tout autre cas. L'utilisateur s'oblige à ne réclamer aucune
            indemnisation de ce fait.
          </li>
          <li>
            - En approuvant ces conditions générales, l'utilisateur reconnaît et
            accepte que toute réparation de dommages sera conforme aux
            dispositions du Code civil en la matière, notamment les articles
            1240 et 1241 du Code civil.
          </li>
          <li>
            - REFI fournit des conseils et des orientations en matière
            d'investissement immobilier. Toutefois, REFI ne saurait être tenue
            responsable des décisions d'investissement prises par ces
            utilisateurs sur la base de ces conseils, sauf en cas de faute
            professionnelle avérée. Dans le cas où une faute, une négligence ou
            une imprudence de la part de REFI serait prouvée, REFI s'engage à
            réparer les dommages conformément aux dispositions du Code civil,
            incluant les articles 1240 et 1241.
          </li>
        </ul>
      </section>
      <section>
        <h2>9 - ASSURANCE</h2>
        <p>
          REFI explore activement la possibilité d'assurer à 100 % les risques
          liés à ses activités en maintenant des discussions continues avec des
          compagnies d'assurances locales et internationales. L'utilisateur de
          la plateforme <a href="www.refi.bj">www.refi.bj</a> demeure
          responsable et est libre de souscrire à une assurance adéquate pour
          couvrir les risques associés à son expérience sur{" "}
          <a href="www.refi.bj">www.refi.bj</a> avec REAL ESTATES FRAUD
          INVESTIGATOR (REFI), si cela lui est impérativement utile à une fin
          spécifique.
        </p>
      </section>
      <section>
        <h2>10 - DURÉE ET RÉSILIATION</h2>
        <p>Durée du contrat entre l'utilisateur et REFI</p>
        <ul>
          <li>
            - Le contrat entre REFI et l'utilisateur est établi pour une durée
            indéterminée, tant que l'utilisateur juge utile de maintenir la
            relation active. Chaque utilisateur est libre de solliciter les
            services de REFI à tout moment et de mettre fin à la relation sans
            réclamer de dédommagement. - REFI garantit un service de qualité
            dans les plus brefs délais, ces délais étant fixés en fonction des
            discussions actives avec ses partenaires techniques.
          </li>
          <li>
            - REFI garantit un service de qualité dans les plus brefs délais,
            ces délais étant fixés en fonction des discussions actives avec ses
            partenaires techniques.
          </li>
        </ul>
      </section>
      <section>
        <h2>11 - TARIFS</h2>
        <p>
          Les tarifs de chaque service proposé par REFI sont communiqués à
          l'utilisateur sur la plateforme. Après le paiement du tarif pour le
          service choisi, l'utilisateur reçoit des notifications ainsi qu'une
          quittance de paiement délivrée par notre partenaire local KAKIAPAY. Il
          est à noter que ces frais sont non remboursables.
        </p>
      </section>
      <section>
        <h2>12 - PAIEMENT</h2>
        <ul>
          <li>
            - L'utilisateur s'engage à régler les frais facturés sur son dossier
            selon les modalités définies sur la plateforme. Tout retard de
            paiement pourrait entraîner la révision des frais applicables et
            éventuellement la suspension des services en cas de non-paiement qui
            perdure sans motif.
          </li>
          <li>
            - Tous les paiements liés à l'utilisation de la plateforme{" "}
            <a href="www.refi.bj">www.refi.bj</a> doivent être effectués en
            ligne via la plateforme. Aucun utilisateur, quel que soit son rôle,
            n'est autorisé à collecter de l'argent pour le compte de REFI.
          </li>
          <li>
            - REFI précise que jamais aucun utilisateur de la plateforme, quelle
            que soit sa nature, n'est autorisé à percevoir de l'argent pour le
            compte de la plateforme.
          </li>
          <li>
            - Pour une meilleure expérience utilisateur, REFI encourage
            fortement le paiement des frais liés à la prestation de services à
            partir d'un moyen de paiement portant le nom du demandeur du
            service, et ce dernier doit conserver la preuve de paiement.
          </li>
          <li>
            - REFI ne perçoit jamais d'argent autre que ce qui est strictement
            facturé pour la prestation de services à un demandeur.
          </li>
          <li>
            - Il est de la responsabilité de tous les utilisateurs de la
            plateforme de respecter cette politique.
          </li>
        </ul>
      </section>
      <section>
        <h2>13 - MODIFICATIONS DES CONDITIONS GÉNÉRALES</h2>
        <p>
          REFI se réserve le droit de modifier les présentes conditions
          générales à tout moment. Le projet de modification sera communiqué à
          l'avance et les modifications seront portées à la connaissance des
          utilisateurs avant leur entrée en vigueur, dans un délai raisonnable,
          conformément aux dispositions légales en vigueur au Bénin.
        </p>
      </section>
      <section>
        <h2>14 - LÉGISLATION APPLICABLE ET JURIDICTION COMPÉTENTE</h2>
        <p>
          Les présentes conditions générales d'utilisation sont régies par les
          lois en vigueur en République du Bénin. En cas de litige relatif à
          l'interprétation ou à l'exécution de ces conditions, les parties
          s'engagent prioritairement à rechercher une solution amiable. À défaut
          d'accord amiable, le litige sera porté devant les tribunaux compétents
          du Bénin.
        </p>
      </section>
    </div>
  );
};

export default withoutAuth(Terms);
