import React, { useEffect, useState } from "react";
import { TableofData } from "../../ui-kits/table";
import { DashGreenButton } from "../../ui-kits/button/button";
import { toast } from "react-toastify";
import axios from "axios";
import { Img } from "../../ui-kits/kits";
import { OperationStatusModal } from "../../ui-kits/Modal";
import { Spinner } from "../../assets/icons/Icon";
import withAuth from "../../hoc/WithAuth";
import { useAuth } from "../../context/Auth";
import { useModal } from "../../context/Modal";

const tableHeadApporteur = [
  { name: "clientFullname", label: "Filleul" },
  { name: "type", label: "Type de demande" },
  // { name: "cost", label: "Prix du dossier" },
  { name: "commission", label: "Commission" },
  { name: "option", label: "Cochez les paiements voulu" },
  // { name: "status", label: "Statut" },
];

const ComissionList = () => {
  const { user } = useAuth();
  const authed = user;
  const baseUrl = window.location.origin;
  const { showModal } = useModal();

  const [sponsoredData, setSponsoredData] = useState([]);

  const [loading, setLoading] = useState(true);

  const totalCommission = sponsoredData.reduce(
    (total, item) => total + item.commission,
    0
  );

  const [commissionWantPayArray, setCWPA] = useState([]);

  const handleAction = (type, item) => {
    if (type === "check") {
      if (!item.commission) {
        toast.info("La commission n'a pas encore été calculée.");
      } else {
        if (
          commissionWantPayArray.some(
            (comArrays) => comArrays.clientRequestId === item._id
          )
        ) {
          const copy = commissionWantPayArray.filter(
            (tabs) => tabs.clientRequestId !== item._id
          );
          setCWPA(copy);
        } else {
          setCWPA((prev) => [
            ...prev,
            {
              businessFinderId: authed._id,
              clientRequestId: item._id,
              cost: item.commission,
            },
          ]);
        }
      }
    }
  };
  const action = [
    {
      action: "check",
    },
  ];

  const getAllSponsoredClient = async () => {
    try {
      const response = await axios.get(`user/sponsored/${authed.sponsorToken}`);
      setSponsoredData(
        response.data
          .filter((item) => item.hasCommissionRequest === null)
          .map((item) => ({
            ...item,
            clientFullname: item.clientId.fullName,
            commission: item.cost * 0.1,
          }))
      );

      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    getAllSponsoredClient();
  }, []);

  const postCommission = async () => {
    const promises = commissionWantPayArray.map(async (item) => {
      const response = await axios.post("/commission-request/add", item);
      return response.data;
    });

    return Promise.all(promises);
  };

  const [commissionLoading, setCommissionLoading] = useState(false);
  const handlePostCommissionRequest = () => {
    setCommissionLoading(true);
    postCommission()
      .then(() => {
        setCWPA([]);
        setCommissionLoading(false);
        showModal(
          <OperationStatusModal
            props={{
              type: "success",
              message:
                "Demande effectuée. Vous recevrez dans les plus bref délais un email après validation",
            }}
          />
        );
        setTimeout(() => {
          window.location.reload();
        }, [2000]);
      })
      .catch((error) => {
        setCommissionLoading(false);
        showModal(
          <OperationStatusModal
            props={{
              type: "error",
              message: "Une erreur s'est produite",
            }}
          />
        );
      });
  };

  return (
    <div className="add-request-components dashboard-base-param">
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Mes commissions</h2>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <h3>Votre Lien d'affliation : </h3>
            <span
              style={{
                fontSize: "14px",
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >{
              `
            ${authed.sponsorKlooLink}
            `
              // ${baseUrl}/Signup?sponsorToken=${authed.sponsorToken}
            }</span>
          </div>

          <DashGreenButton
            content="Copier le lien"
            link=""
            onClick={() => {
              navigator.clipboard.writeText(`${authed.sponsorKlooLink}`);
              toast.info("Le lien a été copié dans votre presse papier");
            }}
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            <h3>
              Total des commission :{" "}
              <span style={{ color: "black" }}>{totalCommission} Fcfa</span>
            </h3>
          </div>

          {sponsoredData.length > 0 ? (
            <>
              {commissionWantPayArray.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <div>
                      Paiement voulu :{" "}
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        {commissionWantPayArray.reduce(
                          (total, item) => total + item.cost,
                          0
                        )}{" "}
                        Fcfa
                      </span>
                    </div>
                    <DashGreenButton
                      content={
                        commissionLoading ? (
                          <Spinner />
                        ) : (
                          "Fait la demande de paiement"
                        )
                      }
                      onClick={() => handlePostCommissionRequest()}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <TableofData
                tableHead={tableHeadApporteur}
                tableBody={sponsoredData}
                option={action}
                handle={handleAction}
                hideTop
                commissionWantPayArray={commissionWantPayArray}
                filterOption={[
                  {
                    label: "Type de demande",
                    value: "type",
                  },
                  {
                    label: "Nom & Prénoms",
                    value: "clientFullname",
                  },
                  {
                    value: "commission",
                    label: "Montant de la commission",
                  },
                ]}
                // workerFilter={true}
              />
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "50px",
                fontSize: "20px",
              }}
            >
              Pas de nouvelle commission pour l'instant
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(ComissionList, ["businessFinder"]);
