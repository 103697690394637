import React, { useEffect, useState } from "react";
import { Popover } from "@material-ui/core";
import { ConfirmModal, ModalAddTestimony } from "../../ui-kits/Modal";
import { Img } from "../../ui-kits/kits";
import {
  PhDotsThreeOutlineFill,
  PhDotsThreeOutlineVerticalBold,
  PopoverIcon,
} from "../../assets/icons/Icon";
import { DashGreenButton } from "../../ui-kits/button/button";
import axios from "axios";
import { toast } from "react-toastify";
import withAuth from "../../hoc/WithAuth";
import { useModal } from "../../context/Modal";

const TestimonyConfigurate = () => {
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedTestimony, setST] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [testimonyList, setTestimonyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleOpenPopover = (item, e) => {
    setST(item);
    setAnchorElement(e?.currentTarget);
    setVisiblePopover(true);
  };
  const ClosePopover = () => {
    setAnchorElement(null);
    setVisiblePopover(false);
  };

  const { hideModal, showModal } = useModal();

  const getAllTestimony = async () => {
    try {
      const response = await axios.get(`notice/`);
      setTestimonyList(response.data.data.data);
      setLoading(false);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };
  const deleteTestimony = async (item) => {
    hideModal();
    toast.info("Suppression du témoignage");
    try {
      await axios.delete(`notice/` + item._id);
      toast.success("Opération réussir");
      setTimeout(() => {
        window.location.reload();
      }, [2000]);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
    }
  };
  useEffect(() => {
    getAllTestimony();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <Popover
            id="popover"
            open={visiblePopover}
            anchorEl={anchorElement}
            onClose={ClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ul>
              <li
                onClick={() => {
                  ClosePopover();
                  showModal(
                    <ConfirmModal
                      text={`Etes vous sûr de vouloir supprimer ce témoignage ?`}
                      handle={() => deleteTestimony(selectedTestimony)}
                    />
                  );
                }}
              >
                <PopoverIcon /> Effacer
              </li>
            </ul>
          </Popover>
          <h2>Gerer les témoignages collectés</h2>
          <div className="parteners-containers">
            {testimonyList.length > 0
              ? testimonyList.map((item, ind) => (
                  <div className="partners-container">
                    <div key={ind} className="pc-head">
                      <img src={item.picture} alt="" />
                      <p>{item.username}</p>
                      <div onClick={(e) => handleOpenPopover(item, e)}>
                        <PhDotsThreeOutlineFill />
                      </div>
                    </div>
                    <div>{item.content}</div>
                  </div>
                ))
              : "Pas de Témoignagne disponible"}
          </div>
          <div>
            <DashGreenButton
              content="Ajouter un témoignage"
              link=""
              onClick={() => showModal(<ModalAddTestimony />)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default withAuth(TestimonyConfigurate, ["superAdmin"]);
