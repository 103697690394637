import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Img } from "../../ui-kits/kits";
import { useNavigate } from "react-router-dom";
import withAuth from "../../hoc/WithAuth";

const InvestigationDemandesMunicipalities = () => {
  const [loading, setLoading] = useState(true);
  const [municipalitiesData, setMunicipalitiesData] = useState([]);
  const navigate = useNavigate();
  const getMunicipalities = async () => {
    try {
      const response = await axios.get(
        "/client-requests/municipalities?clientRequestType=Investigations immobilières"
      );
      setLoading(false);
      setMunicipalitiesData(response.data.data);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };
  useEffect(() => {
    getMunicipalities();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Liste des communes ou des investigateurs sont requis</h2>
          {municipalitiesData && municipalitiesData.length > 0 ? (
            <div className="reports">
              {municipalitiesData.map((item, ind) => (
                <div className="info" key={ind}>
                  <div>
                    <p>{item._id.investigationMunicipalitie} </p>
                  </div>
                  <div className="update">
                    <button
                      onClick={() =>
                        navigate(
                          `/InvestigationDemandesMunicipalities/${item._id.investigationMunicipalitie}`
                        )
                      }
                    >
                      Voir les missions de cette commune
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Pas de de mission disponible
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(InvestigationDemandesMunicipalities, ["investigator"]);
