import React, { useState, useEffect } from "react";
import btnLinks from "../../dataRaws/btnLinks";
import { langSelector } from "../../helpers/langs";
import { BackgroundButton, ColoredButton, Img } from "../../ui-kits/kits";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "../../assets/icons/Icon";
import BecomeAgentForm from "./BecomeAgentComponents/BecomeAgentForm";
import { useModal } from "../../context/Modal";

const HomeBanner = () => {
  const l = langSelector("homePage").homeBanner;
  const [listParteners, setListParteners] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showModal } = useModal();

  const getAllPartners = async () => {
    try {
      const response = await axios.get(`partner/`);
      setListParteners(response.data.data.data);
      setLoading(false);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };

  const showCanditatureModal = () => {
    showModal(<BecomeAgentForm />, true);
  };
  useEffect(() => {
    getAllPartners();
  }, []);
  return (
    <div className="banner">
      <div className="container">
        <div>
          <h1>
            Acquiert ton bien immobilier en toute sécurité et sans risque de
            litige.
          </h1>
          <p>
            Partout dans le monde, le prix d'un bien immobilier est influencé
            par son emplacement relatif à certains éléments, par le niveau de
            sécurité attesté par ses documents, et par les conditions dans
            lesquelles le vendeur souhaite procéder à la vente.
          </p>
          <div>
            <BackgroundButton
              route={btnLinks.client_make_request_Link}
              label={l.info1.buttonText}
              style={{
                background: "#0D6E29",
                border: "2px solid #0D6E29",
                color: "#FFFFFF",
              }}
              bordered
            />
            <ColoredButton
              label={"Travailler avec nous"}
              style={{
                background: "transparent",
                border: "2px solid #FFFFFF",
                color: "#FFFFFF",
              }}
              onClick={() => showCanditatureModal()}
              bordered
            />
          </div>
        </div>
      </div>
      <div className="band">
        <h3>
          Consultez notre liste, bien que non exhaustive, de partenaires et
          clients.
        </h3>
        <div>
          {loading ? (
            <Spinner />
          ) : listParteners.length > 0 ? (
            listParteners.map((item) => (
              <a
                href={item.partner_link}
                target="_blank"
                rel="noopener noreferrer"
                key={item.partner_name}
              >
                <img src={item.logo_url} alt="" key={item.partner_name} />
              </a>
            ))
          ) : (
            "Pas de partenaires disponible"
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
