import React from "react";
import {
  IonManSharp,
  PhFolderNotchOpenBold,
  PhMedalBold,
} from "../assets/icons/Icon";
import Photo1 from "../assets/img/photoForAbout1.webp";
import Photo2 from "../assets/img/photoForAbout2.webp";
import withoutAuth from "../hoc/WithoutAuth";

function About(props) {
  // const ArrayForAboutBanerIcones = [
  //   { icone: <PhFolderNotchOpenBold />, text: "500 +" },
  //   { icone: <IonManSharp />, text: "500 +" },
  //   { icone: <PhMedalBold />, text: "5 +" },
  // ];

  const ArrayForAboutBannerText = [
    {
      title: "Qui sommes Nous?",
      text: `REFI offre à ses clients un ensemble essentiel de services dans le
      domaine de l'investissement immobilier, permettant d'éviter les
      pièges fréquents qui mènent souvent à des litiges interminables.
      Composée d'experts expérimentés, notre équipe travaille en
      synergie pour répondre efficacement à vos attentes.`,
    },
    {
      title: "Notre Vision",
      text: "Notre vision est nourrie par l'espoir de voir le pays résoudre de manière définitive tous les conflits fonciers, quelle que soit leur nature.",
    },
    {
      title: "Nos Objectifs",
      text: `Nos objectifs incluent le respect de la législation par chaque propriétaire foncier et la vérification de la conformité aux normes légales en vigueur par tout acquéreur de bien immobilier. Nous sommes engagés dans la lutter contre le phénomène de la Mafia Foncière dans le pays, tout en travaillant à sensibiliser et renforcer les compétences des acteurs de l'immobilier à l'échelle nationale.`,
    },
  ];
  return (
    <div>
      <div className="block"></div>
      <div className="afterAboutBanner">
        <div className="afterAboutContent">
          <div className="afterAboutContentImage1">
            <img src={Photo1} alt="" />
          </div>

          <div className="afterAboutContentImage2">
            <img src={Photo2} alt="" />
          </div>
        </div>
      </div>
      <div className="aboutParagraphe">
        {ArrayForAboutBannerText.map((el, index) => {
          return (
            <div key={`Paragraphe` + index} className="aboutParagrapheContent">
              <h2>{el.title}</h2>
              <p>{el.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withoutAuth(About);
