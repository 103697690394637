import React, { useState } from "react";
import { Formik, Form } from "formik";
import {
  SubmitBorderButton,
  SubmitBackgroundButton,
} from "../../../../ui-kits/kits";
import { FormikInputFile } from "../../../../ui-kits/form";

const AskInfo = (props) => {
  const { next, setCurrentStep, setDistance, data, validationSchema, setData } =
    props;
  const handleSubmit = (values) => {
    next(values);
  };
  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
    setDistance((prev) => prev - 45);
  };

  const [filesByTypeRequest, setFilesByTypeRequest] = useState({
    "Veille immobilière": [
      {
        label: `les documents du terrain`,
        name: "land_documents",
      },
      {
        label: `Levé topographique récent du terrain.`,
        name: "topographic_survey",
      },
      {
        label: `Autres documents de l’objet`,
        name: "other_documents",
      },
    ],
    "Conseils & Orientations": [
      {
        label: `Copie d'une pièce d'identité valide du demandeur.`,
        name: "identity_card",
      },
      {
        label: `Levé topographique récent du terrain.`,
        name: "topographic_survey",
      },
      {
        label: `Tous les documents qui prouvent votre droit de propriété`,
        name: "other_documents",
      },
    ],
    "Investigations immobilières": [
      {
        label: `Pièce d'identité valide de votre vendeur`,
        name: "seller_identity_card",
      },
      {
        label: `Levé topographique récent du terrain.`,
        name: "topographic_survey",
      },
      {
        label: `Tous les autres documents que le vendeur vous a remis à propos du terrain`,
        name: "docs_land_infos",
      },
    ],
  });

  return (
    <div>
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <p style={{ marginBottom: 20 + "px" }}>
              Veuillez nous envoyer l'ensemble des documents que vous détenez.
              Vous pouvez charger autant de pièce que possible.
            </p>
            <>
              <div className="ask-info">
                <div style={{ marginTop: 20 + "px" }}>
                  {filesByTypeRequest[data.type].map((item, index) =>
                    FormikInputFile(index, item, formik, setData, data, true)
                  )}
                </div>
              </div>
            </>
            <div className="paginate-button">
              <div>
                <SubmitBorderButton
                  onClick={() => handleBack()}
                  label="Précédent"
                />
                <SubmitBackgroundButton label="Suivant" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AskInfo;
