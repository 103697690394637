import React, { useEffect, useState } from "react";

import { ActionButton, DashGreenButton } from "../../../ui-kits/button/button";
import axios from "axios";
import moment from "moment";
import "moment/locale/fr";
import { Img } from "../../../ui-kits/kits";
import ModalDocViews from "../../../ui-kits/ModalDocViews";
import { useParams } from "react-router";
import {
  PhCheckCircleBold,
  PhEnvelopeSimpleBold,
  PhPhoneBold,
} from "../../../assets/icons/Icon";
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from "kkiapay";
import { toast } from "react-toastify";
import withAuth from "../../../hoc/WithAuth";
import { useModal } from "../../../context/Modal";
import { getKkiapayApiKey, getKkiapaySandbox } from "../../../helpers/payment";

const VoirDetailsClients = () => {
  const { showModal } = useModal();
  const { id } = useParams();
  const [loading, setLoadding] = useState(true);

  const [datas, setDatas] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [clientDetails, setClientDetails] = useState([]);
  const [kkiapayStart, setKkiapayStart] = useState(false);

  const getRequest = async () => {
    const data = await axios.get("/client-requests/" + id);
    setDatas(data.data.data);
    setClientId(data.data.data.clientId);
  };

  const getClientInfo = async () => {
    const data = await axios.get("/user/" + clientId);
    setClientDetails(data.data.data);
    setLoadding(false);
  };

  const detailsArray = [
    {
      label: "Numéro du dossier",
      data: datas?._id,
    },
    {
      label: "Dossier (pièces)",
      data: "Voir les pieces",
    },
    {
      label: "Type de Service",
      data: datas?.type,
    },
    {
      label: "Date",
      data: moment(datas?.createdAt).locale("fr").format("LL"),
    },
    {
      label: "Statut",
      data: datas?.status,
    },
    {
      label: "Tarif",
      data: (
        <b>
          {datas?.cost
            ? datas?.cost + " Francs(" + datas.costLeft + " Cfa restant)"
            : "Le cout sera definit dans les prochaines heures"}
        </b>
      ),
    },
  ];

  if (datas?.type === "Investigations immobilières") {
    detailsArray.push({
      label: "Lieu de l'enquête",
      data: datas?.investigationMunicipalitie,
    });
  } else if (datas?.type === "Conseils & Orientations") {
    detailsArray.push({
      label: "Motif de la demande",
      data: datas?.requestReason,
    });
  }

  if (datas?.statusReasons) {
    detailsArray.push({
      label: "Raison du statut",
      data: datas?.statusReasons,
    });
  }

  const demandeStatut = {
    ended: (
      <span className="demandeStatut payed">
        <PhCheckCircleBold />
        Dossier traité
      </span>
    ),
    paiementPending: (
      <span className="demandeStatut not-red-payed">
        En attente de paiement
      </span>
    ),
    paid: (
      <span className="demandeStatut notpayed">En cours de traitement</span>
    ),
  };

  const handleSeeDoc = () => {
    showModal(<ModalDocViews demande={datas} />);
  };

  const openKkiapay = (cost) => {
    openKkiapayWidget({
      amount: cost,
      theme: "blue",
      api_key: getKkiapayApiKey(),
      sandbox: getKkiapaySandbox(),
      email: clientDetails?.email,
      phone: "",
    });
  };

  function successHandler(response) {
    console.log(response);

    const { transactionId } = response;
    if (response.transactionId) {
      toast.success("Paiement éffectué");
      axios
        .post(`/payment/verify/`, { transactionId })
        .then((response) => {
          // console.log(response.amount);
          axios
            .put(`/client-requests/${datas._id}/update/kkiapay-statement`, {
              amount: parseInt(response.data.amount),
              transactionId,
            })
            .then((response) => {})
            .finally(() => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
        })
        .finally(() => {});
    }
  }

  useEffect(() => {
    getRequest();
  }, []);
  useEffect(() => {
    getClientInfo();
  }, [clientId]);

  useEffect(() => {
    addKkiapayListener("success", successHandler);
    return () => {
      removeKkiapayListener("success", successHandler);
    };
  }, [kkiapayStart]);

  return (
    <div className="add-request-components dashboard-base-param">
      {loading === false ? (
        <div className="view-detail-container">
          <h2 style={{ textAlign: "center" }}>Detail de la demande</h2>
          <h5 style={{ textAlign: "center", marginTop: "20px" }}>
            Pour tout paiement, ne quittez pas la page avant le rechargement de
            celle-ci et le changement du statut du dossier.
            <br />
          </h5>
          <h5 style={{ color: "#fa0000" }}>
            N.B. : Veuillez patienter jusqu'à la confirmation complète du
            processus pour garantir la réussite de votre transaction.
          </h5>
          <div className="clientDemandeDetail">
            <div className="detailContainer">
              {detailsArray.map((item) =>
                item.label === "Dossier (pièces)" ? (
                  <div key={item.label}>
                    <h2>{item.label}</h2>
                    <ActionButton
                      content={item.data}
                      background="#c4e7ff"
                      color="black"
                      onClick={() => handleSeeDoc()}
                    />
                  </div>
                ) : item.label === "Statut" ? (
                  <div key={item.label}>
                    <h2>{item.label}</h2>
                    <p>{demandeStatut[item.data]}</p>
                  </div>
                ) : (
                  <div key={item.label}>
                    <h2>{item.label}</h2>
                    <p>{item.data}</p>
                  </div>
                )
              )}
            </div>
          </div>
          {datas?.cost && datas.status === "paiementPending" && (
            <div style={{ textAlign: "center", marginTop: "40px" }}>
              {/* <DashGreenButton
                content={"Effectuer le paiement"}
                link=""
                disabled={kkiapayStart}
                onClick={() => {
                  setKkiapayStart(true);
                  openKkiapay(datas.cost);
                }}
              /> */}
              <p>
                Vous pouvez payez en 4 tranches
                {"(4 x " + datas.cost / 4 + " Fcfa)"} ou payer en totalite
              </p>
              <br />
              <div style={{ display: "flex", gap: 10 }}>
                <DashGreenButton
                  content={"Payer une tranche(" + datas.cost / 4 + " Cfa)"}
                  link=""
                  disabled={kkiapayStart}
                  onClick={() => {
                    setKkiapayStart(true);
                    openKkiapay(datas.cost / 4);
                  }}
                />
                <DashGreenButton
                  content={"Payer en une fois"}
                  link=""
                  disabled={kkiapayStart}
                  onClick={() => {
                    setKkiapayStart(true);
                    openKkiapay(
                      datas.costPaid > 0
                        ? datas.cost - datas.costPaid
                        : datas.cost
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div className="paiementReclamation">
            <h5 style={{ textAlign: "center", marginTop: "20px" }}>
              Si le changement automatique du statut de votre dossier n'est pas
              acté après paiement, veuillez contacter notre plateforme pour
              réclamation.
            </h5>
            <p>
              Par téléphone :{" "}
              <a href="tel:+229 51178080">
                <PhPhoneBold /> +229 51178080
              </a>
            </p>
            <p>
              Par Mail:
              <a href="mailto:info@refi.com">
                <PhEnvelopeSimpleBold /> contact@refi.bj
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      )}
    </div>
  );
};

export default withAuth(VoirDetailsClients, ["client", "businessFinder"]);

// YeahMan@2025
