import React, { useEffect, useRef, useState } from "react";
import {
  IonFilter,
  PhMagnifyingGlassBold,
  PopoverIcon,
} from "../assets/icons/Icon";
import moment from "moment/moment";
import "moment/locale/fr";
import { Popover } from "@material-ui/core";
import { toast } from "react-toastify";

const InputFilter = ({
  tableBody,
  setFilterTab,
  filterOption = [],
  hideTop = false,
  workerFilter,
  approveFilter,
}) => {
  const [filterWithText, setFilterWithText] = useState("");
  const [filterBy, setFilterBy] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [typeFilter, setTypeFilter] = useState(0);
  const [filteredTableBody, setFilteredTableBody] = useState(tableBody);

  const filtrerTableau = () => {
    if (filterWithText !== "") {
      const resultatFiltre = filteredTableBody.filter((item) =>
        item[filterBy]
          .toString()
          .toLowerCase()
          .includes(filterWithText.toString().toLowerCase())
      );
      setFilterTab(resultatFiltre);
    } else {
      setFilterTab(filteredTableBody);
    }
  };

  const filterWithDate = (e) => {
    const date = new Date(e.target.value);
    if (e.target.value === "") {
      setFilterTab(filteredTableBody);
    } else {
      const resultatFiltre = filteredTableBody.filter(
        (objet) =>
          moment(objet.createdAt).locale("fr").format("LL") ===
          moment(date).locale("fr").format("LL")
      );
      setFilterTab(resultatFiltre);
    }
  };

  const filterWithDossierStatut = () => {
    if (typeFilter === 0) {
      setFilteredTableBody(tableBody);
      setFilterTab(tableBody);
    } else if (typeFilter === 1) {
      if (workerFilter || approveFilter) {
        const resultatFiltre = tableBody.filter(
          (item) => item.status === "approuved" || item.status === "approved"
        );
        setFilteredTableBody(resultatFiltre);
        setFilterTab(resultatFiltre);
      } else {
        const resultatFiltre = tableBody.filter(
          (item) =>
            item.status === "paiementPending" || item.status === "pending"
        );
        setFilteredTableBody(resultatFiltre);
        setFilterTab(resultatFiltre);
      }
    } else if (typeFilter === 2) {
      if (workerFilter || approveFilter) {
        const resultatFiltre = tableBody.filter(
          (item) => item.status === "pending"
        );
        setFilteredTableBody(resultatFiltre);
        setFilterTab(resultatFiltre);
      } else {
        const resultatFiltre = tableBody.filter(
          (item) => item.status === "paid"
        );
        setFilteredTableBody(resultatFiltre);
        setFilterTab(resultatFiltre);
      }
    } else {
      const resultatFiltre = tableBody.filter(
        (item) => item.status === "ended"
      );
      setFilteredTableBody(resultatFiltre);
      setFilterTab(resultatFiltre);
    }
  };

  const handleValueFilter = (e) => {
    if (!filterBy) {
      return toast.error("Veuillez choisir un type de filtrage");
    } else {
      setFilterWithText(e?.target?.value);
    }
  };

  const ClosePopover = () => {
    setAnchorElement(null);
    setVisiblePopover(false);
  };

  useEffect(() => {
    filtrerTableau();
  }, [filterWithText]);

  useEffect(() => {
    filterWithDossierStatut();
  }, [typeFilter]);

  return (
    <>
      <Popover
        id="popover"
        open={visiblePopover}
        anchorEl={anchorElement}
        onClose={ClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ul>
          {filterOption.map((item, ind) => (
            <li
              key={ind}
              onClick={() => {
                setFilterBy(item.value);
                ClosePopover();
              }}
            >
              <PopoverIcon /> {item.label}
            </li>
          ))}
        </ul>
      </Popover>
      {hideTop ? (
        ""
      ) : (
        <ul className="rh-filter">
          {[
            "Tout",
            "Dossier en attente de paiement",
            "Dossier en cours de traitement",
            "Dossier Conclus",
          ].map((item, ind) => (
            <li
              key={"Monter quels types de dossier " + item}
              onClick={() => setTypeFilter(ind)}
              className={typeFilter === ind ? "rh-selected " : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
      {workerFilter ? (
        <ul className="rh-filter">
          {["Tout", "Approuvé", "En attente de paiement"].map((item, ind) => (
            <li
              key={"Monter quels types de dossier " + item}
              onClick={() => setTypeFilter(ind)}
              className={typeFilter === ind ? "rh-selected " : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      ) : approveFilter ? (
        <ul className="rh-filter">
          {["Tout", "Approuvé", "En attente d'approbation"].map((item, ind) => (
            <li
              key={"Monter quels types de dossier " + item}
              onClick={() => setTypeFilter(ind)}
              className={typeFilter === ind ? "rh-selected " : ""}
            >
              {item}
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      <div className="rh-filter-with-input">
        <div>
          <PhMagnifyingGlassBold />
          <input
            type="text"
            placeholder="Rechercher pour filter"
            onChange={(e) => handleValueFilter(e)}
          />
        </div>
        <div
          onClick={(e) => {
            setAnchorElement(e?.currentTarget);
            setVisiblePopover(true);
          }}
          className="filter-div"
        >
          Filtrer
          <IonFilter />
        </div>
        <div>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => filterWithDate(e)}
          />
        </div>
      </div>
    </>
  );
};

export default InputFilter;
