import React from "react";
import BecomeAgentForm from "./BecomeAgentComponents/BecomeAgentForm";
import { SubmitBackgroundButton, Img } from "../../ui-kits/kits";
import { langSelector } from "../../helpers/langs";
import { useModal } from "../../context/Modal";

const HomeBecomeAgent = () => {
  const l = langSelector("homePage").homeBecomeAgent;
  const { showModal } = useModal();
  const showCanditatureModal = () => {
    showModal(<BecomeAgentForm />, true);
  };
  return (
    <>
      <div className="becomeAgent">
        <div className="container">
          <div className="left">
            <h1>Collaborer 24h/7 avec REFI</h1>
            <Img img="young-africain.webp" alt="user" />
          </div>
          <div className="right">
            <h1>Collaborer 24h/7 avec REFI</h1>
            <p>
              Vous pouvez gagner de l’argent avec REFI depuis là où vous vivez.
              Comment ça marche ? Cliquez ici !
            </p>
            <SubmitBackgroundButton
              onClick={showCanditatureModal}
              label={l.buttonText}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBecomeAgent;
