import {
  PhUserBold,
  PhFolderNotchOpenBold,
  PhPlusCircleBold,
  IonSettingsSharp,
  MdiFolderHome,
  IcBaselinePlaylistAddCheckCircle,
  IcBaselinePlaylistAddCircle,
  HealthiconsMoneyBag,
} from "../assets/icons/Icon";

export const simpleNavLinks = [
  {
    route: "/",
    label: "Accueil",
  },
  {
    route: "/About",
    label: "A Propos",
  },
  {
    label: "Services",
  },
  // {
  //   routes: "/Contact",
  //   label: "Contact",
  // },
];
export const servicesLinks = [
  {
    route: "/login",
    label: "Veille imobilière",
  },
  {
    route: "/login",
    label: "Conseils & Orientations",
  },
  {
    route: "/login",
    label: "Investigations Immobilières",
  },
];

export const dashboardSidebarLinks = {
  client: [
    {
      icon: <PhPlusCircleBold />,
      itemRoute: "/Client-request/add",
      label: "Ajouter une demande",
    },
    {
      icon: <PhFolderNotchOpenBold />,
      itemRoute: "/Dashboard",
      label: "Historique",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "/Users-settings",
      activeCondition: "Users",
      label: "Mon compte",
    },
  ],
  superAdmin: [
    {
      icon: <MdiFolderHome />,
      itemRoute: "no-home-route",
      label: "Historique des demandes",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "no-route",
      activeCondition: "Users",
      label: "Les utilisateurs",
    },
    {
      icon: <IonSettingsSharp />,
      itemRoute: "no-param-route",
      // itemRoute: "/Configurated-account",
      label: "Configurations",
    },
    {
      icon: <HealthiconsMoneyBag />,
      itemRoute: "/AllCommission",
      label: "Toutes les commissions",
    },
  ],
  secretary: [
    {
      icon: <MdiFolderHome />,
      itemRoute: "no-home-route",
      label: "Historique des demandes",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "no-route",
      activeCondition: "Users",
      label: "Les utilisateurs",
    },
  ],
  accountant: [],
  investigator: [
    {
      icon: <PhFolderNotchOpenBold />,
      itemRoute: "/Dashboard",
      label: "Historique des demandes",
    },
    {
      icon: <IcBaselinePlaylistAddCircle />,
      itemRoute: "/AddInvestigationDemandes",
      label: "Faire une demande d'investigation",
    },
    {
      icon: <IcBaselinePlaylistAddCheckCircle />,
      itemRoute: "/SeePendingInvestigationDemandes",
      label: "Voir les demandes en attente",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "/Users-settings",
      activeCondition: "Users",
      label: "Mon compte",
    },
  ],
  businessFinder: [
    {
      icon: <PhPlusCircleBold />,
      itemRoute: "/Client-request/add",
      label: "Ajouter une demande",
    },
    {
      icon: <PhFolderNotchOpenBold />,
      itemRoute: "/Dashboard",
      label: "Historique des demandes",
    },
    {
      icon: <HealthiconsMoneyBag />,
      itemRoute: "/Money",
      label: "Commission",
    },
    {
      label: "historyCommission",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "/Users-settings",
      activeCondition: "Users",
      label: "Mon compte",
    },
  ],
  agent: [
    {
      icon: <PhFolderNotchOpenBold />,
      itemRoute: "/Dashboard",
      label: "Historique des demandes",
    },
    {
      icon: <PhUserBold />,
      itemRoute: "/Users-settings",
      activeCondition: "Users",
      label: "Mon compte",
    },
  ],
};
