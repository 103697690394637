import React, { useState } from "react";
import AskInfo from "./info";
import AskFinish from "./finish";
import AskIdentity from "./identity";
import ReadAndConfirmClientRequest from "./readAndConfirmation";
import * as Yup from "yup";
import { useAuth } from "../../../../context/Auth";
import {
  ArrowLeftRounded,
  MaterialSymbolsDone,
} from "../../../../assets/icons/Icon";

const ClientRequestAddSteppers = (props) => {
  const { user } = useAuth();
  const authed = user;
  const userRole = authed.role;
  const { typeOfAskChoosed, setCurrentDemandStep } = props.customProps;
  const [distance, setDistance] = useState(0);
  const [clientData, setClientData] = useState({
    type: typeOfAskChoosed,
    name: authed.fullName,
    email: authed.email,
    municipalitie: "",
    whatsappContact: authed.phone,
    topographic_survey: [],
    land_documents: [],
    other_documents: [],
    requestReason: "",
    identity_card: [],
    docs_land_infos: [],
    seller_identity_card: [],
    clientFullname: userRole === "client" ? authed.fullName : "",
    investigationMunicipalitie:
      typeOfAskChoosed === "Investigations immobilières"
        ? ""
        : "notConcernedInthisCase",
    requestReason:
      typeOfAskChoosed === "Conseils & Orientations"
        ? ""
        : "notConcernedInthisCase",
  });

  const [currentstep, setCurrentStep] = useState(0);
  const nextStep = (newData) => {
    setClientData((prev) => ({ ...prev, ...newData }));
    if (currentstep === 0) {
      setDistance((prev) => prev + 45);
    } else {
      setDistance((prev) => prev + 44);
    }
    setCurrentStep((prev) => prev + 1);
  };
  const askIndentityValidationSchema = Yup.object({
    name: Yup.string().required("Veuillez remplir ce champ"),
    whatsappContact: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .email(
        "Veuillez entrer un mail valide, verifiez aussi les espaces en trop."
      )
      .required("Veuillez remplir ce champ"),
    municipalitie: Yup.string().required("Veuillez remplir ce champ"),
    clientFullname: Yup.string().required("Veuillez remplir ce champ"),
  });
  const askIndentityConseilsValidation = Yup.object({
    name: Yup.string().required("Veuillez remplir ce champ"),
    whatsappContact: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .email(
        "Veuillez entrer un mail valide, verifiez aussi les espaces en trop."
      )
      .required("Veuillez remplir ce champ"),
    municipalitie: Yup.string().required("Veuillez remplir ce champ"),
    requestReason: Yup.string().required("Veuillez remplir ce champ"),
    clientFullname: Yup.string().required("Veuillez remplir ce champ"),
  });
  const askIndentityInvestigationsValidation = Yup.object({
    name: Yup.string().required("Veuillez remplir ce champ"),
    whatsappContact: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .email(
        "Veuillez entrer un mail valide, verifiez aussi les espaces en trop."
      )
      .required("Veuillez remplir ce champ"),
    municipalitie: Yup.string().required("Veuillez remplir ce champ"),
    investigationMunicipalitie: Yup.string().required(
      "Veuillez remplir ce champ"
    ),
    clientFullname: Yup.string().required("Veuillez remplir ce champ"),
  });
  const askInfoValidationSchema = Yup.object({
    land_documents: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    other_documents: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",

          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    topographic_survey: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",

          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
  });
  const askInfoConseilsSchema = Yup.object({
    topographic_survey: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    identity_card: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    other_documents: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
  });
  const askInfoInvestigationSchema = Yup.object({
    seller_identity_card: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",

          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    docs_land_infos: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",

          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
    topographic_survey: Yup.array()
      .required("Les fichiers sont requis")
      .min(1, "Au moins un fichier est requis")
      .max(4, "Nombre maximum de fichiers autorisé est de 4")
      .test("fileCount", "Au moins un fichier est requis", (value) => {
        return value && value.length > 0;
      })
      .test("fileType", "Type de fichier invalide", async (value) => {
        const supportedTypes = [
          "application/pdf",

          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/heic",
        ];
        for (let i = 0; i < value.length; i++) {
          if (!supportedTypes.includes(value[i].type)) {
            return false;
          }
        }
        return true;
      })
      .test(
        "fileSize",
        "Taille du fichier ne doit pas depasser 5 Mo",
        async (value) => {
          const maxSize = 5 * 1024 * 1024; // 5 Mo
          for (let i = 0; i < value.length; i++) {
            if (value[i].size > maxSize) {
              return false;
            }
          }
          return true;
        }
      ),
  });

  const steppers = [
    <AskIdentity
      authed={authed}
      userRole={userRole}
      next={nextStep}
      data={clientData}
      validationSchema={(() => {
        if (typeOfAskChoosed === "Conseils & Orientations") {
          return askIndentityConseilsValidation;
        } else if (typeOfAskChoosed === "Investigations immobilières") {
          return askIndentityInvestigationsValidation;
        } else {
          return askIndentityValidationSchema;
        }
      })()}
    />,
    <AskInfo
      next={nextStep}
      setCurrentStep={setCurrentStep}
      setDistance={setDistance}
      data={clientData}
      setData={setClientData}
      validationSchema={(() => {
        if (typeOfAskChoosed === "Conseils & Orientations") {
          return askInfoConseilsSchema;
        } else if (typeOfAskChoosed === "Investigations immobilières") {
          return askInfoInvestigationSchema;
        } else {
          return askInfoValidationSchema;
        }
      })()}
    />,
    <ReadAndConfirmClientRequest
      authed={authed}
      userRole={userRole}
      setCurrentStep={setCurrentStep}
      setDistance={setDistance}
      data={clientData}
      typeOfAskChoosed={typeOfAskChoosed}
    />,
    <AskFinish />,
  ];

  const progressBar = [
    "Informations personnelles",
    "Documents du bien",
    "Récapitulatif",
  ];
  return (
    <>
      <div className="ask-form">
        <div className="ask-steppers-head">
          <span onClick={() => setCurrentDemandStep(0)}>
            <ArrowLeftRounded />
          </span>
          <h2>
            Demande{" "}
            {typeOfAskChoosed === "Investigations immobilières" ? "d'" : "de"}{" "}
            {typeOfAskChoosed.toLowerCase()}
          </h2>
          <span></span>
        </div>
        <div className="ask-steppers-form">
          {currentstep < 3 && (
            <div className="ask-steppers-item-counter">
              {progressBar.map((item, index) => (
                <div key={"Etape " + index + 1}>
                  <span
                    className={`${
                      index === currentstep ? "stepperCurrentStep" : ""
                    }${index === currentstep - 1 ? "checked" : ""} `}
                  >
                    {index === currentstep - 1 ? (
                      <MaterialSymbolsDone />
                    ) : (
                      index + 1
                    )}
                  </span>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          )}
          <div className="ask-steppers-container">{steppers[currentstep]}</div>
        </div>
      </div>
    </>
  );
};

export default ClientRequestAddSteppers;
