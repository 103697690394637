import React from "react";

import { langSelector } from "../../helpers/langs";
import { Img } from "../../ui-kits/kits";

const HomeActivity = () => {
  const l = langSelector("homePage").homeActivity;

  return (
    <div className="activity">
      <h3>Nos services</h3>
      <section className="act-veille">
        <div className="item">
          <div className="img-container">
            <h1>Veille Immobilière</h1>
            <div>
              <Img img="veille1.png" alt="images activite" className="end" />
              <Img img="veille2.png" alt="images activite" />
            </div>
          </div>
          <div className="info">
            <h1>
              Veille <br /> Immobilière
            </h1>
            <p>
              Nous veillons sur vos biens immobiliers, qu'ils soient bâtis ou
              non, en vous représentant sur place. Ceci est réalisé sans
              nécessiter l'apposition de plaques indiquant votre identité,
              respectant ainsi votre confidentialité dans le cadre légal.
            </p>
          </div>
        </div>
        <div className="item" style={{ background: "#EEF8EA" }}>
          <div className="info">
            <h1>
              Conseils <br /> et Orientations
            </h1>
            <p>
              Nous offrons des conseils et des orientations spécialisés en
              investissement immobilier et vous accompagnons dans la gestion de
              litiges existants. Investissez désormais de manière avisée dans
              l'immobilier.
            </p>
          </div>
          <div className="img-container">
            <h1>Conseils et Orientations</h1>
            <div>
              <Img img="conseil1.png" alt="images activite" className="end" />
              <Img img="conseil2.png" alt="images activite" />
            </div>
          </div>
        </div>
        <div className="item">
          <div className="img-container">
            <h1>Investigations immobilières</h1>
            <div>
              <Img img="invest1.png" alt="images activite" className="end" />
              <Img img="invest2.png" alt="images activite" />
            </div>
          </div>
          <div className="info">
            <h1>
              Investigations <br /> immobilières
            </h1>
            <p>
              Nous effectuons des vérifications approfondies sur la situation
              patrimoniale et juridique des biens immobiliers que vous envisagez
              d'acquérir, ou que vous possédez déjà, afin de garantir la
              sécurité de votre investissement et de prévenir tout obstacle à la
              jouissance paisible de vos propriétés.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeActivity;

<h6>Nos activités</h6>;
