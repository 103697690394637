import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { Img } from "../../ui-kits/kits";
import { TableofData } from "../../ui-kits/table";
import { DashGreenButton } from "../../ui-kits/button/button";
import { ConfirmModal, OperationStatusModal } from "../../ui-kits/Modal";
import withAuth from "../../hoc/WithAuth";
import { useAuth } from "../../context/Auth";
import { useModal } from "../../context/Modal";

const BusinnessFinderAskedPaiement = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { showModal } = useModal();
  const authed = user;
  const userRole = authed.role;
  const [loading, setLoading] = useState(true);
  const [commissionList, setCommissionList] = useState([]);
  const [pendingCommission, setPendingCommission] = useState([]);

  const commissionHead = [
    { name: "cost", label: "Commission" },
    { name: "status", label: "Statut" },
    // { name: "clientRequestId", label: "Id du dossier" },
    { label: "Date de la demande de paiement", name: "createdAt" },
  ];

  const getBussinessFinderCommissions = async () => {
    try {
      const response = await axios.get(`/commission-request/${id}`);
      setCommissionList(
        response.data.data.data.map((item) => ({
          ...item,
          status: item.commissionPaid ? "approved" : "pending",
        }))
      );
      setPendingCommission(
        response.data.data.data
          .filter((item) => item.commissionPaid === false)
          .map((item) => item._id)
      );
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  const putValidateCommission = async () => {
    const promises = pendingCommission.map(async (item) => {
      const response = await axios.put(
        `/commission-request/make-payment/${item}`
      );
      return response.data;
    });

    return Promise.all(promises);
  };

  const validateCommission = () => {
    putValidateCommission()
      .then(() => {
        setPendingCommission([]);
        showModal(
          <OperationStatusModal
            props={{
              type: "success",
              message: "Demandes en attente validées",
            }}
          />
        );

        setTimeout(() => {
          window.location.reload();
        }, [2000]);
      })
      .catch((error) => {
        showModal(
          <OperationStatusModal
            props={{
              type: "error",
              message: "Une erreur s'est produite",
            }}
          />
        );
      });
  };
  useEffect(() => {
    getBussinessFinderCommissions();
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <div className="add-request-components dashboard-base-param">
          <h2>
            {userRole === "superAdmin"
              ? "Historique des commissions"
              : "Les paiements de commissions demandés"}
          </h2>
          {userRole === "superAdmin" &&
          Array.isArray(commissionList) &&
          commissionList.length > 0 ? (
            <div className="detail-field">
              <h3 style={{ padding: "10px" }}>
                Information de l'apporteur concerné
              </h3>
              <table>
                <td>
                  <tr>Nom & prenoms</tr>
                  <tr>Numéro de téléphone (momo)</tr>
                  <tr>Numéro whatsapp</tr>
                  <tr>Email</tr>
                </td>
                <td>
                  <tr>{commissionList[0].businessFinderId.fullName}</tr>
                  <tr>{commissionList[0].businessFinderId.phone}</tr>
                  <tr>{commissionList[0].businessFinderId.whatsapp}</tr>
                  <tr>{commissionList[0].businessFinderId.email}</tr>
                </td>
              </table>
            </div>
          ) : (
            ""
          )}
          {Array.isArray(commissionList) && commissionList.length > 0 ? (
            <>
              <TableofData
                tableHead={commissionHead}
                tableBody={commissionList}
                tableType={"businness"}
                hideTop
                workerFilter={true}
                filterOption={[
                  {
                    value: "cost",
                    label: "Montant de la commission",
                  },
                  {
                    value: "clientRequestId",
                    label: "ID du dossier",
                  },
                ]}
                // workerFilter={true}
              />
              {userRole === "superAdmin" && pendingCommission.length > 0 ? (
                <DashGreenButton
                  content="Valider les paiements en attentes"
                  onClick={() =>
                    showModal(
                      <ConfirmModal
                        text={`Valider les commission en attentes ?`}
                        handle={() => validateCommission()}
                      />
                    )
                  }
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Pas de demande de commission
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default withAuth(BusinnessFinderAskedPaiement, [
  "superAdmin",
  "businessFinder",
  "secretary",
]);
