import { PhCheckCircleBold } from "../assets/icons/Icon";
import { useState } from "react";
import { useEffect } from "react";
import InputFilter from "./InputFilter";
import moment from "moment/moment";

export function TableofData(props) {
  const {
    tableHead,
    tableBody,
    option,
    handle = () => {},
    tableType,
    filterOption = [],
    hideTop,
    workerFilter,
    approveFilter,
    commissionWantPayArray = [],
    hideFilter,
  } = props;
  const validateStatut = {
    approuved: (
      <span className="demandeStatut payed">
        <PhCheckCircleBold />
        Approuvé
      </span>
    ),
    pending: <span className="demandeStatut notpayed"> En attente</span>,
    approved: (
      <span className="demandeStatut payed">
        <PhCheckCircleBold />
        Approuvé
      </span>
    ),
  };
  const demandeStatut = {
    ended: (
      <span className="demandeStatut payed">
        <PhCheckCircleBold />
        Dossier traité
      </span>
    ),
    paiementPending: (
      <span className="demandeStatut not-red-payed">
        En attente de paiement
      </span>
    ),
    paid: (
      <span className="demandeStatut notpayed">En cours de traitement</span>
    ),
  };
  const agentStatut = {
    false: (
      <span className="demandeStatut payed">
        <PhCheckCircleBold />
        Actif
      </span>
    ),
    true: <span className="demandeStatut not-red-payed">Suspendu</span>,
  };

  const [filterTab, setFilterTab] = useState(tableBody);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(filterTab.length / itemsPerPage)
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [currentItems, setCurrentItems] = useState(
    filterTab.slice(indexOfFirstItem, indexOfLastItem)
  );
  // const currentItems = filterTab.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationLinks = () => {
    const links = [];
    if (currentPage > 1 && totalPages > 5) {
      links.push(
        <li key="previous">
          <button onClick={() => handlePageChange(currentPage - 1)}>
            {"<"}
          </button>
        </li>
      );
    }
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(currentPage + 2, totalPages);
      i++
    ) {
      links.push(
        <li key={i} className={i === currentPage ? "pageActive" : ""}>
          <button onClick={() => handlePageChange(i)}>{i}</button>
        </li>
      );
    }
    if (currentPage < totalPages && totalPages > 5) {
      links.push(
        <li key="next">
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {">"}
          </button>
        </li>
      );
    }

    return links;
  };

  useEffect(() => {
    setCurrentItems(filterTab.slice(indexOfFirstItem, indexOfLastItem));
  }, [currentPage, filterTab]);
  useEffect(() => {
    setTotalPages(Math.ceil(filterTab.length / itemsPerPage));
    setCurrentPage(1);
  }, [filterTab]);
  return (
    <>
      {!hideFilter && (
        <InputFilter
          tableBody={tableBody}
          setFilterTab={setFilterTab}
          filterOption={filterOption}
          hideTop={hideTop}
          workerFilter={workerFilter}
          approveFilter={approveFilter}
        />
      )}
      <div className="dashboard-history-table">
        <table>
          <thead>
            <tr className="dashboard-history-table-head">
              {tableHead.map((item, j) => (
                <th key={"Tableau colonnes " + j}>{item.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!filterTab.length ? (
              <p className="not-filter-data">
                Pas de données avec cet élément de recherche
              </p>
            ) : (
              currentItems.map((item, j) => (
                <tr
                  className="dashboard-history-table-element"
                  key={"t ..." + j}
                >
                  {tableHead.map((item2, k) =>
                    (() => {
                      if (item2.name === "status") {
                        return (
                          <td key={"Tableau colonnes content 1" + k}>
                            {tableType === "businness"
                              ? validateStatut[item[item2.name]]
                              : demandeStatut[item[item2.name]]}
                          </td>
                        );
                      } else if (item2.name === "createdAt") {
                        return (
                          <td key={"Tableau colonnes content 2" + k}>
                            {moment(item[item2.name]).locale("fr").format("LL")}
                          </td>
                        );
                      } else if (item2.name === "option") {
                        return (
                          <td>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                flexDirection:
                                  tableType === "businness" ? "column" : "",
                              }}
                            >
                              {item.status === "approved"
                                ? "..."
                                : option.length
                                ? option.map((opt) => (
                                    <>
                                      {(() => {
                                        if (opt.action === "read") {
                                          return (
                                            <span
                                              style={{
                                                color: "black",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) =>
                                                handle("read", item)
                                              }
                                            >
                                              {opt.icon}
                                            </span>
                                          );
                                        } else if (opt.action === "update") {
                                          return (
                                            item.status !== "approuved" && (
                                              <span
                                                style={{
                                                  color: "blue",
                                                  cursor: "pointer",
                                                }}
                                                onClick={(e) =>
                                                  handle("update", item)
                                                }
                                              >
                                                {opt.icon}
                                              </span>
                                            )
                                          );
                                        } else if (opt.action === "delete") {
                                          return (
                                            <span
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) =>
                                                handle("delete", item)
                                              }
                                            >
                                              {opt.icon}
                                            </span>
                                          );
                                        } else if (opt.action === "dot") {
                                          return (
                                            <span
                                              style={{
                                                color: "black",
                                                cursor: "pointer",
                                              }}
                                              onClick={(e) =>
                                                handle("dot", item, e)
                                              }
                                            >
                                              {opt.icon}
                                            </span>
                                          );
                                        } else if (opt.action === "check") {
                                          return (
                                            <input
                                              type="checkbox"
                                              id={item._id}
                                              onChange={() =>
                                                handle("check", item)
                                              }
                                              checked={commissionWantPayArray.some(
                                                (com) =>
                                                  com?.clientRequestId ===
                                                  item._id
                                              )}
                                            />
                                          );
                                        }
                                      })()}
                                    </>
                                  ))
                                : ""}
                            </div>
                          </td>
                        );
                      } else if (item2.name === "role") {
                        return (
                          <td>
                            {(() => {
                              if (item[item2.name] === "secretary") {
                                return "Sécrétaire";
                              } else if (item[item2.name] === "accountant") {
                                return "Comptable";
                              }
                            })()}
                          </td>
                        );
                      } else if (item2.name === "suspended") {
                        return <td>{agentStatut[item[item2.name]]}</td>;
                      } else {
                        return (
                          <td key={"Tableau colonnes content 3" + k}>
                            {item2.subName
                              ? item[item2.name][item2.subName]
                              : item[item2.name] === "notConcernedInthisCase"
                              ? "-"
                              : item[item2.name] || "-"}
                          </td>
                        );
                      }
                    })()
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <ul className="pagination">{renderPaginationLinks()}</ul>
    </>
  );
}
