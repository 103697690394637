import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";
import { toast } from "react-toastify";
import { Img } from "../../../ui-kits/kits";
import { useNavigate } from "react-router-dom";
import withAuth from "../../../hoc/WithAuth";
import { useAuth } from "../../../context/Auth";

const ReportsNotices = () => {
  const navigate = useNavigate();
  const { requestId, contributorId } = useParams();
  const { user } = useAuth();
  const authed = user;
  const [loading, setLoading] = useState(true);
  const [allNotes, setAllNotes] = useState([]);
  const getAllReports = async () => {
    try {
      const response = await axios.get(
        "/client-requests/" + requestId + "/note/all"
      );
      setAllNotes(
        authed.role === "investigator"
          ? response.data.data.filter(
              (item) => item.contributorId._id === contributorId
            )
          : response.data.data
      );
      setLoading(false);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllReports();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Compte rendu des missions effectuées</h2>
          {allNotes && allNotes.length > 0 ? (
            <div className="reports">
              {allNotes.map((item, ind) => (
                <>
                  <div className="info" key={ind}>
                    <div>
                      <h4>Date du compte rendu</h4>
                      <p>{moment(item?.createdAt).locale("fr").format("LL")}</p>
                      <h4>Auteur du compte rendu</h4>
                      {item?.contributorId?._id === contributorId &&
                      !["superAdmin", "secretary"].includes(authed.role) ? (
                        <p>vous</p>
                      ) : (
                        <p>
                          {item?.contributorId?.fullName}
                          {item?.contributorId?.role === "agent"
                            ? " (agent)"
                            : " (investigateur)"}
                        </p>
                      )}
                    </div>
                    <div
                      className="see"
                      onClick={() =>
                        navigate(`/Read-reports/${requestId}/${item?._id}`)
                      }
                    >
                      <button>Lire le compte rendu</button>
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Aucun compte rendu n'a été effectué pour cette mission.
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(ReportsNotices, [
  "agent",
  "investigator",
  "superAdmin",
  "secretary",
]);
