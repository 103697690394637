import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Img } from "../../ui-kits/kits";
import { TableofData } from "../../ui-kits/table";
import withAuth from "../../hoc/WithAuth";

const PendingInvestigationsDemandes = () => {
  const [loading, setLoading] = useState(true);
  const [missionResquest, setMissionResquest] = useState([]);
  const invetigatorHead = [
    {
      name: "clientFullname",
      label: "Nom & Prénoms",
    },
    {
      name: "investigationMunicipalitie",
      label: "Commune de l'investigation",
    },
    {
      name: "type",
      label: "Type de demande",
    },
    {
      name: "status",
      label: "Statut",
    },
    {
      name: "createdAt",
      label: "Date de création",
    },
  ];

  console.log("missionResquest", missionResquest);
  const getMissionRequest = async () => {
    try {
      const response = await axios.get(`client-requests/participation-request`);
      setMissionResquest(
        response.data.data.map((item) => ({
          createdAt: item.createdAt,
          status: item.status,
          clientFullname: item.clientRequestId.clientFullname,
          investigationMunicipalitie:
            item.clientRequestId.investigationMunicipalitie,
          type: item.clientRequestId.type,
        }))
      );
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };

  useEffect(() => {
    getMissionRequest();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Mes demandes pour participer à des investigations </h2>
          {missionResquest && missionResquest.length > 0 ? (
            <TableofData
              tableHead={invetigatorHead}
              tableBody={missionResquest}
              tableType={"businness"}
              hideTop
              filterOption={[
                {
                  label: "Nom & Prénoms",
                  value: "clientFullname",
                },
                {
                  label: "Commune du investigation",
                  value: "investigationMunicipalitie",
                },
              ]}
            />
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Pas d'historique de mission
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(PendingInvestigationsDemandes, ["investigator"]);
