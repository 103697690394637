export function getKkiapayApiKey() {
  if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
    return process.env.REACT_APP_KKIAPAY_LIVE;
  } else {
    return process.env.REACT_APP_KKIAPAY_SANDBOX;
  }
}

export function getKkiapaySandbox() {
  if (process.env.NODE_ENV && process.env.NODE_ENV == "production") {
    return false;
  } else {
    return true;
  }
}
