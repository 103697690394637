import React from "react";
import withoutAuth from "../hoc/WithoutAuth";
import { Formik, Form, ErrorMessage } from "formik";
import { useState } from "react";
import { signUpTable } from "../dataRaws/signUp";
import { FormikInputTextOrMail } from "../ui-kits/form";
import axios from "axios";
import { signupValidationSchema } from "../helpers/formValidations";
import { OperationStatusModal } from "../ui-kits/Modal";
import { langSelector } from "../helpers/langs";
import { Spinner } from "../assets/icons/Icon";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useModal } from "../context/Modal";

const SignUp = ({ offCanvas = false }) => {
  const l = langSelector("authPage").signUp;

  const currentUrl = new URLSearchParams(new URL(window.location.href).search);

  const { showModal } = useModal();

  const sponsorToken = currentUrl.get("sponsorToken");

  const [signUpData, setSignUpData] = useState({
    fullName: "",
    phone: "",
    whatsapp: "",
    email: "",
    municipalitie: "",
    sponsorToken: sponsorToken ? sponsorToken : "refi_sponsor_token_2023",
  });

  const [loading, setLoading] = useState(false);

  const handleRegister = (values) => {
    setLoading(true);
    axios
      .post("/auth/register", { ...values, role: "client" })
      .then(() => {
        showModal(
          <OperationStatusModal
            props={{
              type: "success",
              message: l.modal.successMessage,
            }}
          />
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let message = "";
        let errMessage = "";
        if (err.response && err.response.data) {
          message = err.response.data.message;
        }
        if (!message) {
          errMessage = "Un erreur a éte rencontrée.";
        } else {
          if (message.userAlreadyExist) {
            errMessage = l.modal.error.userAlreadyExist;
          } else if (message.invalidOperation) {
            errMessage = l.modal.error.invalidOperation;
          } else if (message.invalidRole) {
            errMessage = l.modal.error.invalidRole;
          }
        }

        showModal(
          <OperationStatusModal
            props={{
              type: "error",
              message: errMessage,
            }}
          />
        );
      })
      .finally(() => {});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSignUpData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      {/* {!offCanvas && <div className="block"></div>} */}
      <div className="signUp">
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{l.title}</h2>

        <Formik
          initialValues={signUpData}
          onSubmit={handleRegister}
          validationSchema={signupValidationSchema}
          enableReinitialize
        >
          {() => (
            <Form>
              {signUpTable.map((item) =>
                item.name === "phone" || item.name === "whatsapp" ? (
                  <div key={"inscription" + item.name}>
                    <label htmlFor="">
                      {item.label} <span>*</span>
                    </label>
                    <PhoneInput
                      country={"bj"}
                      value={item.name}
                      onChange={(e) =>
                        setSignUpData({ ...signUpData, [item.name]: e })
                      }
                      placeholder="+229 00 00 00 00"
                    />
                    <span
                      className="input-errors"
                      style={{ marginBottom: 10 + "px" }}
                    >
                      <ErrorMessage name={item.name} />
                    </span>
                  </div>
                ) : (
                  FormikInputTextOrMail(item, false, handleInputChange)
                )
              )}
              <button type="submit">
                {loading ? <Spinner /> : l.buttonText}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default withoutAuth(SignUp, false);
// export default SignUp;
