import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const accessTokenLabel = process.env.REACT_APP_TOKEN_LABEL;
  const getLocalAccessToken = localStorage.getItem(accessTokenLabel);
  const [user, setUser] = useState(null);
  const [userLoading, setUL] = useState(true);
  const [userRefetch, setUR] = useState(false);
  const [userToken, setUT] = useState(
    getLocalAccessToken ? getLocalAccessToken : null
  );

  const navigate = useNavigate();
  const login = (token) => {
    setUT(token);
    localStorage.setItem(accessTokenLabel, token);
  };
  const logout = () => {
    localStorage.removeItem(accessTokenLabel);
    setUT(null);
    setUser(null);
    navigate("/");
  };

  const getLoggedUserData = () => {
    axios
      .get("/user/")
      .then((res) => {
        if (res.data.data) {
          setUser(res.data.data);
        } else {
          logout();
        }
      })
      .catch((err) => {})
      .finally(() => {
        setUL(false);
      });
  };

  useEffect(() => {
    if (userToken) {
      axios.defaults.headers = {
        "x-auth-token": userToken,
      };
      getLoggedUserData();
    } else {
      setUL(false);
    }
  }, [userToken]);
  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        user,
        userLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
