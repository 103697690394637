import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { langSelector } from "../../helpers/langs";
import { toast } from "react-toastify";

const HomeInformation = () => {
  const l = langSelector("homePage").homeInformations;
  const [news, setNews] = useState([]);

  const getAllNews = async () => {
    try {
      const response = await axios.get(`refi-news/`);
      setNews(response.data.data.data);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
    }
  };

  useEffect(() => {
    getAllNews();
  }, []);

  const articleList = [
    {
      title:
        "SÉCURISATION FONCIÈRE AU BÉNIN : Abdou-Wahab Say Garba propose une révision du code foncier",
      route:
        "https://daabaaru.bj/securisation-fonciere-au-benin-abdou-wahab-say-garba-propose-une-revision-du-code-foncier/",
    },
    {
      title:
        "Les explications du DG ANDF sur l’expiration de la période transitoire",
      route:
        "https://www.24haubenin.info/?Les-explications-du-DG-ANDF-sur-l-expiration-de-la-periode-transitoire",
    },
    {
      title:
        "IMPACTS DE LA MAFIA FONCIERE ET DES CONFLITS DOMANIAUX SUR LE DEVELOPPEMENT DURABLE AU BENIN",
      route:
        "https://www.linkedin.com/pulse/impacts-de-la-mafia-fonciere-et-des-conflits-sur-le-au-agossadou",
    },
  ];
  return (
    <div className="information">
      <div className="top">
        <h3>Informations</h3>
        <p>
          Voici quelques-uns de nos articles récents sur l'immobilier, proposant
          un contenu riche pour enrichir votre culture dans ce domaine.
        </p>
      </div>
      {news.length > 0 ? (
        <div className="article-container">
          {news.map((item, index) => (
            <div
              key={"Article " + index + 1}
              style={{ backgroundImage: "url(" + item.picture + ")" }}
            >
              <div>
                <h2>{item.title}</h2>
                {/* <p>{item.desc}</p> */}
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  Voir plus
                </a>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Aucune news disponible
        </p>
      )}
    </div>
  );
};

export default HomeInformation;
