import React from "react";
import { MailChangeModal, ModalChangePhoneOrNumber } from "../../ui-kits/Modal";
import ModalDocViews from "../../ui-kits/ModalDocViews";
import { toast } from "react-toastify";
import withAuth from "../../hoc/WithAuth";
import { useAuth } from "../../context/Auth";
import { useModal } from "../../context/Modal";

const UsersSettings = () => {
  const { user } = useAuth();
  const authed = user;
  const UserRole = authed.role;
  const { showModal } = useModal();

  return (
    <div className="add-request-components dashboard-base-param">
      <h2 className="us-title">Paramêtre généraux du compte</h2>
      <div className="us-field">
        <div className="info">
          <div>
            <p>Nom & Prénoms</p>
            <p>{authed.fullName} </p>
          </div>
        </div>
        <div className="info">
          <div>
            <p>Email</p>
            <p>{authed.email} </p>
          </div>
          <div className="update">
            <button onClick={() => showModal(<MailChangeModal />)}>
              Modifier
            </button>
          </div>
        </div>
        <div className="info">
          <div>
            <p>Contact</p>
            <p>{authed.phone} </p>
          </div>
          <div className="update">
            <button
              onClick={() =>
                showModal(
                  <ModalChangePhoneOrNumber
                    left="whatsaap"
                    leftContact={authed.whatsapp}
                  />
                )
              }
            >
              Modifier
            </button>
          </div>
        </div>
        <div className="info">
          <div>
            <p>Whatsaap</p>
            <p>{authed.whatsapp} </p>
          </div>
          <div className="update">
            <button
              onClick={() =>
                showModal(
                  <ModalChangePhoneOrNumber
                    left="contact"
                    leftContact={authed.phone}
                  />
                )
              }
            >
              Modifier
            </button>
          </div>
        </div>
        {/* {["businessFinder"].includes(authed.role) && (
          <div className="info">
            <div>
              <p>Code Sponsor</p>
              <p>{authed.sponsorToken} </p>
            </div>
            <div className="update">
              <button
                onClick={() => {
                  navigator.clipboard.writeText(authed.sponsorToken);
                  toast.info("Code copié dans presse papier");
                }}
              >
                Copier votre code
              </button>
            </div>
          </div>
        )} */}
        {/* {UserRole !== "client" && (
          <>
            <div
              className="info"
              style={{ borderTop: "1px solid #e7e7e7", borderBottom: "none" }}
            >
              <div>
                <p>Documents</p>
              </div>
              <div className="see">
                <button onClick={() => handleSeeDoc()}>Visualiser</button>
              </div>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
};

export default withAuth(UsersSettings, [
  "agent",
  "investigator",
  "secretary",
  "client",
  "businessFinder",
]);
