import React from "react";
import RequestList from "../routesComponents/clientRequest/list";
import withAuth from "../hoc/WithAuth";
import { useAuth } from "../context/Auth";

const dashboard404 = ["AddDemand", "Demandes", "Users", "MoneyBag", "Settings"];

const Dashboard = () => {
  const { user } = useAuth();
  //
  const dashboardFonctionality = {
    home: <RequestList user={user} />,
    // superAdmin: <WorkerDemandesList />,
    // agent: "Ce que l'agent voit sur son dashboard",
    // businnessFinder: "Ce que l'apporteur d'affaire voit sur son dashboard",
  };

  return (
    <>
      <section className="">
        <div className="dashboard-element-container">
          {dashboardFonctionality.home}
        </div>
      </section>
    </>
  );
};

export default withAuth(Dashboard);
