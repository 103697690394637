import React from "react";
import { Formik, Form } from "formik";
import {
  PhMapPinLineBold,
  PhPaperPlaneTiltBold,
  PhUserBold,
} from "../../../../assets/icons/Icon";
import { SubmitBackgroundButton } from "../../../../ui-kits/kits";
import { FormikInputTextOrMail } from "../../../../ui-kits/form";
import { Field, ErrorMessage } from "formik";

const AskIdentity = (props) => {
  const { next, data, validationSchema, authed, userRole } = props;
  const inputsArray = [
    {
      label: "Nom Prenoms",
      name: "name",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
      readOnly: true,
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Ex: monmail@gmail.com",
      type: "text",
      icon: <PhPaperPlaneTiltBold />,
      readOnly: true,
    },
    {
      label: "Lieu de résidence",
      name: "municipalitie",
      icon: <PhMapPinLineBold />,
    },
  ];
  const inputsConseilsOrientation = [
    {
      label: "Nom Prenoms",
      name: "name",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
      readOnly: true,
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Ex: monmail@gmail.com",
      type: "text",
      icon: <PhPaperPlaneTiltBold />,
      readOnly: true,
    },
    {
      label: "Lieu de résidence",
      name: "municipalitie",
      icon: <PhMapPinLineBold />,
    },
    {
      label: "Motif de la demande",
      name: "requestReason",
      placeholder: "Entrez le motif",
      type: "text",
    },
  ];
  const inputsInvestigation = [
    {
      label: "Nom Prenoms",
      name: "name",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
      readOnly: true,
    },
    {
      label: "Email",
      name: "email",
      placeholder: "Ex: monmail@gmail.com",
      type: "text",
      icon: <PhPaperPlaneTiltBold />,
      readOnly: true,
    },
    {
      label: "Commune de résidence",
      name: "municipalitie",
      icon: <PhMapPinLineBold />,
    },
    {
      label: "Commune de l'investigation",
      name: "investigationMunicipalitie",
      icon: <PhMapPinLineBold />,
    },
  ];

  if (userRole === "businessFinder") {
    inputsArray.push({
      label: "Nom Prenoms du client",
      name: "clientFullname",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
    });
    inputsConseilsOrientation.push({
      label: "Nom Prenoms du client",
      name: "clientFullname",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
    });
    inputsInvestigation.push({
      label: "Nom Prenoms du client",
      name: "clientFullname",
      placeholder: "Ex: Jean Dassi",
      type: "text",
      icon: <PhUserBold />,
    });
  }

  const handleSubmit = (values) => {
    next(values);
  };
  return (
    <div>
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <div className="ask-idendity-input-container">
              {(() => {
                if (data.type === "Conseils & Orientations") {
                  return inputsConseilsOrientation.map((item) =>
                    FormikInputTextOrMail(item, false, false)
                  );
                } else if (data.type === "Investigations immobilières") {
                  return inputsInvestigation.map((item) =>
                    FormikInputTextOrMail(item, false, false)
                  );
                } else {
                  return inputsArray.map((item) =>
                    FormikInputTextOrMail(item, false, false)
                  );
                }
              })()}
            </div>
            <div className="paginate-button">
              <div>
                <SubmitBackgroundButton label="Suivant" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AskIdentity;
