import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { DashGreenButton } from "../../ui-kits/button/button";
import Listes from "./components/Listes";
import axios from "axios";
import { Img } from "../../ui-kits/kits";
import withAuth from "../../hoc/WithAuth";

const AgentList = () => {
  const { type } = useParams();
  const [typeList, setTypeList] = useState("");

  const [usersList, setUsersList] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (type === "agent-agréé") {
      setTypeList("agents agrées");
    } else if (type === "apporteur-affaire") {
      setTypeList("apporteurs");
    } else if (type === "staff") {
      setTypeList("staffs");
    } else if (type === "client") {
      setTypeList("clients");
    } else {
      setTypeList("investigateurs");
    }
  }, [type]);

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const users = await axios.get("/user/all");
      let filteredUsers = [];
      if (typeList === "agents agrées") {
        filteredUsers = users.data.data.filter((user) => user.role === "agent");
        setUsersList(filteredUsers);
      } else if (typeList === "clients") {
        filteredUsers = users.data.data.filter(
          (user) => user.role === "client"
        );
        setUsersList(filteredUsers);
      } else if (typeList === "apporteurs") {
        filteredUsers = users.data.data.filter(
          (user) => user.role === "businessFinder"
        );
        setUsersList(filteredUsers);
      } else if (typeList === "staffs") {
        filteredUsers = users.data.data.filter(
          (user) =>
            ![
              "agent",
              "businessFinder",
              "client",
              "superAdmin",
              "investigator",
            ].includes(user.role)
        );
        setUsersList(filteredUsers);
      } else if (typeList === "investigateurs") {
        filteredUsers = users.data.data.filter(
          (user) => user.role === "investigator"
        );
        setUsersList(filteredUsers);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeList) {
      getAllUsers();
    }
  }, [typeList]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <div className="add-request-components dashboard-base-param">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2>Liste des {typeList}</h2>
          </div>
          <Listes listeType={typeList} body={usersList} />
        </div>
      )}
    </>
  );
};

export default withAuth(AgentList, ["superAdmin", "secretary"]);
