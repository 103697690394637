import React from "react";
import { Link } from "react-router-dom";
import { Img } from "../../../../ui-kits/kits";

const AskFinish = () => {
  return (
    <div className="ask-finish">
      <div className="logo">
        <Img img="demandeDone.png" alt="done" />
      </div>
      <h2>Demande envoyée</h2>
      <p>
        La demande a été enregistré avec succès. Vous pouvez consulter le statut
        ou l'évolution de votre dossier en cliquant sur le bouton Consulter
      </p>
      <Link className="okay" to="/Dashboard">
        Consulter
      </Link>
    </div>
  );
};

export default AskFinish;
