import {
  PhUserBold,
  PhMapPinLineBold,
  PhEnvelopeSimpleBold,
  PhPhoneBold,
} from "../assets/icons/Icon";
import { langSelector } from "../helpers/langs";

const l = langSelector("authPage").signUp.formFields;

export const signUpTable = [
  {
    label: l.fullName.label,
    placeholder: l.fullName.placeholder,
    icon: <PhUserBold />,
    name: "fullName",
    type: "text",
  },
  {
    label: l.phone.label,
    placeholder: l.phone.placeholder,
    icon: <PhPhoneBold />,
    name: "phone",
    type: "tel",
  },
  {
    label: l.whatsapp.label,
    placeholder: l.whatsapp.placeholder,
    icon: <PhPhoneBold />,
    name: "whatsapp",
    type: "tel",
  },
  {
    label: l.email.label,
    placeholder: l.email.placeholder,
    icon: <PhEnvelopeSimpleBold />,
    name: "email",
    type: "mail",
  },
  {
    label: "Commune de résidence",
    // placeholder: l.residence.placeholder,
    icon: <PhMapPinLineBold />,
    name: "municipalitie",
    type: "text",
  },
];
