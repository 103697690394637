import React from "react";
import withoutAuth from "../hoc/WithoutAuth";
import { Link } from "react-router-dom";

const MentionsLegales = () => (
  <div className="mentions-legales">
    <div className="afterAboutBanner" style={{ marginBottom: "40px" }}></div>
    <h1>MENTION LÉGALE</h1>
    <section>
      <p>
        Conformément aux dispositions de l’article 328 de la Loi N°2017-20 du 20
        avril 2018 portant code du numérique en République du Bénin, nous
        portons à la connaissance des utilisateurs et des visiteurs du site web
        refi.bj les informations suivantes :
      </p>
    </section>
    <section>
      <h2>ÉDITEUR</h2>
      <p>
        Le site web refi.bj est la propriété exclusive de REAL ESTATES FRAUD
        INVESTIGATOR, qui l’édite.
      </p>
      <p>REAL ESTATES FRAUD INVESTIGATOR</p>
      <p>
        Tél
        <a href="tel:+229 51178080"> : +229 51178080</a>
      </p>
      <p>
        Immatriculée au Registre du Commerce et du Crédit Mobilier en République
        du Bénin sous le numéro RB/ABC/22 A 82335.
      </p>
      <p>
        Adresse de courrier électronique :{" "}
        <a href="mailto:contact@refi.bj">contact@refi.bj</a>
      </p>
      <p>
        Directeur de publication : <strong>Luck Magloire TCHIHOUNDJRO</strong>
      </p>
      <p>
        Contact du responsable de publication :{" "}
        <a href="mailto:luck@refi.bj">luck@refi.bj</a>
      </p>
      <p>
        Téléphone du responsable de publication :{" "}
        <a href="tel:+229 97000517">+229 97000517</a>
      </p>
    </section>

    <section>
      <h2> HÉBERGEMENT</h2>
      <p>
        Le site web est hébergé par : PlanetHoster, 4416 Louis-B.-Mayer, Laval,
        Québec, Canada. Contact :{" "}
        <a href="tel:+1 855 774-4678">+1 855 774-4678</a>,{" "}
        <a href="mailto:info@planethoster.info">info@planethoster.info</a>. Site
        web : [
        <a
          href="http://www.planethoster.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.planethoster.com
        </a>{" "}
        ]({" "}
        <a
          href="https://www.planethoster.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.planethoster.com
        </a>{" "}
        ).
      </p>
    </section>

    <section>
      <h2>DESCRIPTION DES SERVICES FOURNIS</h2>
      <p>
        Le site web refi.bj a pour objet de fournir des informations concernant
        l’ensemble des activités de REAL ESTATES FRAUD INVESTIGATOR. Le
        propriétaire du site web s’efforce de fournir des informations aussi
        précises que possible. Toutefois, il ne pourra être tenu responsable des
        omissions, des inexactitudes et des carences dans la mise à jour,
        qu’elles soient de son fait ou du fait des tiers partenaires qui lui
        fournissent ces informations. Toutes les informations proposées sur le
        site web refi.bj sont données à titre indicatif, sont non exhaustives et
        sont susceptibles d’évoluer. Elles sont données sous réserve de
        modifications ayant été apportées depuis leur mise en ligne.
      </p>
    </section>

    <section>
      <h2>PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS</h2>
      <p>
        REAL ESTATES FRAUD INVESTIGATOR détient les droits de propriété
        intellectuelle ou les droits d’usage sur tous les éléments accessibles
        sur le site web, notamment les textes, images, graphismes, logos,
        icônes, sons, logiciels… Toute reproduction, représentation,
        modification, publication, adaptation totale ou partielle des éléments
        du site web, quel que soit le moyen ou le procédé utilisé, est
        interdite, sauf autorisation écrite préalable à l’email :{" "}
        <a href="mailto:contact@refi.bj.">contact@refi.bj.</a>
      </p>
      <p>
        Conformément aux dispositions des articles 529 et 530 de la Loi
        N°2017-20 du 20 avril 2018 portant code du numérique en République du
        Bénin, toute exploitation non autorisée du site web ou de l’un
        quelconque des éléments qu’il contient, sera considérée comme
        constitutive d’une contrefaçon et poursuivie selon les dispositions des
        articles 531 et suivants de la section 1 traitant de l’atteinte aux
        droits de propriété intellectuelle de la Loi N°2017-20 du 20 avril 2018
        portant code du numérique en République du Bénin.
      </p>
    </section>

    <section>
      <h2>LIENS HYPERTEXTES ET COOKIES</h2>
      <p>
        Le site web refi.bj contient un certain nombre de liens hypertextes vers
        d’autres sites (partenaires, informations…) mis en place avec
        l’autorisation des propriétaires desdits sites. Cependant, REAL ESTATES
        FRAUD INVESTIGATOR n’a pas la possibilité de vérifier entièrement le
        contenu des sites ainsi visités et décline donc toute responsabilité de
        ce fait quant aux risques éventuels de contenus illicites. L’utilisateur
        est informé que lors de ses visites sur le site web refi.bj, un ou des
        cookies sont susceptibles de s’installer automatiquement sur son
        appareil. Un cookie est un fichier de petite taille, qui ne permet pas
        l’identification de l’utilisateur, mais qui enregistre des informations
        relatives à la navigation d’un appareil sur un site ou une application.
        Les données ainsi obtenues visent à faciliter la navigation ultérieure
        sur le site web et ont également vocation à permettre diverses mesures
        de fréquentation. Le paramétrage du logiciel de navigation permet
        d’informer de la présence de cookie et éventuellement, de refuser
        l’installation des cookies. Le refus d’installation d’un cookie peut
        entraîner l’impossibilité d’accéder à certains services. L’utilisateur
        peut toutefois configurer son paramètre cookies et choisir quoi
        partager.
      </p>
    </section>
    <section>
      <h2>
        PROTECTION DES BIENS ET DES PERSONNES – GESTION DES DONNÉES PERSONNELLES
      </h2>
      <p>
        Utilisateur : Internaute se connectant, utilisant le site web susnommé :
        refi.bj. Au Bénin, les données personnelles sont notamment protégées par
        l’article 378 de la Loi N°2017-20 du 20 avril 2018 portant code du
        numérique en République du Bénin.
      </p>
      <p>
        Sur le site web refi.bj, le propriétaire ne collecte des informations
        personnelles relatives à l’utilisateur que pour le besoin de certains
        services proposés par cette dernière. L’utilisateur fournit ces
        informations en toute connaissance de cause, spécifiquement lorsqu’il
        procède lui-même à leur saisie. Il est alors précisé à l’utilisateur du
        site web refi.bj l’obligation ou non de fournir ces informations.
        Conformément aux dispositions des articles 437 à 443 de la Loi N°2017-20
        du 20 avril 2018 portant code du numérique en République du Bénin, tout
        utilisateur dispose d’un droit d’accès, de rectification, de suppression
        et d’opposition aux données personnelles le concernant. Pour l’exercer,
        adresser sa demande à REAL ESTATES FRAUD INVESTIGATOR par email :{" "}
        <a href="mailto:contact@refi.bj">contact@refi.bj</a> ou en effectuant
        une demande écrite et signée, accompagnée d’une copie du titre
        d’identité avec signature du titulaire de la pièce, en précisant
        l’adresse à laquelle la réponse doit être envoyée.
      </p>
      <p>
        Aucune information personnelle de l’utilisateur de refi.bj n’est publiée
        à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un
        support quelconque à des tiers. Seule l’hypothèse du rachat de refi.bj à
        son propriétaire et de ses droits permettrait la transmission desdites
        informations à l’éventuel acquéreur qui serait à son tour tenu de la
        même obligation de conservation et de modification des données vis-à-vis
        de l’utilisateur de refi.bj. Les bases de données sont protégées par les
        dispositions des articles 433 et 434 de la Loi N°2017-20 du 20 avril
        2018 portant code du numérique en République du Bénin, relatifs à la
        protection juridique des bases de données.
      </p>
    </section>
    <section>
      <h2>
        POLITIQUE DE CONFIDENTIALITÉ ET CONDITIONS GÉNÉRALES D'UTILISATION
      </h2>
      <p>
        Les utilisateurs du site web refi.bj sont invités à consulter la
        <Link to="/PrivacyPolicy"> politique de confidentialité</Link> et les
        <Link to="/Terms">conditions générales d'utilisation</Link>
        pour plus d'informations sur la protection des données personnelles et
        les règles d'utilisation de la plateforme.
      </p>
    </section>
  </div>
);

export default withoutAuth(MentionsLegales);
