import React, { useEffect, useState } from "react";
import { TableofData } from "../../ui-kits/table";
import axios from "axios";
import ModalDocViews from "../../ui-kits/ModalDocViews";
import {
  ApprouvedButton,
  SeeDocumentButton,
} from "../../ui-kits/button/button";
import { toast } from "react-toastify";
import { Img } from "../../ui-kits/kits";
import { useParams } from "react-router";
import { ConfirmModal } from "../../ui-kits/Modal";
import withAuth from "../../hoc/WithAuth";
import { useModal } from "../../context/Modal";

function WorkerDemandesList() {
  const { showModal } = useModal();
  const { typeJob } = useParams();
  const tableHead = [
    { name: "lastname", label: "Nom" },
    { name: "firstname", label: "Prénoms" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Telephone" },
    { name: "whatsapp", label: "Numéro Whatsapp" },
    { name: "status", label: "Statut" },
    { name: "createdAt", label: "Date de la demande" },
    { name: "option", label: "Action" },
  ];
  const [listDemandes, setListDemandes] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterElement, setFilterElement] = useState([]);

  const getListDemandes = async () => {
    setLoading(true);
    const allData = await axios.get("/worker-requests/all");
    setListDemandes(allData.data.data.data);
  };

  const HandleApprouve = async (id) => {
    try {
      await axios.put("/worker-requests/approve/" + id);
      toast.success(
        typeJob === "businessFinder"
          ? "Apporteur d'affaire approuvé. Le compte a été créé"
          : typeJob === "investigator"
          ? "Investigateur approuvé. Le compte a été créé"
          : "Agent approuvé. Le compte a été créé"
      );
      setRefresh((prev) => !prev);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
    }
  };

  const HandleSeeDoc = (type, item) => {
    if (type === "read") {
      showModal(<ModalDocViews demande={item} />);
    } else {
      showModal(
        <ConfirmModal
          text={`Approuver la demande ?`}
          handle={() => {
            HandleApprouve(item._id);
          }}
        />
      );
    }
  };

  const action = [
    {
      action: "read",
      icon: <SeeDocumentButton content="Documents" />,
    },
    {
      action: "update",
      icon: <ApprouvedButton content="Approuver" />,
    },
  ];

  const handleFilter = () => {
    let isFilteringComplete = false;

    if (typeJob === "businessFinder") {
      setFilterElement(
        listDemandes.filter((item) => item.workerType === "businessFinder")
      );
      isFilteringComplete = true;
    } else if (typeJob === "investigator") {
      setFilterElement(
        listDemandes.filter((item) => item.workerType === "investigator")
      );
      isFilteringComplete = true;
    } else {
      setFilterElement(
        listDemandes.filter((item) => item.workerType === "agent")
      );
      isFilteringComplete = true;
    }
    if (isFilteringComplete) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListDemandes();
  }, [refresh, typeJob]);
  useEffect(() => {
    handleFilter();
  }, [listDemandes]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <div className="add-request-components dashboard-base-param">
          <h2>
            La liste de ceux qui ont demandé à être{" "}
            {typeJob === "businessFinder"
              ? "apporteur d'affaire"
              : typeJob === "investigator"
              ? "investigateur"
              : "agent"}
          </h2>
          {filterElement.length && filterElement.length > 0 ? (
            <TableofData
              tableHead={tableHead}
              tableBody={filterElement}
              handle={HandleSeeDoc}
              tableType={"businness"}
              option={action}
              hideTop
              approveFilter={true}
              filterOption={[
                {
                  label: "Nom & prenoms",
                  value: "fullName",
                },
                {
                  label: "Email",
                  value: "email",
                },
              ]}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "50px",
                fontSize: "20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Img img="nothingFound.png" />
              Aucune demande actuellement
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default withAuth(WorkerDemandesList, ["superAdmin", "secretary"]);
