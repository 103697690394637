import React from "react";
import { PhEyeBold } from "../../../assets/icons/Icon";
import { TableofData } from "../../../ui-kits/table";
import { useNavigate } from "react-router-dom";
import { Img } from "../../../ui-kits/kits";

const tableHead = [
  { name: "fullName", label: "Nom & Prénoms" },
  { name: "phone", label: "N° Téléphone" },
  { name: "email", label: "Email" },
  { name: "createdAt", label: "Date de creation du compte" },
  { name: "suspended", label: "Etat du compte" },
  { name: "option", label: "Option" },
];
const agentTableHead = [
  { name: "fullName", label: "Nom & Prénoms" },
  { name: "phone", label: "N° Téléphone" },
  { name: "email", label: "Email" },
  { name: "createdAt", label: "Date de creation du compte" },
  { name: "role", label: "Rôle" },
  { name: "suspended", label: "Etat du compte" },
  { name: "option", label: "Option" },
];

const Listes = ({ listeType, body }) => {
  const navigate = useNavigate();

  const handleAction = (type, item) => {
    if (type === "read") {
      navigate(`/Agent-detail/${listeType}/${item._id}`);
    } else if (type === "delete") {
    }
  };
  const action = [
    {
      action: "read",
      icon: <PhEyeBold />,
    },
  ];
  return (
    <div>
      {body.length ? (
        listeType === "staffs" ? (
          <TableofData
            tableHead={agentTableHead}
            tableBody={body}
            handle={handleAction}
            option={action}
            filterOption={[
              {
                label: "Nom & prenoms",
                value: "fullName",
              },
              {
                label: "Email",
                value: "email",
              },
            ]}
            hideTop={true}
          />
        ) : (
          <TableofData
            tableHead={tableHead}
            tableBody={body}
            handle={handleAction}
            option={action}
            filterOption={[
              {
                label: "Nom & prenoms",
                value: "fullName",
              },
              {
                label: "Email",
                value: "email",
              },
            ]}
            hideTop={true}
          />
        )
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "100px", fontSize: "20px" }}
        >
          <Img img="nothingFound.png" />
          <p>Aucune donnée</p>
        </div>
      )}
    </div>
  );
};

export default Listes;
