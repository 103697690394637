import React, { useEffect, useState } from "react";
import {
  PhDotsThreeOutlineFill,
  PhEyeBold,
  PopoverIcon,
} from "../../assets/icons/Icon";
import useClientRequests from "../../hooks/useClientRequests";
import { TableofData } from "../../ui-kits/table";
import { DashGreenButton } from "../../ui-kits/button/button";
import { useNavigate } from "react-router-dom";
import { Img } from "../../ui-kits/kits";
import { Popover } from "@material-ui/core";
import {
  ModalGiveFolderToAgent,
  ModalSetFolderPrice,
  ModalSetFolderStatut,
} from "../../ui-kits/Modal";
import ModalDocViews from "../../ui-kits/ModalDocViews";
import axios from "axios";
import { toast } from "react-toastify";
import { useModal } from "../../context/Modal";

const tableHead = [
  { name: "_id", label: "ID du dossier" },
  {
    name: "clientFullname",
    label: "Nom & Prénoms",
  },
  {
    name: "cost",
    label: "Prix",
  },
  {
    name: "municipalitie",
    label: "Commune du client",
  },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "requestReason",
    label: "Motif",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  {
    name: "status",
    label: "Statut",
  },
  { name: "costPaid", label: "Deja Payer" },
  { name: "costLeft", label: "Reste a payer" },
  {
    name: "createdAt",
    label: "Date de création",
  },
  { name: "option", label: "Option" },
];
const tableHead2 = [
  { name: "_id", label: "ID du dossier" },
  {
    name: "clientFullname",
    label: "Nom & Prénoms",
  },
  {
    name: "cost",
    label: "Prix",
  },
  {
    name: "municipalitie",
    label: "Commune du client",
  },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "requestReason",
    label: "Motif",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  {
    name: "status",
    label: "Statut",
  },
  { name: "costPaid", label: "Deja Payer" },
  { name: "costLeft", label: "Reste a payer" },
  {
    name: "whoChangeStatus",
    label: "Statut changer par",
  },
  {
    name: "whoChangePrice",
    label: "Prix changer par",
  },
  {
    name: "createdAt",
    label: "Date de création",
  },
  { name: "option", label: "Option" },
];

const clientHead = [
  { name: "_id", label: "ID du dossier" },
  { name: "type", label: "Type de demande" },
  { name: "createdAt", label: "Date de creation" },
  { name: "status", label: "Statut" },
  { name: "costPaid", label: "Deja Payer" },
  { name: "costLeft", label: "Reste a payer" },
  { name: "option", label: "Option" },
];
const businessFinderHead = [
  { name: "_id", label: "ID du dossier" },
  {
    name: "clientFullname",
    label: "Nom & Prénoms du client",
  },
  { name: "type", label: "Type de demande" },
  { name: "createdAt", label: "Date de creation" },
  { name: "status", label: "Statut" },
  { name: "option", label: "Option" },
];
const invetigatorHead = [
  // {
  //   name: "clientFullname",
  //   label: "Nom & Prénoms",
  // },
  // {
  //   name: "cost",
  //   label: "Prix",
  // },
  // {
  //   name: "municipalitie",
  //   label: "Commune du client",
  // },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  // {
  //   name: "status",
  //   label: "Statut",
  // },
  // {
  //   name: "createdAt",
  //   label: "Date de création",
  // },
  { name: "option", label: "Option" },
];

const RequestList = ({ user }) => {
  const authed = user;
  const userRole = authed.role;
  const [anchorElement, setAnchorElement] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [selectedFolder, setSF] = useState(null);
  const [agentFolders, setAgentFolders] = useState([]);
  const { clientRequests, loading } = useClientRequests();

  const clientDemandes = useClientRequests(authed._id);
  const navigate = useNavigate();

  const handleAction = (type, item) => {
    if (type === "read") {
      navigate(`/Client-requete-details/${item._id}`);
    }
  };
  const action = [
    {
      action: "dot",
      icon: <PhDotsThreeOutlineFill />,
    },
    {
      action: "read",
      icon: <PhEyeBold />,
    },
  ];

  const handleAgentAgreedAction = (type, item, e) => {
    if (type === "dot") {
      setSF(item);
      setAnchorElement(e?.currentTarget);
      setVisiblePopover(true);
    }
  };

  const handleManageDossier = (type, item, e) => {
    if (type === "dot") {
      setSF(item);
      setAnchorElement(e?.currentTarget);
      setVisiblePopover(true);
    }
  };
  const ClosePopover = () => {
    setAnchorElement(null);
    setVisiblePopover(false);
  };

  const dotAction = [
    {
      action: "dot",
      icon: <PhDotsThreeOutlineFill />,
    },
  ];

  const { showModal } = useModal();

  // manager folder by admin
  const handleGivesFolder = () => {
    ClosePopover();
    showModal(<ModalGiveFolderToAgent dossier={selectedFolder} />);
  };
  const handleFolderPrice = () => {
    ClosePopover();
    showModal(<ModalSetFolderPrice dossier={selectedFolder} />);
  };
  const handleFolderStatus = () => {
    ClosePopover();
    showModal(<ModalSetFolderStatut dossier={selectedFolder} />);
  };
  // manager folder by agent

  const getAgentsDossier = async () => {
    try {
      const response = await axios.get(
        "/client-requests/for-agent/" + authed._id
      );
      setAgentFolders(response.data.data);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
    }
  };
  const getInvestigatorDossier = async () => {
    try {
      const response = await axios.get(
        "/client-requests/for-investigator/" + authed._id
      );
      setAgentFolders(response.data.data);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
    }
  };
  useEffect(() => {
    if (userRole === "agent") {
      getAgentsDossier();
    } else if (userRole === "investigator") {
      getInvestigatorDossier();
    }
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <Popover
            id="popover"
            open={visiblePopover}
            anchorEl={anchorElement}
            onClose={ClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {userRole === "agent" ? (
              <ul>
                {selectedFolder && selectedFolder.status !== "ended" && (
                  <li
                    onClick={() =>
                      navigate(
                        `/Write-note-on-demande/${
                          selectedFolder && selectedFolder._id
                        }`
                      )
                    }
                  >
                    <PopoverIcon /> Rediger le compte rendu de votre travail
                  </li>
                )}
                {selectedFolder &&
                  selectedFolder.status !== "ended" &&
                  selectedFolder &&
                  selectedFolder.type === "Investigations immobilières" && (
                    <li
                      onClick={() =>
                        navigate(
                          `/InvestiagionMissionDeamndes/${selectedFolder._id}`
                        )
                      }
                    >
                      <PopoverIcon /> Voir les investigateurs souhaitant
                      travailler sur le dossier
                    </li>
                  )}
                <li
                  onClick={() => {
                    ClosePopover();
                    showModal(<ModalDocViews demande={selectedFolder} />);
                  }}
                >
                  <PopoverIcon /> Voir les documents
                </li>
                <li
                  onClick={() => {
                    navigate(
                      `/Reports/${selectedFolder && selectedFolder._id}/${
                        authed._id
                      }`
                    );
                  }}
                >
                  <PopoverIcon /> Voir Les comptes rendus réaliser
                </li>
              </ul>
            ) : userRole === "investigator" ? (
              <ul>
                {selectedFolder && selectedFolder.status !== "ended" && (
                  <li
                    onClick={() =>
                      navigate(
                        `/Write-note-on-demande/${
                          selectedFolder && selectedFolder._id
                        }`
                      )
                    }
                  >
                    <PopoverIcon /> Rediger le compte rendu de votre travail
                  </li>
                )}
                <li
                  onClick={() => {
                    navigate(
                      `/Reports/${selectedFolder && selectedFolder._id}/${
                        authed._id
                      }`
                    );
                  }}
                >
                  <PopoverIcon /> Voir Les comptes rendus réaliser
                </li>
                <li
                  onClick={() => {
                    ClosePopover();
                    showModal(<ModalDocViews demande={selectedFolder} />);
                  }}
                >
                  <PopoverIcon /> Voir les documents
                </li>
              </ul>
            ) : (
              <ul>
                {selectedFolder &&
                  selectedFolder?.status === "paid" &&
                  !selectedFolder.agentId && (
                    <li onClick={() => handleGivesFolder()}>
                      <PopoverIcon /> Attribuer a un agent
                    </li>
                  )}
                {/* {selectedFolder && selectedFolder.agentId && (
                  <li>
                    <PhCheckCircleBold /> Déjà attribuer à un agent
                  </li>
                )} */}
                {selectedFolder &&
                  selectedFolder.costPayOnKkipay === false &&
                  selectedFolder.costPaid == 0 && (
                    <li onClick={() => handleFolderPrice()}>
                      <PopoverIcon /> Ajouter/Modifier prix
                    </li>
                  )}
                {selectedFolder && selectedFolder.status !== "ended" && (
                  <li onClick={() => handleFolderStatus()}>
                    <PopoverIcon /> Modifier le statut
                  </li>
                )}
                <li
                  onClick={() => {
                    navigate(
                      `/Reports/${selectedFolder && selectedFolder._id}/${
                        authed._id
                      }`
                    );
                  }}
                >
                  <PopoverIcon /> Voir les rapports
                </li>
                <li
                  onClick={() => {
                    ClosePopover();
                    showModal(<ModalDocViews demande={selectedFolder} />);
                  }}
                >
                  <PopoverIcon /> Voir les documents
                </li>
              </ul>
            )}
          </Popover>
          <div className="request-history">
            <div className="rqheader">
              <h2>Historique des demandes</h2>
              {userRole === "client" && (
                <DashGreenButton
                  content={"Faire une demande"}
                  link="/Client-request/add"
                />
              )}
              {userRole === "businessFinder" && (
                <DashGreenButton
                  content={"Inscrire une demande pour un client"}
                  link="/Client-request/add"
                />
              )}
            </div>
            {userRole === "client" &&
            clientDemandes?.clientRequests?.length > 0 ? (
              <TableofData
                tableHead={clientHead}
                tableBody={clientDemandes.clientRequests}
                handle={handleAction}
                filterOption={[
                  {
                    label: "Type de demande",
                    value: "type",
                  },
                ]}
                option={[action[1]]}
              />
            ) : (
              ""
            )}
            {userRole === "agent" && agentFolders.length > 0 ? (
              <TableofData
                tableHead={tableHead}
                tableBody={agentFolders}
                handle={handleAgentAgreedAction}
                option={[action[0]]}
                filterOption={[
                  {
                    label: "Type de demande",
                    value: "type",
                  },
                  {
                    label: "Nom & Prénoms",
                    value: "clientFullname",
                  },
                  {
                    label: "Commune du client",
                    value: "municipalitie",
                  },
                ]}
              />
            ) : (
              ""
            )}
            {(userRole === "superAdmin" || userRole === "secretary") &&
              clientRequests.length > 0 && (
                <TableofData
                  tableHead={tableHead2}
                  tableBody={clientRequests.map((item) => {
                    return {
                      ...item,
                      whoChangePrice: item?.whoChangePrice?.fullName
                        ? item?.whoChangePrice?.fullName
                        : "-",
                      whoChangeStatus: item?.whoChangeStatus?.fullName
                        ? item?.whoChangeStatus?.fullName
                        : "-",
                    };
                  })}
                  handle={handleManageDossier}
                  option={dotAction}
                  filterOption={[
                    {
                      label: "Type de demande",
                      value: "type",
                    },
                    {
                      label: "Nom & Prénoms",
                      value: "clientFullname",
                    },
                    {
                      label: "Id du dossier",
                      value: "_id",
                    },
                    {
                      label: "Commune du client",
                      value: "municipalitie",
                    },
                  ]}
                />
              )}
            {userRole === "businessFinder" &&
            clientDemandes?.clientRequests?.length > 0 ? (
              <TableofData
                tableHead={businessFinderHead}
                tableBody={clientDemandes.clientRequests}
                handle={handleAction}
                option={[action[1]]}
                filterOption={[
                  {
                    label: "Type de demande",
                    value: "type",
                  },
                  {
                    label: "Nom & Prénoms",
                    value: "clientFullname",
                  },
                ]}
              />
            ) : (
              ""
            )}
            {userRole === "investigator" && agentFolders.length > 0 ? (
              <TableofData
                tableHead={invetigatorHead}
                tableBody={agentFolders.map((item) => item.clientRequestId)}
                handle={handleAgentAgreedAction}
                option={[action[0]]}
                hideFilter
              />
            ) : (
              ""
            )}
            {(userRole === "superAdmin" || userRole === "secretary") &&
              clientRequests.length < 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "50px",
                    fontSize: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Img img="nothingFound.png" />
                  Aucune Demande
                </div>
              )}
            {(userRole === "client" || userRole === "businessFinder") &&
              clientDemandes.clientRequests.length < 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "50px",
                    fontSize: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Img img="nothingFound.png" />
                  Aucune Demande
                </div>
              )}
            {(userRole === "agent" || userRole === "investigator") &&
              agentFolders.length < 1 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "50px",
                    fontSize: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Img img="nothingFound.png" />
                  Aucune demande ne vous a été attribuées
                </div>
              )}
          </div>
        </>
      )}
    </>
  );
};

export default RequestList;
