const frLangs = {
  nav: {
    routes: {
      home: "Accueil",
      about: "À propos",
      services: "Services",
      serviceItem: {
        serviceItem1: "Veille immobilière",
        serviceItem2: "Conseils & Orientations",
        serviceItem3: "Investigations immobilières",
      },
    },
    action: {
      login: "Se connecter",
      signUp: "S'inscrire",
    },
  },
  homePage: {
    homeBanner: {
      info1: {
        desc: "Partout à travers le monde, le prix de chaque bien immobilier dépend de sa position par rapport à telle ou telle chose, du niveau de sécurité garanti par ses documents de preuve et de la circonstance dans laquelle son vendeur veut le céder à autrui.",
        buttonText: "Faire une demande",
      },
      info2: {
        desc: "Cependant, il est recommandé d'être très vigilant lors des transactions immobilières, surtout dans les pays où l'État n'a pas encore un cadre fiable, des corrompus et des mafieux contrôlent des acteurs du foncier et entretiennent l'inquiétude au sujet de la jouissance paisible d'un investissement immobilier fait.",
        buttonText: "Faire une demande",
      },
      info3: {
        desc: "Notre package de prestations vous aide à ne pas tomber dans des pièges sans fin. Pour cela, nous sommes à votre service pour le meilleur ! Cliquez ici pour solliciter l'une de nos prestations.",
        buttonText: "Faire une demande",
      },
    },
    homeActivity: {
      activity1: {
        title: "Veille immobilière",
        desc: "Nous veillons sur vos biens immobiliers, qu'ils soient bâtis ou non, en vous représentant sur place. Ceci est réalisé sans nécessiter l'apposition de plaques indiquant votre identité, respectant ainsi votre confidentialité dans le cadre légal.",
      },
      activity2: {
        title: "Conseils & Orientations",
        desc: "Nous offrons des conseils et des orientations spécialisés en investissement immobilier et vous accompagnons dans la gestion de litiges existants. Investissez désormais de manière avisée dans l'immobilier.",
        buttonText: "Faire une demande",
      },
      activity3: {
        title: "Investigations immobilières",
        desc: "Nous effectuons des vérifications approfondies sur la situation patrimoniale et juridique des biens immobiliers que vous envisagez d'acquérir, ou que vous possédez déjà, afin de garantir la sécurité de votre investissement et de prévenir tout obstacle à la jouissance paisible de vos propriétés.",
        buttonText: "Faire une demande",
      },
    },
    homeBriefing: {
      title: "Ce qu'il faut savoir sur nous",
      subTitle: "À PROPOS DE REFI",
      content: (
        <>
          Veille sur vos biens immobiliers (bâtis ou non bâtis) en vos lieux et
          places. Et ceci, sans que vous soyez obligés de mettre des plaques
          portant votre identité sur ces biens, pour des raisons de
          confidentialité (licite et légale).
          <br />
          <br />
          Nous donnons des conseils et des orientations, en matière
          d'investissement immobilier et nous accompagnons dans la gestion de
          litiges déjà nés. N'investissez plus n'importe comment en immobilier.
          <br />
          <br />
          Vérifications de la situation patrimoniale et juridique réelle des
          biens immobiliers que vous comptez acquérir, ou que vous avez déjà
          acquis, afin de vous garantir un investissement d'une part, mais aussi
          de vous permettre de prévenir toutes perturbations dans la jouissance
          paisible de vos biens.
        </>
      ),
      buttonText: "Découvrir Plus",
    },
    homeAgent: {
      title: "Agent de Terrain",
      subTitle: "Contactez un représentant pour de l'aide",
      buttonText: "Voir tous les agents",
    },
    homeInformations: {
      title: "Informations",
      subTitle: "Articles intéressants",
      buttonText: "Voir les Détails",
    },
    homeTestimony: {
      title: "Témoignage",
      subTitle: "L'avis de nos clients sur nos services",
    },
    homeBecomeAgent: {
      title: "Gagnez de l'argent avec REFI",
      subTitle:
        "Vous pouvez gagner de l’argent avec REFI depuis là où vous vivez. Comment ça marche ? Cliquez ici ! ",
      buttonText: "Travailler avec REFI",
    },
    homeBecomeAgentForm: {
      title: "Formulaire pour travailler avec REFI",
      identity_card: {
        label: "Carte d'identité ",
        placeholder: "Uploader un document",
      },
      ifu: {
        label: "N° ifu",
        placeholder: "Votre ifu",
      },
      criminalRecord: {
        label: "Casier judiciaire",
        placeholder: "Votre Casier judiciaire",
      },
      name: {
        label: "Nom",
        placeholder: "Insérez votre nom",
      },
      firstName: {
        label: "Prénoms",
        placeholder: "Insérez votre prénom",
      },
      phone: {
        label: "Téléphone (momo)",
        placeholder: "Insérez votre WhatsApp",
      },
      mail: {
        label: "Email ",
        placeholder: "Insérez votre email",
      },
      buttonText: "Soumettre ma candidature",
    },
  },
  authPage: {
    login: {
      title: "REFI",
      subTitle: (
        <>
          L'assurance dans <br /> toutes vos acquisitions <br /> immobilières
        </>
      ),
      formTitle: "Connexion",
      email: {
        label: <>E-mail</>,
        placeholder: "Votre email",
      },
      accessCode: {
        label: <>Code d'accès.</>,
        placeholder: "Votre code",
      },
      btnAccessCode: "Recevoir le code d'accès",
      btnConfirmAccessCode: "Confirmer",
    },
    signUp: {
      title: "Inscription compte Client",
      modal: {
        successMessage:
          "Vous avez bien été enregistré sur REFI, veuillez vérifier votre adresse email pour la prochaine étape",
        error: {
          userAlreadyExist: "Un utilisateur avec cet email existe déjà",
          invalidOperation: "L'opération n'a pas pu s'effectuer",
          invalidRole: "Rôle utilisateur inconnu",
        },
      },
      buttonText: "S'inscrire",
      formFields: {
        fullName: {
          label: "Noms & Prénoms",
          placeholder: "Noms & Prénoms",
        },
        phone: {
          label: "Téléphone",
          placeholder: "Votre contact",
        },
        whatsapp: {
          label: "WhatsApp",
          placeholder: "Votre contact WhatsApp",
        },
        email: {
          label: "Email",
          placeholder: "Votre email",
        },
        residence: {
          label: "Lieu de résidence",
          placeholder: "Votre lieu de résidence",
        },
      },
      yupValidation: {
        required: "Veuillez remplir ce champ",
        invalidNumber: "Numéro invalide",
        requiredNumber: "Vous devez entrer un numéro",
        email: {
          valid:
            "Veuillez entrer une adresse email valide, vérifiez aussi les espaces en trop.",
        },
        residence: {},
        phone: {
          min: "Vous devez entrer un minimum de 11 caractères",
          max: "Vous devez entrer un maximum de 11 caractères",
        },
        whatsapp: {
          min: "Vous devez entrer un minimum de 11 caractères",
          max: "Vous devez entrer un maximum de 11 caractères",
        },
        fullName: {},
      },
    },
  },
  mails: {
    mailValidation: {
      title: "Confirmation de votre mail",
      valideMailMessage: "Mail validé avec succès",
      invalidMailMessage: "Mail non validé",
    },
  },
  footer: {
    address: {
      title: "Adresse",
      refiSummary: "Toujours pas de description",
    },
    service: {
      title: "Nos services",
      service1: "Veille immobilière",
      service2: "Conseils & Orientations",
      service3: "Investigations immobilières",
    },
    links: {
      title: "Liens rapides",
      linkToAbout: "À Propos",
    },
    socialMedia: {
      title: "Réseaux sociaux",
    },
  },
};

export default frLangs;
