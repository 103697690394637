import React from "react";
import { useAuth } from "../context/Auth";
import Footer from "../routesComponents/global/Footer";
import { SimpleNavigation } from "../routesComponents/global/navs";
import { Navigate } from "react-router-dom";
import { Img } from "../ui-kits/kits";

const withoutAuth = (WrappedComponent, withLayout = true) => {
  return (props) => {
    const { user, userLoading } = useAuth();
    if (userLoading)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      );
    if (user) return <Navigate to="/Dashboard" replace />;
    return (
      <>
        {withLayout && <SimpleNavigation />}
        <WrappedComponent />
        {withLayout && <Footer />}
      </>
    );
  };
};

export default withoutAuth;
