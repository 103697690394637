import { NavLink, useLocation } from "react-router-dom";
import { CashRegister } from "../../assets/icons/Icon";
import { dashboardSidebarLinks } from "../../dataRaws/otherLinks";
import { useDispatch } from "react-redux";
import { closeMenu } from "../../store/actions/mobileMenuActions";
import { Img } from "../../ui-kits/kits";

export const DashSidebar = ({ props }) => {
  const { userRole, id } = props;
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  return (
    <div className="dash-sidebar">
      <div className="img">
        <Img img="logoLogin.png" alt="logo" />
      </div>
      <ul className="dash-sidebar-routes">
        {dashboardSidebarLinks[userRole].map((item, key) =>
          (() => {
            if (item.itemRoute === "no-route") {
              return (
                <li key={"Dashboard menu " + key} className="agent-type-list">
                  <span
                    className={`${
                      pathname.includes("Agent-list") && "dash-sidebar-active"
                    }`}
                    dataName="list"
                  >
                    {item.icon}
                    <p>{item.label}</p>
                  </span>
                  <div className="chooseTypeList">
                    <NavLink
                      to="/Agent-list/client"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Clients
                    </NavLink>
                    <NavLink
                      to="/Agent-list/apporteur-affaire"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Apporteurs d'affaires
                    </NavLink>
                    <NavLink
                      to="/Agent-list/agent-agréé"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Agents Agréés
                    </NavLink>
                    <NavLink
                      to="/Agent-list/staff"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Staffs
                    </NavLink>
                    <NavLink
                      to="/Agent-list/investigator"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Investigateurs
                    </NavLink>
                  </div>
                </li>
              );
            } else if (item.itemRoute === "no-home-route") {
              return (
                <li key={"Dashboard menu " + key} className={`agent-type-list`}>
                  <span
                    className={`${
                      pathname === "/DemandeApporteur" ||
                      pathname === "/Dashboard"
                        ? "dash-sidebar-active"
                        : ""
                    }`}
                    dataName="home"
                  >
                    {item.icon}
                    <p>{item.label}</p>
                  </span>
                  <div className="chooseTypeList">
                    <NavLink
                      to="/Dashboard"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Demande client
                    </NavLink>
                    <NavLink
                      to="/DemandeJob/businessFinder"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Demande Apporteur
                    </NavLink>
                    <NavLink
                      to="/DemandeJob/investigator"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Demande Investigateur
                    </NavLink>
                    <NavLink
                      to="/DemandeJob/agent"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Demande Agent
                    </NavLink>
                  </div>
                </li>
              );
            } else if (item.itemRoute === "no-param-route") {
              return (
                <li key={"Dashboard menu " + key} className="agent-type-list">
                  <span
                    className={`${
                      (pathname.includes("Configurated-account") ||
                        pathname.includes("Configurated-partners")) &&
                      "dash-sidebar-active"
                    }`}
                    dataName="param"
                  >
                    {item.icon}
                    <p>{item.label}</p>
                  </span>
                  <div className="chooseTypeList">
                    <NavLink
                      to="/launch-app"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Etat Application
                    </NavLink>
                    <NavLink
                      to="/Configurated-account"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Configurer un nouveau membre du staff
                    </NavLink>
                    <NavLink
                      to="/Configurated-partners"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Gerer les partenaires
                    </NavLink>
                    <NavLink
                      to="/Configurated-news"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Gerer les news
                    </NavLink>
                    <NavLink
                      to="/Configurated-testimony"
                      className={({ isActive }) =>
                        isActive ? "activeDashMenu" : ""
                      }
                      onClick={() => dispatch(closeMenu())}
                    >
                      &gt; Gérer les témoignages
                    </NavLink>
                  </div>
                </li>
              );
            } else if (item.label === "historyCommission") {
              return (
                <li key={"Dashboard menu " + key}>
                  <NavLink
                    to={`/CommissionPaiementAsked/${id}`}
                    className={({ isActive }) =>
                      isActive ? "dash-sidebar-active" : ""
                    }
                    onClick={() => dispatch(closeMenu())}
                  >
                    <CashRegister />
                    <p>Historique des commissions</p>
                  </NavLink>
                </li>
              );
            } else {
              return (
                <li key={"Dashboard menu " + key}>
                  <NavLink
                    to={item.itemRoute}
                    className={({ isActive }) =>
                      isActive ? "dash-sidebar-active" : ""
                    }
                    onClick={() => dispatch(closeMenu())}
                  >
                    {item.icon}
                    <p>{item.label}</p>
                  </NavLink>
                </li>
              );
            }
          })()
        )}
      </ul>
    </div>
  );
};
