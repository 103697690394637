import React from "react";

const ClientRequestAddType = (props) => {
  const { setCurrentDemandStep, setTypeOfAskChoosed } = props.customProps;
  const handleSubmit = (value) => {
    setTypeOfAskChoosed(value);
    value && setCurrentDemandStep(1);
  };
  const allOfRequirementsForTypeOfAsk = [
    {
      type: "Veille immobilière",
      id: "typeOfAsk-vi",
      requirement: (
        <ul>
          <li>Tous les documents du terrain.</li>
          <li>Levé topographique récent du terrain.</li>
          <li>Autres documents de l’objet.</li>
        </ul>
      ),
    },
    {
      type: "Conseils & Orientations",
      requirement: (
        <ul>
          <li> Copie d'une pièce d'identité valide du demandeur. </li>
          <li>Levé topographique récent du terrain.</li>
          <li>Tous les documents qui prouvent votre droit de propriété.</li>
        </ul>
      ),
      id: "typeOfAsk-co",
    },
    {
      type: "Investigations immobilières",
      id: "typeOfAsk-ii",
      requirement: (
        <ul>
          <li>Levé topographique récent du terrain.</li>
          <li>
            Tous les autres documents que le vendeur vous a remis à propos du
            terrain.
          </li>
          <li>Pièce d'identité valide de votre vendeur.</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="typeOfAsk">
      {/* <Link to="/About" className="back">
        <PhArrowLeftBold /> Retour
      </Link> */}
      <h2>Que souhaitez vous demander ?</h2>
      <div className="typeOfAsk-container">
        {/* <Formik
          initialValues={typeOfAskChoosed}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <div>
                {typeChoices.map((item, index) =>
                  FormikInputRadio(
                    index,
                    item,
                    typeOfAskChoosed === item.value,
                    setTypeOfAskChoosed
                  )
                )}
              </div>
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                {!typeOfAskChoosed && "veuillez choisir un type de prestation"}
              </span>
              <button type="submit" className="borderButton">
                Obtenir <PhArrowRightBold />{" "}
              </button>
            </Form>
          )}
        </Formik> */}
        <div className="typeOfAsk-requirements">
          {allOfRequirementsForTypeOfAsk.map((item) => (
            <div
              key={"Reuirements pour " + item.type}
              onClick={() => handleSubmit(item.type)}
            >
              <h1>{item.type}</h1>
              <h2>Document à fournir</h2>
              {item.requirement}
              {item.type === "Investigations immobilières" && (
                <p style={{ marginBottom: "10px" }}>
                  NB: Nous vous enverrons une liste de documents à compléter par
                  votre vendeur, en cas de pièces incomplètes
                </p>
              )}
              <p>
                Le coût vous sera communiqué une fois votre demande acceptée
              </p>
              <button>Faire une demande &gt;</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClientRequestAddType;
