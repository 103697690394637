import Home from "../pages/Home";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import About from "../pages/About";

import SignUp from "../pages/SignUp";

import ClientRequest from "../pages/ClientRequest";
import WorkerDemandesList from "../routesComponents/workerRequest/list";
import ComissionList from "../routesComponents/comissions/ComissionList";
import UsersSettings from "../routesComponents/UsersSettings/UsersSettings";
import AgentList from "../routesComponents/AgentsList/AgentList";
import Details from "../routesComponents/AgentsList/components/Details";
import VoirDetailsClients from "../routesComponents/clientRequest/components/VoirDetailsClients";
import AccountConfigurate from "../routesComponents/Configurated/AccountConfigurate";
import PartnersConfigurate from "../routesComponents/Configurated/PartnersConfigurate";
import ReportsNotices from "../routesComponents/clientRequest/components/ReportsNotices";
import ReadReports from "../routesComponents/clientRequest/components/ReadReports";
import InvestigationDemandesMunicipalities from "../routesComponents/investigators/InvestigationDemandesMunicipalities";
import PendingInvestigationsDemandes from "../routesComponents/investigators/PendingInvestigationsDemandes";
import InvestigationDemandesByMunicipalitie from "../routesComponents/investigators/InvestigationDemandesByMunicipalitie";
import InvestigatorMissionRequests from "../routesComponents/AgentsList/components/InvestigatorMissionRequests";
import TestimonyConfigurate from "../routesComponents/Configurated/TestimonyConfigurate";
import BusinnessFinderAskedPaiement from "../routesComponents/comissions/BusinnessFinderAskedPaiement";
import AllCommission from "../routesComponents/comissions/AllCommission";
import Terms from "../pages/Terms";
import NoticesWritter from "../routesComponents/clientRequest/components/NoticesWritter";
import MentionsLegales from "../pages/MentionsLegales";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import LaunchApp from "../pages/LaunchApp";
import RefiNewsConfigurate from "../routesComponents/Configurated/RefiNewsConfigurate";

export const notAuthRoutes = [
  {
    path: "/",
    requireAuth: false,
    element: <Home />,
  },
  {
    path: "/About",
    requireAuth: false,
    element: <About />,
  },
  {
    path: "/Terms",
    requireAuth: false,
    element: <Terms />,
  },
  // {
  //   path: "/Contact",
  //   requireAuth: false,
  //   element: <Contact />,
  // },
  {
    path: "/Login",
    requireAuth: false,
    element: <Login />,
  },
  {
    path: "/Signup",
    element: <SignUp />,
    requireAuth: false,
  },
  {
    path: "/Mentionslegales",
    element: <MentionsLegales />,
    requireAuth: false,
  },
  {
    path: "/PrivacyPolicy",
    element: <PrivacyPolicy />,
    requireAuth: false,
  },
  // {
  //   path: "/Mail/:action",
  //   requireAuth: false,
  //   element: <Mail />,
  // },
  // {
  //   path: "/Mail/:action/:actionId",
  //   requireAuth: false,
  //   element: <Mail />,
  // },
];

export const authedRoutes = [
  {
    path: "/Dashboard",
    requireAuth: true,
    element: <Dashboard />,
  },
  {
    path: "/launch-app",
    requireAuth: true,
    element: <LaunchApp />,
  },
  // {
  //   path: "/Client-request/:action/:actionId",
  //   requireAuth: true,
  //   element: <ClientRequest />,
  // },
  {
    path: "/Client-request/:action",
    requireAuth: null,
    element: <ClientRequest />,
    acces: ["client", "businessFinder"],
  },
  {
    path: "/Client-requete-details/:id",
    requireAuth: true,
    element: <VoirDetailsClients />,
    acces: ["client", "businessFinder"],
  },
  {
    path: "/Users-settings",
    requireAuth: true,
    element: <UsersSettings />,
    acces: ["agent", "investigator", "client", "businessFinder"],
  },
  {
    path: "/Money",
    requireAuth: true,
    element: <ComissionList />,
    acces: ["businessFinder"],
  },
  {
    path: "/Agent-list/:type",
    requireAuth: true,
    element: <AgentList />,
    acces: ["superAdmin", "secretary"],
  },
  {
    path: "/Agent-detail/:detail/:id",
    requireAuth: true,
    element: <Details />,
    acces: ["superAdmin", "secretary"],
  },
  {
    path: "/Write-note-on-demande/:requestId",
    requireAuth: true,
    element: <NoticesWritter />,
    acces: ["agent", "investigator"],
  },
  {
    path: "/Reports/:requestId/:contributorId",
    requireAuth: true,
    element: <ReportsNotices />,
    acces: ["agent", "investigator", "superAdmin", "secretary"],
  },
  {
    path: "/Read-reports/:requestId/:idNote",
    requireAuth: true,
    element: <ReadReports />,
    acces: ["agent", "investigator", "superAdmin", "secretary"],
  },
  {
    path: "/DemandeJob/:typeJob",
    requireAuth: true,
    element: <WorkerDemandesList />,
    acces: ["superAdmin", "secretary"],
  },
  {
    path: "/Configurated-account",
    requireAuth: true,
    element: <AccountConfigurate />,
    acces: ["superAdmin"],
  },
  {
    path: "/Configurated-partners",
    requireAuth: true,
    element: <PartnersConfigurate />,
    acces: ["superAdmin"],
  },
  {
    path: "/Configurated-news",
    requireAuth: true,
    element: <RefiNewsConfigurate />,
    acces: ["superAdmin"],
  },
  {
    path: "/Configurated-testimony",
    requireAuth: true,
    element: <TestimonyConfigurate />,
    acces: ["superAdmin"],
  },

  {
    requireAuth: true,
    path: "/AddInvestigationDemandes",
    element: <InvestigationDemandesMunicipalities />,
    acces: ["investigator"],
  },
  {
    requireAuth: true,
    path: "/InvestigationDemandesMunicipalities/:municipalitie",
    element: <InvestigationDemandesByMunicipalitie />,
    acces: ["investigator"],
  },
  {
    requireAuth: true,
    path: "/InvestiagionMissionDeamndes/:id",
    element: <InvestigatorMissionRequests />,
    acces: ["agent"],
  },
  {
    requireAuth: true,
    path: "/SeePendingInvestigationDemandes",
    element: <PendingInvestigationsDemandes />,
    acces: ["investigator"],
  },
  {
    requireAuth: true,
    path: "/CommissionPaiementAsked/:id",
    element: <BusinnessFinderAskedPaiement />,
    acces: ["superAdmin", "businessFinder", "secretary"],
  },
  {
    requireAuth: true,
    path: "/AllCommission",
    element: <AllCommission />,
    acces: ["superAdmin"],
  },
];

// {
//   path: "/:update/:id",
//   requireAuth: true,
//   element: <UpdateAgents />,
// },

// {
//   path: "*",
//   element: <ErrorPage buttonRoute="/" buttonLabel="Allez sur l'accueil" />,
// },
