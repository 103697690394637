import Langs from "../language";

export function langSelector(sectionName) {
    let selectedLang = 'fr';
    let langData = null;

    if (localStorage.getItem.refi_lang == 'en') {

    }
    langData = Langs()[selectedLang];
    return langData[sectionName];
}