import { Form, Formik, ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "../../assets/icons/Icon";
import { FormikInputTextOrMail } from "../../ui-kits/form";
import withAuth from "../../hoc/WithAuth";
const AccountConfigurate = () => {
  const [accountInfo, setAccountInfo] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    whatsapp: "",
    email: "",
    role: "",
    municipalitie: "",
  });

  const [loading, setLoading] = useState(false);
  const inputArrays = [
    {
      label: "Prénom",
      name: "firstname",
      type: "text",
      value: accountInfo.firstname,
    },
    {
      label: "Nom de famille",
      name: "lastname",
      type: "text",
      value: accountInfo.lastname,
    },
    {
      label: "Email",
      name: "email",
      type: "mail",
      value: accountInfo.email,
    },
    {
      label: "Téléphone",
      name: "phone",
      type: "tel",
      value: accountInfo.phone,
    },
    {
      label: "Whatsapp",
      name: "whatsapp",
      type: "tel",
      value: accountInfo.whatsapp,
    },
    {
      label: "Rôle",
      name: "role",
      type: "select",
      value: accountInfo.role,
    },
    {
      label: "Commune de residence",
      name: "municipalitie",
      type: "text",
      value: accountInfo.municipalitie,
    },
  ];

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Veuillez remplir ce champ"),
    lastname: Yup.string().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .email(
        "Veuillez entrer un mail valide, verifiez aussi les espaces en trop."
      )
      .required("Veuillez remplir ce champ"),
    phone: Yup.number().required("Veuillez remplir ce champ"),
    whatsapp: Yup.number().required("Veuillez remplir ce champ"),
    role: Yup.string().required("Veuillez remplir ce champ"),
    municipalitie: Yup.string().required("Veuillez remplir ce champ"),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post("admin/user/create", {
        ...accountInfo,
      })
      .then((response) => {
        toast.success("Utilisateur configuré avec succès");
      })
      .catch((error) => {
        toast.error(
          "Un utilisateur est déjà configuré avec ce mail ou ce numéro"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="add-request-components dashboard-base-param">
      <h2>Configurer un compte d'utilisateur</h2>
      <div style={{ marginTop: "50px" }} className="user-configuration">
        <Formik
          initialValues={accountInfo}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="user-configuration-container">
                {inputArrays.map((item) =>
                  FormikInputTextOrMail(item, false, handleInputChange)
                )}
              </div>
              <button
                type="submit"
                style={{
                  background: "#0d6e29",
                  color: "white",
                  border: "none",
                  width: "max-content",
                  borderRadius: "5px",
                }}
              >
                {loading ? <Spinner /> : "Enregister"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withAuth(AccountConfigurate, ["superAdmin"]);
