export const newPartnersField = [
  {
    label: `Le nom du partenaire`,
    name: "partner_name",
  },
  {
    label: `Logo du partenaire`,
    name: "logo",
  },
  {
    label: "Le lien du site du partenaire",
    name: "partner_link",
  },
];

export const newRefiNewsField = [
  {
    label: `Le titre de la news`,
    name: "title",
  },
  {
    label: `L'image de la news`,
    name: "picture",
  },
  {
    label: "Le lien du site de la news",
    name: "link",
  },
];

export const testimonialsField = [
  {
    label: `Nom & Prénoms du témoin`,
    name: "username",
  },
  {
    label: `Contenu du témoignagne`,
    name: "content",
  },
  {
    label: `Photo du témoin`,
    name: "picture",
  },
];
