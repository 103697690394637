import React, { useEffect, useState } from "react";
import withAuth from "../hoc/WithAuth";
import axios from "axios";

function LaunchApp() {
  let [loading, setLoading] = useState(true);
  let [appLive, setAppLive] = useState(false);
  useEffect(() => {
    axios
      .get("/admin/app-status")
      .then((res) => setAppLive(res.data))
      .catch((err) => null)
      .finally((item) => setLoading(false));
  }, []);

  function toggleAppStatus() {
    axios
      .post("/admin/change-app-status", { launch: !appLive })
      .then((res) => window.location.reload())
      .catch((err) => null)
      .finally((item) => setLoading(false));
  }

  if (loading) return <p>Chargement....</p>;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px 0",
        alignItems: "center",
      }}
    >
      <h1>Refi est actuellement {appLive ? "Ouvert" : "Fermer"} au public</h1>
      <button className="btn btnBg btnSimple" onClick={toggleAppStatus}>
        {appLive ? "Fermer" : "Ouvrir"} au public
      </button>
    </div>
  );
}

export default withAuth(LaunchApp, "superAdmin");
