import React, { useState } from "react";
import { IconDocLght, PhEyeBold, Spinner } from "../../../../assets/icons/Icon";
import {
  SubmitBorderButton,
  SubmitBackgroundButton,
} from "../../../../ui-kits/kits";
import axios from "axios";
import { toast } from "react-toastify";

const ReadAndConfirmClientRequest = (props) => {
  const {
    setCurrentStep,
    setDistance,
    data,
    authed,
    typeOfAskChoosed,
    userRole,
  } = props;
  const [disabled, setDisabled] = useState(false);
  async function addRequest() {
    setDisabled(true);
    const formData = new FormData();
    formData.append("clientId", authed._id);
    formData.append("type", data.type);
    formData.append("email", data.email);
    for (let i = 0; i < data.topographic_survey.length; i++) {
      formData.append("topographic_survey", data.topographic_survey[i]);
    }
    for (let i = 0; i < data.land_documents.length; i++) {
      formData.append("land_documents", data.land_documents[i]);
    }
    for (let i = 0; i < data.other_documents.length; i++) {
      formData.append("other_documents", data.other_documents[i]);
    }
    for (let i = 0; i < data.identity_card.length; i++) {
      formData.append("identity_card", data.identity_card[i]);
    }
    for (let i = 0; i < data.docs_land_infos.length; i++) {
      formData.append("docs_land_infos", data.docs_land_infos[i]);
    }
    for (let i = 0; i < data.seller_identity_card.length; i++) {
      formData.append("seller_identity_card", data.seller_identity_card[i]);
    }
    formData.append("requestReason", data.requestReason);
    formData.append("clientFullname", data.clientFullname);
    formData.append(
      "investigationMunicipalitie",
      data.investigationMunicipalitie
    );
    formData.append("municipalitie", data.municipalitie);
    try {
      const addCR = await axios.post("/client-requests/add", formData);
      toast.success("Demande soumise");
      handleMoov("next");
      setDisabled(false);
    } catch (error) {
      toast.error("Une erreur est survenue ");
      setDisabled(false);
    }
  }
  const handleMoov = (whereWego) => {
    if (whereWego === "prev") {
      setCurrentStep((prev) => prev - 1);
      setDistance((prev) => prev - 34);
    } else {
      setCurrentStep((prev) => prev + 1);
      // setDistance((prev) => prev + 25);
    }
  };
  const documents = [
    {
      key: "topographic_survey",
      label: "Levé topographique récent du terrain.",
    },
    {
      key: "identity_card",
      label: "Copie d'une pièce d'identité valide du demandeur.",
    },
    {
      key: "seller_identity_card",
      label: "Pièce d'identité valide de votre vendeur",
    },
    {
      key: "docs_land_infos",
      label: "Tous les autres documents",
    },
    {
      key: "land_documents",
      label: "Les documents du terrain",
    },
    {
      key: "other_documents",
      label: "Autres documents",
    },
  ];

  return (
    <div className="ask-presentation">
      <h3>Details du client</h3>
      <table>
        {userRole === "client" && (
          <tr>
            <th>Nom & Prénoms :</th>
            <td>{data.name}</td>
          </tr>
        )}
        {userRole !== "client" && (
          <tr>
            <th>Nom prenoms du client :</th>
            <td>{data.clientFullname}</td>
          </tr>
        )}
        <tr>
          <th>N° Téléphone :</th>
          <td>{data.whatsappContact}</td>
        </tr>
        <tr>
          <th>Email :</th>
          <td>{data.email}</td>
        </tr>
        <tr>
          <th>Lieu de résidence :</th>
          <td>{data.municipalitie}</td>
        </tr>

        {typeOfAskChoosed === "Conseils & Orientations" && (
          <tr>
            <th>Motif de la demandes de conseils :</th>
            <td>{data.requestReason}</td>
          </tr>
        )}
        {typeOfAskChoosed === "Investigations immobilières" && (
          <tr>
            <th>Lieu de l'enquête :</th>
            <td>{data.investigationMunicipalitie}</td>
          </tr>
        )}
      </table>
      <h3 style={{ marginBottom: "10px" }}>Documents du bien immobilier </h3>

      {documents.map((document, index) =>
        Array.isArray(data[document.key]) && data[document.key].length > 0
          ? data[document.key].map((item, ind) => (
              <div className="documents" key={ind}>
                <div>
                  <IconDocLght />
                  {document.label}
                </div>
                <a
                  href={URL.createObjectURL(item)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PhEyeBold />
                </a>
              </div>
            ))
          : null
      )}

      <div className="paginate-button">
        <div>
          <SubmitBorderButton
            onClick={() => handleMoov("prev")}
            label="Précédent"
            disabled={disabled}
          />
          <SubmitBackgroundButton
            onClick={addRequest}
            label={disabled ? <Spinner /> : "Suivant"}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ReadAndConfirmClientRequest;
