import { Field, ErrorMessage } from "formik";
import { useState } from "react";
import { PhEyeBold, PhEyeClosedBold, UploadIcon } from "../assets/icons/Icon";
import { RoleTab, communesBenin } from "../dataRaws/selectOptions";
export function FormikInputTextOrMail(item, key = false, handle, list) {
  return (
    <div key={"Champ input simple ou email " + key + " pour " + item.name}>
      {item.name === "role" ? (
        <>
          <label htmlFor="">
            {item.label} <span>*</span>
          </label>
          <div className="formikFormField">
            <Field name={item.name} as="select" onChange={handle}>
              <option value="">Selectionner un rôle</option>
              <option value="secretary">Sécrétaire</option>
              {/* <option value="accountant">Comptable</option>   */}
            </Field>
          </div>
          <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
            <ErrorMessage name={item.name} />
          </span>
        </>
      ) : item.name === "status" ? (
        <>
          <label htmlFor="">
            {item.label} <span>*</span>
          </label>
          <div className="formikFormField">
            <Field name={item.name} as="select" onChange={handle}>
              <option value="">Sélectionner un status</option>
              {[
                { label: "Dossier Traité", value: "ended" },
                { label: "En cours de traitement", value: "paid" },
              ].map((status) => (
                <option key={status.label} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Field>
          </div>
          <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
            <ErrorMessage name={item.name} />
          </span>
        </>
      ) : item.name === "agentId" ? (
        <>
          <label htmlFor="">
            {item.label} <span>*</span>
          </label>
          <div className="formikFormField">
            <Field name={item.name} as="select" onChange={handle}>
              <option value="">Sélectionner un agent</option>
              {list.map((agent) => (
                <option key={agent.fullName} value={agent._id}>
                  {agent.fullName}
                </option>
              ))}
            </Field>
          </div>
          <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
            <ErrorMessage name={item.name} />
          </span>
        </>
      ) : item.name === "investigationMunicipalitie" ||
        item.name === "municipalitie" ? (
        <>
          {handle ? (
            <>
              <label htmlFor="">
                {item.label} <span>*</span>
              </label>
              <div className="formikFormField">
                <Field name={item.name} as="select" onChange={handle}>
                  <option value="">Sélectionner une commune</option>
                  {communesBenin.map((commune) => (
                    <option key={commune} value={commune}>
                      {commune}
                    </option>
                  ))}
                </Field>
              </div>
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                <ErrorMessage name={item.name} />
              </span>
            </>
          ) : (
            <>
              <label htmlFor="">
                {item.label} <span>*</span>
              </label>
              <div className="formikFormField">
                <Field name={item.name} as="select">
                  <option value="">Sélectionner une commune</option>
                  {communesBenin.map((commune) => (
                    <option key={commune} value={commune}>
                      {commune}
                    </option>
                  ))}
                </Field>
              </div>
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                <ErrorMessage name={item.name} />
              </span>
            </>
          )}
        </>
      ) : item.name === "workerType" ? (
        <>
          {handle ? (
            <>
              <label htmlFor="">
                {item.label} <span>*</span>
              </label>
              <div className="formikFormField">
                <Field name={item.name} as="select" onChange={handle}>
                  <option value="">Sélectionner un poste</option>
                  {RoleTab.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </Field>
              </div>
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                <ErrorMessage name={item.name} />
              </span>
            </>
          ) : (
            <>
              <label htmlFor="">
                {item.label} <span>*</span>
              </label>
              <div className="formikFormField">
                <Field name={item.name} as="select">
                  <option value="">Sélectionner un poste</option>
                  {RoleTab.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </Field>
              </div>
              <span
                className="input-errors"
                style={{ marginBottom: 10 + "px" }}
              >
                <ErrorMessage name={item.name} />
              </span>
            </>
          )}
        </>
      ) : (
        <>
          <label htmlFor="">
            {item.label} <span>*</span>
          </label>
          <div className="formikFormField">
            {handle ? (
              <Field
                name={item.name}
                type={item.type}
                placeholder={item.placeholder}
                onChange={handle}
                disabled={item.readOnly ? item.readOnly : false}
              ></Field>
            ) : (
              <Field
                name={item.name}
                type={item.type}
                placeholder={item.placeholder}
                readOnly={item.readOnly ? item.readOnly : false}
                // readOnly={
                //   whereWeUse === "identity" || item.name === "motif"
                //     ? true
                //     : false
                // }
              ></Field>
            )}
            {item.icon}
          </div>
          <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
            <ErrorMessage name={item.name} />
          </span>
        </>
      )}
    </div>
  );
}

export function FormikInputRadio(index, item, checkCond, setToState) {
  return (
    <div key={"Champ input radio nb" + index + " pour " + item.name}>
      <div className="formikFormFieldRadio">
        <Field
          name={item.value}
          type={"radio"}
          value={item.value}
          id={item.id}
          checked={checkCond}
          onChange={(e) => setToState(e.target.value)}
        ></Field>
        <span></span>
        <label htmlFor={item.id}>{item.label}</label>
      </div>
      <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
        <ErrorMessage name={item.name} />
      </span>
    </div>
  );
}

export function FormikInputPasword(item) {
  const [inputState, setInputState] = useState("notShow");

  function showPassword() {
    if (inputState === "show") {
      setInputState("notShow");
    } else if (inputState === "notShow") {
      setInputState("show");
    }
  }

  const pwdIcons = {
    show: <PhEyeClosedBold />,
    notShow: <PhEyeBold />,
  };
  return (
    <div key={"Champ " + item.placeholder + " pour l'inscription"}>
      <label htmlFor="">
        {item.label} <span>*</span>
      </label>
      <div className="formikFormField">
        <Field
          name={item.name}
          type={inputState === "notShow" ? "text" : item.type}
          placeholder={item.placeholder}
        ></Field>
        <span onClick={showPassword}>{pwdIcons[inputState]}</span>
      </div>
      <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
        <ErrorMessage name={item.name} />
      </span>
    </div>
  );
}

export function FormikInputFile(
  index,
  item,
  formik,
  setData,
  data,
  demande = false
) {
  const handleData = (e) => {
    if (
      [
        "topographic_survey",
        "land_documents",
        "other_documents",
        "identity_card",
        "docs_land_infos",
        "seller_identity_card",
      ].includes(item?.name)
    ) {
      setData((prev) => ({
        ...prev,
        [item?.name]: Array.from(e.target.files),
      }));
      formik.setFieldValue(item?.name, Array.from(e.target.files));
    } else {
      setData((prev) => ({
        ...prev,
        [item?.name]: e.target.files[0],
      }));
      formik.setFieldValue(item?.name, e.target.files[0]);
    }
  };

  return (
    <div key={"Champ input file nb" + index + " pour " + item.name}>
      <label htmlFor={item?.name}>{item?.label}</label>
      <div className="formikFormFieldFile">
        <input
          name={item?.name}
          type={"file"}
          accept=".pdf, .png, .jpg, .jpeg, .heic, image/*"
          // capture="user"
          multiple={demande ? true : false}
          onChange={(e) => handleData(e)}
        />

        <span className="text">
          <p>
            {data[item?.name] && !Array.isArray(data[item?.name])
              ? data[item?.name].name
              : Array.isArray(data[item?.name]) && data[item?.name].length === 1
              ? data[item?.name][0].name
              : Array.isArray(data[item?.name]) && data[item?.name].length > 1
              ? data[item?.name].length + " fichier(s)"
              : "Selectionner un fichier..."}
          </p>

          <UploadIcon />
        </span>
      </div>
      <span className="input-errors" style={{ marginBottom: 10 + "px" }}>
        <ErrorMessage name={item?.name} />
      </span>
    </div>
  );
}
