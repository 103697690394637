import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  ArrowLeftRounded,
  DocIcon,
  PhMapPinLineBold,
  PhPaperPlaneTiltBold,
  PhPhoneBold,
  Spinner,
} from "../../../assets/icons/Icon";

import { langSelector } from "../../../helpers/langs";
import axios from "axios";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FormikInputFile, FormikInputTextOrMail } from "../../../ui-kits/form";
import { OperationStatusModal } from "../../../ui-kits/Modal";
import { useModal } from "../../../context/Modal";
import Steppers from "../../global/Steppers";

const steps = [
  {
    step: 1,
  },
  {
    step: 2,
  },
];

const BecomeAgentForm = (props) => {
  const l = langSelector("homePage").homeBecomeAgentForm;

  const { showModal, hideModal } = useModal();

  const [loading, setLoading] = useState(false);

  const [candidatToBeAgentInfo, setCandidatToBeAgentInfo] = useState({
    identity_card_single: "",
    ifu: "",
    criminal_records: "",
    lastname: "",
    firstname: "",
    phone: "",
    email: "",
    whatsapp: "",
    workerType: "",
    municipalitie: "",
  });

  const handleSubmit = async (formik) => {
    setCandidatToBeAgentInfo(formik);
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        "identity_card_single",
        candidatToBeAgentInfo.identity_card_single
      );
      formData.append("ifu", candidatToBeAgentInfo.ifu);
      formData.append(
        "criminal_records",
        candidatToBeAgentInfo.criminal_records
      );
      formData.append("lastname", candidatToBeAgentInfo.lastname);
      formData.append("firstname", candidatToBeAgentInfo.firstname);
      formData.append("phone", candidatToBeAgentInfo.phone);
      formData.append("whatsapp", candidatToBeAgentInfo.whatsapp);
      formData.append("email", candidatToBeAgentInfo.email);
      formData.append("workerType", candidatToBeAgentInfo.workerType);
      formData.append("municipalitie", candidatToBeAgentInfo.municipalitie);
      await axios.post("/worker-requests/add", formData);

      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message:
              "Votre demande a été soumise nous vous contacterons par mail pour la suite de la candidature",
          }}
        />
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data.message.userAlreadyExist) {
        return toast.error(
          "Ce mail ou ces contacts sont déjà utilisés par une demande de travail."
        );
      }
      toast.error("Une erreur a été rencontrer");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "identity_card_single" ||
      name === "criminal_records" ||
      name === "ifu"
    ) {
      if (e.target.files[0]) {
        setCandidatToBeAgentInfo((prev) => ({
          ...prev,
          [name]: e.target.files[0],
        }));
      } else {
        setCandidatToBeAgentInfo((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setCandidatToBeAgentInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <>
      <p className="becomeAgentForm-head">
        <span onClick={() => hideModal()}>
          <ArrowLeftRounded />
        </span>
        {l.title}
        <span></span>
      </p>
      <Steppers
        steps={steps}
        simple
        currentStep={currentStep}
        style={{ width: "100%" }}
      />
      {currentStep === 1 && (
        <FormStep1
          candidatToBeAgentInfo={candidatToBeAgentInfo}
          handleSubmit={() => setCurrentStep(2)}
          handleInputChange={handleInputChange}
          setCandidatToBeAgentInfo={setCandidatToBeAgentInfo}
        />
      )}
      {currentStep === 2 && (
        <FormStep2
          candidatToBeAgentInfo={candidatToBeAgentInfo}
          handleSubmit={handleSubmit}
          setCandidatToBeAgentInfo={setCandidatToBeAgentInfo}
          handleBack={() => setCurrentStep(1)}
          loading={loading}
        />
      )}
    </>
  );
};

export default BecomeAgentForm;

const FormStep1 = ({
  candidatToBeAgentInfo,
  handleInputChange,
  setCandidatToBeAgentInfo,
  handleSubmit,
}) => {
  const l = langSelector("homePage").homeBecomeAgentForm;
  const inputArray = [
    {
      name: "workerType",
      label: "Poste convoité",
      value: candidatToBeAgentInfo.name,
    },
    {
      type: "text",
      name: "lastname",
      label: l.name.label,
      placeholder: l.name.placeholder,
      value: candidatToBeAgentInfo.name,
    },
    {
      type: "text",
      name: "firstname",
      label: l.firstName.label,
      placeholder: l.firstName.placeholder,
      value: candidatToBeAgentInfo.firstname,
    },
    {
      type: "mail",
      name: "email",
      label: l.mail.label,
      placeholder: l.mail.placeholder,
      icon: <PhPaperPlaneTiltBold />,
      value: candidatToBeAgentInfo.email,
    },
    {
      label: "Commune de résidence",
      icon: <PhMapPinLineBold />,
      name: "municipalitie",
    },
    {
      type: "tel",
      name: "phone",
      label: l.phone.label,
      placeholder: l.phone.placeholder,
      icon: <PhPhoneBold />,
      value: candidatToBeAgentInfo.phone,
    },
    {
      type: "tel",
      name: "whatsapp",
      label: "Numéro Whatsapp",
      placeholder: "Numéro Whatsapp",
      icon: <PhPhoneBold />,
      value: candidatToBeAgentInfo.whatsapp,
    },
  ];
  const validationSchema = Yup.object({
    lastname: Yup.string().required("Veuillez remplir ce champ"),
    firstname: Yup.string().required("Veuillez remplir ce champ"),
    phone: Yup.number().required("Veuillez remplir ce champ"),
    whatsapp: Yup.number().required("Veuillez remplir ce champ"),
    email: Yup.string()
      .email(
        "Veuillez entrer un mail valide, verifiez aussi les espaces en trop."
      )
      .required("Veuillez remplir ce champ"),
    workerType: Yup.string().required("Veuillez remplir ce champ"),
    municipalitie: Yup.string().required("Veuillez remplir ce champ"),
  });
  return (
    <Formik
      initialValues={candidatToBeAgentInfo}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => (
        <Form className="becomeAgentForm">
          <div className="becomeAgentForm-input-container">
            {inputArray.map((item, index) =>
              item?.name === "phone" || item?.name === "whatsapp" ? (
                <div key={"inscription" + item.name}>
                  <label htmlFor="">
                    {item?.label} <span>*</span>
                  </label>
                  <PhoneInput
                    country={"bj"}
                    value={candidatToBeAgentInfo[item?.name]}
                    onChange={(e) =>
                      setCandidatToBeAgentInfo({
                        ...candidatToBeAgentInfo,
                        [item?.name]: e,
                      })
                    }
                    placeholder="+229 00 00 00 00"
                  />

                  <span
                    className="input-errors"
                    style={{ marginBottom: 10 + "px" }}
                  >
                    <ErrorMessage name={item?.name} />
                  </span>
                </div>
              ) : (
                <React.Fragment key={index}>
                  {FormikInputTextOrMail(item, false, handleInputChange)}
                </React.Fragment>
              )
            )}
          </div>
          <button type="submit">Suivant</button>
        </Form>
      )}
    </Formik>
  );
};

const FormStep2 = ({
  candidatToBeAgentInfo,
  handleSubmit,
  setCandidatToBeAgentInfo,
  handleBack,
  loading,
}) => {
  const l = langSelector("homePage").homeBecomeAgentForm;
  const inputArray = [
    {
      type: "file",
      name: "identity_card_single",
      label: l.identity_card.label,
    },
    {
      type: "file",
      name: "ifu",
      label: "Document ifu",
    },
    {
      type: "file",
      name: "criminal_records",
      label: l.criminalRecord.label,
    },
  ];
  const validationSchema = Yup.object({
    identity_card_single: Yup.mixed()
      .test(
        "fileType",
        "Seuls les fichiers PDF, PNG, JPG, JPEG et HEIC sont acceptés",
        (value) => {
          if (value) {
            const acceptedFileTypes = [
              "application/pdf",
              "image/png",
              "image/jpeg",
              "image/jpg",
              "image/heic",
            ];
            return acceptedFileTypes.includes(value.type);
          }
          return true;
        }
      )
      .required("Veuillez sélectionner un fichier"),
    ifu: Yup.mixed()
      .test(
        "fileType",
        "Seuls les fichiers PDF, PNG, JPG, JPEG et HEIC sont acceptés",
        (value) => {
          if (value) {
            const acceptedFileTypes = [
              "application/pdf",
              "image/png",
              "image/jpeg",
              "image/jpg",
              "image/heic",
            ];
            return acceptedFileTypes.includes(value.type);
          }
          return true;
        }
      )
      .required("Veuillez sélectionner un fichier"),
    criminal_records: Yup.mixed()
      .test(
        "fileType",
        "Seuls les fichiers PDF, PNG, JPG, JPEG et HEIC sont acceptés",
        (value) => {
          if (value) {
            const acceptedFileTypes = [
              "application/pdf",
              "image/png",
              "image/jpeg",
              "image/jpg",
              "image/heic",
            ];
            return acceptedFileTypes.includes(value.type);
          }
          return true;
        }
      )
      .required("Veuillez sélectionner un fichier"),
  });
  return (
    <>
      <div className="upld-doc-lib">
        <DocIcon />
        <div>
          <h2>Les pièces à fournir pour finaliser votre inscription</h2>
          <p>Vous êtes priés de soumettre les pièces suivantes.</p>
        </div>
      </div>
      <Formik
        initialValues={candidatToBeAgentInfo}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formik) => (
          <Form className="becomeAgentForm">
            <div className="becomeAgentForm-input-container">
              {inputArray.map((item, index) =>
                FormikInputFile(
                  index,
                  item,
                  formik,
                  setCandidatToBeAgentInfo,
                  candidatToBeAgentInfo
                )
              )}
            </div>
            <div className="form-action-btn">
              <button type="button" onClick={() => handleBack()}>
                Précédent
              </button>
              <button type="submit" disabled={loading}>
                {loading ? <Spinner /> : "Soumettre"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
