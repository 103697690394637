import React from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  PhArrowDownBold,
  PhListBold,
  PhCaretDownBold,
  ArrowDown,
  PhDoorBold,
} from "../../assets/icons/Icon";
import { simpleNavLinks, servicesLinks } from "../../dataRaws/otherLinks";

import { Img, GreenButton, BlackButton } from "../../ui-kits/kits";
import { useModal } from "../../context/Modal";
import { toast } from "react-toastify";
import SignupForModal from "../../pages/SignUpForModal";
import { useAuth } from "../../context/Auth";
import { openMenu } from "../../store/actions/mobileMenuActions";
import { useDispatch } from "react-redux";

export const SimpleNavigation = () => {
  const [showDrop, setShowDrop] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  return (
    <nav className={`navigation`}>
      <DesktopMenu
        navItems={simpleNavLinks}
        pathname={pathname}
        serviceItems={servicesLinks}
      />
      <SimpleMobileMenu
        setShowDrop={setShowDrop}
        showDrop={showDrop}
        navItems={simpleNavLinks}
        serviceItems={servicesLinks}
      />
    </nav>
  );
};

const DesktopMenu = (props) => {
  const { navItems, pathname, serviceItems } = props;
  const [showServices, setShowServices] = useState(false);
  const { showModal } = useModal();
  const showInscriptionModal = () => {
    showModal(<SignupForModal />, true);
  };
  return (
    <>
      <div>
        <a href="/">
          <Img img="logo.png" alt="logo" />
        </a>
      </div>
      <ul className="pages">
        {navItems.map((item) =>
          item.label === "Services" ? (
            <li
              key={"Destop menu pages " + item.label}
              className="services"
              onClick={() => setShowServices(!showServices)}
            >
              <span>
                {item.label}
                <PhCaretDownBold />
              </span>

              <ul
                className={`services-container ${
                  showServices ? "show-services-container" : ""
                }`}
              >
                {serviceItems.map((item) => (
                  <li
                    key={"Destop Servise sous dropdown element " + item.label}
                  >
                    <NavLink
                      to={item.route}
                      onClick={() => {
                        setShowServices(false);
                        toast.warn("Veuillez vous connecter s'il vous plaît");
                      }}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <>
              <li key={"Desktop menu pages " + item.label}>
                <Link
                  to={item.route}
                  className={pathname === item.route ? "active" : ""}
                >
                  {item.label}
                </Link>
              </li>
            </>
          )
        )}
      </ul>
      <ul className="action-pages">
        {[
          { route: "/login", label: "Se connecter" },
          { route: "/Signup", label: "S'inscrire" },
        ].map((item, index) => (
          <li key={"Destop menu action-pages " + item.label}>
            {item.route === "/login" ? (
              <GreenButton route={item.route} label={item.label} key={index} />
            ) : (
              <BlackButton
                label={item.label}
                key={index}
                onClick={() => showInscriptionModal()}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

const SimpleMobileMenu = (props) => {
  const { showDrop, setShowDrop, navItems, serviceItems } = props;
  const [showServices, setShowServices] = useState(false);
  const setAllStateToFalse = () => {
    setShowServices(false);
    setShowDrop(false);
  };
  const { showModal } = useModal();
  const showInscriptionModal = () => {
    showModal(<SignupForModal />, true);
  };
  return (
    <>
      <div className="menu" onClick={() => setShowDrop(!showDrop)}>
        <PhListBold />
      </div>
      <div className={`${showDrop ? "show-dropdown" : ""} menu-dropdow`}>
        <div className="pages">
          {navItems.map((item) =>
            item.label === "Services" ? (
              <li
                key={"Mobile menu pages " + item.label}
                className="mobile-services"
                onClick={() => setShowServices(!showServices)}
              >
                <span>
                  {item.label}
                  <PhArrowDownBold />
                </span>
                <ul
                  className={`mobile-services-container ${
                    showServices ? "show-mobile-services-container" : ""
                  }`}
                >
                  {serviceItems.map((item) => (
                    <li
                      key={"Mobile Servise sous dropdown element " + item.label}
                    >
                      <NavLink to={item.route} onClick={setAllStateToFalse}>
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            ) : (
              <li key={"Mobile menu pages " + item.label}>
                <Link to={item.route} onClick={() => setShowDrop(false)}>
                  {item.label}
                </Link>
              </li>
            )
          )}
        </div>
        <GreenButton route={"/"} label={"Se connecter"} />
        <BlackButton
          label={"S'inscrire"}
          onClick={() => showInscriptionModal()}
        />
      </div>
    </>
  );
};

export function DashNav() {
  const { user } = useAuth();
  const authed = user;
  const dispatch = useDispatch();
  const [showPopUp, setShowPopUp] = useState(false);
  const showMobileMenu = () => {
    dispatch(openMenu());
  };
  const { logout } = useAuth();
  const makeLogout = () => {
    logout();
  };
  return (
    <div className="dashboardNav">
      <div className="dashboardNav-item">
        <div className="dashboardNav-hamburger" onClick={showMobileMenu}>
          <PhListBold />
        </div>
        <div className="dashboardNav-info">
          <div className="dashboardNav-info-userInfo">
            <Img img="accountPhoto.png" alt="" />
            <div>
              <span className="dashboardNav-info-userInfo-userName">
                {authed?.fullName}
              </span>
              <p>{authed.email}</p>
              <small>{authed.role}</small>
            </div>
            <div
              className="disconnect"
              onClick={() => setShowPopUp((prev) => !prev)}
            >
              <ArrowDown />
            </div>
          </div>
        </div>
      </div>
      {showPopUp && (
        <div className="logout-popUp" onClick={() => makeLogout()}>
          <p>
            <PhDoorBold /> Se déconnecter
          </p>
        </div>
      )}
    </div>
  );
}
