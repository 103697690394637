import { useState, useEffect } from "react";
import axios from "axios";
function useClientRequests(clientId = false) {
  const [datas, setDatas] = useState([]);
  const [refetch, setRefecth] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    try {
      async function getAndSetData() {
        let dataReqUrl = clientId ? "client/" + clientId + "/" : "";
        const allDatas = await axios.get(
          "/client-requests/" + dataReqUrl + "all"
        );
        setDatas(allDatas.data.data);
        setLoading(false);
      }

      getAndSetData();
    } catch (error) {}

    return setRefecth(false);
  }, [refetch]);

  const refetchDatas = () => {
    setRefecth(true);
  };

  return { clientRequests: datas, refetchDatas, loading };
}

export default useClientRequests;
