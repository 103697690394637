export const communesBenin = [
  "Abomey",
  "Abomey-Calavi",
  "Adja-Ouèrè",
  "Adjarra",
  "Adjohoun",
  "Agbangnizoun",
  "Aguégués",
  "Allada",
  "Aplahoué",
  "Athiémé",
  "Avrankou",
  "Banikoara",
  "Bantè",
  "Bassila",
  "Bembèrèkè",
  "Bohicon",
  "Bonou",
  "Bopa",
  "Boukombé",
  "Cobly",
  "Comè",
  "Copargo",
  "Cotonou",
  "Covè",
  "Dangbo",
  "Dassa-Zoumè",
  "Djakotomey",
  "Djidja",
  "Djougou",
  "Dogbo",
  "Glazoué",
  "Gogounou",
  "Grand-Popo",
  "Houéyogbé",
  "Ifangni",
  "Kalalé",
  "Kandi",
  "Karimama",
  "Kérou",
  "Kétou",
  "Klouékanmè",
  "Kouandé",
  "Kpomassè",
  "Lalo",
  "Lokossa",
  "Malanville",
  "Matéri",
  "Missérété",
  "N’dali",
  "Natitingou",
  "Nikki",
  "Ouaké",
  "Ouèssè",
  "Ouidah",
  "Ouinhi",
  "Parakou",
  "Pehunco",
  "Pèrèrè",
  "Pobè",
  "Porto-Novo",
  "Sakété",
  "Savalou",
  "Savè",
  "Ségbana",
  "Sèmè-Podji",
  "Sinendé",
  "Sô-Ava",
  "Tanguiéta",
  "Tchaourou",
  "Toffo",
  "Tori-Bossito",
  "Toucountouna",
  "Toviklin",
  "Zagnanado",
  "Za-Kpota",
  "Zè",
  "Zogbodomey",
];

export const RoleTab = [
  {
    label: "Apporteur d'affaire",
    value: "businessFinder",
  },
  {
    label: "Investigateur immobilier",
    value: "investigator",
  },
  {
    label: "Devenir agent agrée",
    value: "agent",
  },
];
