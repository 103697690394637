import { Link } from "react-router-dom";

export function Img(props) {
  return (
    <img
      src={require(`../assets/img/${props.img}`)}
      alt={props.alt}
      {...props}
    />
  );
}

export function BorderButton(props) {
  return (
    <Link to={props.route} className="borderButton" {...props}>
      {props.label}
    </Link>
  );
}
export function BackgroundButton({ style, bordered, ...props }) {
  return (
    <Link
      to={props.route}
      className={`greenButton ${bordered ? "bordered" : ""}`}
      {...props}
      style={style ? style : {}}
    >
      {props.label}
    </Link>
  );
}

export function GreenButton(props) {
  return (
    <Link
      to={props.route}
      className={`greenButton ${props.bordered ? "bordered" : ""}`}
      {...props}
    >
      {props.label}
    </Link>
  );
}

export function ColoredButton({ style, ...props }) {
  return (
    <Link
      to={props.route}
      className={`coloredButton ${props.bordered ? "bordered" : ""}`}
      style={style ? style : {}}
      {...props}
    >
      {props.label}
    </Link>
  );
}

export function BlackButton(props) {
  return (
    <button
      className={`blackButton ${props.bordered ? "bordered" : ""}`}
      {...props}
    >
      {props.label}
    </button>
  );
}
export function SubmitBackgroundButton(props) {
  return (
    <button type="submit" className="backgroundButton" {...props}>
      {props.label}
    </button>
  );
}
export function SubmitBorderButton(props) {
  return (
    <button className="borderButton" {...props}>
      {props.label}
    </button>
  );
}
export function TitleRed(props) {
  return <h4 className="titleRed">{props.label}</h4>;
}
export function TitleWhite(props) {
  return <h4 className="titleWhite">{props.label}</h4>;
}
