import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { Img } from "../../../ui-kits/kits";
import withAuth from "../../../hoc/WithAuth";
const ReadReports = () => {
  const { requestId, idNote } = useParams();
  const [loading, setLoading] = useState(true);
  const [allNotes, setAllNotes] = useState([]);
  const getAllReports = async () => {
    try {
      const response = await axios.get(
        "/client-requests/" + requestId + "/note/all"
      );
      setAllNotes(response.data.data.filter((item) => item?._id === idNote));
      setLoading(false);
    } catch (e) {
      toast.error("Une erreur a été rencontrer");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllReports();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: allNotes[0]?.content }}></div>
      )}
    </div>
  );
};

export default withAuth(ReadReports, [
  "agent",
  "investigator",
  "superAdmin",
  "secretary",
]);
