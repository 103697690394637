import React from "react";
import { Link } from "react-router-dom";
import {
  PhEnvelopeSimpleBold,
  PhMapPinLineBold,
  PhPhoneBold,
  Twitter,
  Whatsapp,
  Telegram,
  TiktokFill,
} from "../../assets/icons/Icon";
import { Img } from "../../ui-kits/kits";
import { toast } from "react-toastify";

const Footer = () => {
  const date = new Date();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div>
          <h3>Adresse</h3>
          <div>
            <Img img="logo-footer.png" alt="logo" />
            <p>
              REFI offre à ses clients un ensemble essentiel de services dans le
              domaine de l'investissement immobilier, permettant d'éviter les
              pièges fréquents qui mènent souvent à des litiges interminables.
              Composée d'experts expérimentés, notre équipe travaille en
              synergie pour répondre efficacement à vos attentes.
            </p>
          </div>
        </div>
        <div>
          <h3>Nos services</h3>
          <div>
            <Link
              to="/login"
              onClick={() =>
                toast.warn("Veuillez vous connecter s'il vous plaît")
              }
            >
              Veille immobilière
            </Link>
            <Link
              to="/login"
              onClick={() =>
                toast.warn("Veuillez vous connecter s'il vous plaît")
              }
            >
              Conseils & Orientations
            </Link>
            <Link
              to="/login"
              onClick={() =>
                toast.warn("Veuillez vous connecter s'il vous plaît")
              }
            >
              Investigations immobilières
            </Link>
          </div>
        </div>
        <div>
          <h3>Liens rapides</h3>
          <div>
            {[
              { routes: "/About", label: "A Propos" },
              {
                routes: "/Terms",
                label: "Conditions générales d'utilisation ",
              },
              {
                routes: "/PrivacyPolicy",
                label: "Politique de confidentialité",
              },
              { routes: "/MentionsLegales", label: "Mentions Légales" },
            ].map((item) => (
              <Link to={item.routes} key={"Lien rapide vers " + item.label}>
                {item.label}
              </Link>
            ))}
          </div>
        </div>
        <div>
          <h3>Social Media</h3>
          <div className="social">
            <div>
              <a
                href="https://wa.me/message/NB6QVD2VQXJVP1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp />
              </a>
              <a
                href="https://t.me/refi229"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Telegram />{" "}
              </a>
              <a
                href="https://twitter.com/refi229 "
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />{" "}
              </a>
              <a
                href="https://www.tiktok.com/@arefi229"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TiktokFill />{" "}
              </a>
            </div>
            <div>
              <a href="tel:+229 51178080">
                {" "}
                <PhPhoneBold /> +229 51178080
              </a>
              <a href="mailto:info@refi.com">
                <PhEnvelopeSimpleBold /> contact@refi.bj
              </a>
              <p>
                <PhMapPinLineBold /> Lot 393 RFU (Ex Carré 877) AÏDJÈDO 6è
                Arrondissement - Cotonou, République du Bénin.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">copyright, refi {date.getFullYear()}</div>
    </footer>
  );
};

export default Footer;
