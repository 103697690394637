import React from "react";
import { EditPieceIcon, MdiDownload } from "../assets/icons/Icon";
import { ModalChangeFile } from "./Modal";
import Carousel from "react-multi-carousel";
import { useModal } from "../context/Modal";
import { useAuth } from "../context/Auth";

const ModalDocViews = ({ demande }) => {
  const { user } = useAuth();
  const authed = user;
  const userRole = authed.role;
  const { showModal } = useModal();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div
      style={{
        height: "max-content",
      }}
    >
      {Array.isArray(demande.docs) && demande.docs.length > 0 && (
        <section>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            infinite={true}
            autoPlaySpeed={1000}
            transitionDuration={500}
            containerClass="document-container"
            removeArrowOnDeviceType={["mobile"]}
            dotListClass="custom-dot-list-style"
          >
            {demande.docs.map((item, index) => (
              <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <h3>
                    {(() => {
                      if (item.name === "birth_certificate") {
                        return "Acte de naissance";
                      } else if (item.name === "ifu") {
                        return "Ifu";
                      } else if (item.name === "criminal_records") {
                        return "Casier Judiciare";
                      } else if (
                        item.name === "identity_card" ||
                        item.name === "identity_card_single"
                      ) {
                        return "Carte D'identité";
                      } else if (item.name === "land_documents") {
                        return "Documents de la parcelle";
                      } else if (item.name === "other_documents") {
                        return "Autre documents";
                      } else if (item.name === "docs_land_infos") {
                        return "Documents fournir par le vendeur";
                      } else if (item.name === "topographic_survey") {
                        return "Relevé Topographique";
                      } else if (item.name === "seller_identity_card") {
                        return "Carte d'identité du vendeur";
                      }
                    })()}
                  </h3>
                  <div>
                    {(userRole === "client" || userRole === "businessFinder") &&
                      ["paiementPending"].includes(demande.status) && (
                        <span
                          style={{
                            color: "black",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            background: "lightblue",
                            padding: "5px",
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            showModal(
                              <ModalChangeFile
                                tabDemande={demande}
                                index={index}
                              />
                            )
                          }
                        >
                          Modifier <EditPieceIcon />
                        </span>
                      )}

                    {!["client", "investigator", "businessFinder"].includes(
                      userRole
                    ) && (
                      <a
                        href={item.docPath}
                        download=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          background: "lightblue",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        Télecharger <MdiDownload />
                      </a>
                    )}
                  </div>
                </div>

                <embed
                  src={item.docPath}
                  width="100%"
                  height="800"
                  style={{ padding: "10px" }}
                ></embed>
              </>
            ))}
          </Carousel>
        </section>
      )}
    </div>
  );
};

export default ModalDocViews;
