import React from "react";
import { Formik, Form } from "formik";
import { useState } from "react";
import { FormikInputTextOrMail } from "../ui-kits/form";
import {
  PhArrowLeftBold,
  PhEnvelopeSimpleBold,
  Spinner,
} from "../assets/icons/Icon";
import axios from "axios";

import { langSelector } from "../helpers/langs";
import { toast } from "react-toastify";
import { useAuth } from "../context/Auth";
import withoutAuth from "../hoc/WithoutAuth";
import { Img } from "../ui-kits/kits";
import { useNavigate } from "react-router";
import { useModal } from "../context/Modal";

const Login = () => {
  const l = langSelector("authPage").login;

  const [loginData, setLoginData] = useState({ email: "", accessCode: "" });
  const [loginStepOne, setLSO] = useState(false);
  const [logError, setLogError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const { showModal } = useModal();

  function handleSubmitOne(values) {
    setLoading(true);
    axios
      .post("/auth/login/one", values)
      .then(() => {
        setLSO(true);
        setLoginData((prev) => {
          return { ...prev, email: values.email };
        });
        toast.success("un code d'acces a été envoyer sur votre mail", {
          autoClose: 10000,
        });
        setLoading(false);
        setLogError("");
      })
      .catch((err) => {
        setLoading(false);
        let userUnknown = "";
        let suspendedUser = false;
        if (err.response && err.response.data) {
          userUnknown = err?.response?.data?.message?.unknowUser;
          suspendedUser = err?.response?.data?.message?.suspendedUser;
        }
        if (userUnknown) {
          setLogError(`Utilisateur inconnu (Veuillez vous inscrire) `);
        } else if (userUnknown === "") {
          setLogError("Une erreur a été rencontrée");
        } else if (suspendedUser) {
          showModal(
            <p>
              Cher(e) utilisateur(trice).
              <br />
              Nous regrettons de vous informer que votre compte a été suspendu
              temporairement en raison de violations des conditions
              d'utilisation de notre site.
            </p>
          );
        } else {
          setLogError(
            "Une erreur est survenue,veuillez verifier votre connexion"
          );
        }
      })
      .finally();
  }
  function handleSubmitTwo(values) {
    setLoading(true);
    axios
      .post("/auth/login/two", values)
      .then((res) => {
        const { token } = res.data.data;
        login(token);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        const { accessTimePassed, badAccessCode, userNotFound } =
          err.response.data.message;
        if (accessTimePassed) {
          setLogError(`Votre code d'access a expirer`);
        } else if (badAccessCode) {
          setLogError(`Mauvais code d'access`);
        } else if (userNotFound) {
          setLogError(`Utilisateur inconnu (Veuillez vous inscrire)`);
        }
      })
      .finally();
  }
  const navigate = useNavigate();
  return (
    <div>
      <div className="login">
        <div className="login-container">
          <label className="back" onClick={() => navigate("/")}>
            <PhArrowLeftBold />
            Retour
          </label>
          <div className="top">
            <Img img="logoLogin.png" alt="logo" />
            <h2>{l.formTitle}</h2>
          </div>
          {loginStepOne === false && (
            <Formik initialValues={loginData} onSubmit={handleSubmitOne}>
              {(values) => (
                <Form>
                  <div style={{ textAlign: "center" }}>
                    <b style={{ color: "#ff0000", fontSize: "14px" }}>
                      {logError}
                    </b>
                  </div>
                  {FormikInputTextOrMail({
                    type: "email",
                    label: l.email.label,
                    placeholder: l.email.placeholder,
                    name: "email",
                    icon: <PhEnvelopeSimpleBold />,
                  })}
                  <button type="submit">
                    {loading ? <Spinner /> : l.btnAccessCode}
                  </button>
                </Form>
              )}
            </Formik>
          )}
          {loginStepOne === true && (
            <Formik initialValues={loginData} onSubmit={handleSubmitTwo}>
              {(values) => (
                <Form>
                  <div style={{ textAlign: "center" }}>
                    <b
                      style={{
                        color: "#ff0000",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      {logError}
                    </b>
                  </div>
                  {FormikInputTextOrMail({
                    type: "text",
                    label: l.accessCode.label,
                    placeholder: l.accessCode.placeholder,
                    name: "accessCode",
                    icon: <PhEnvelopeSimpleBold />,
                  })}
                  <button type="submit">
                    {loading ? <Spinner /> : l.btnConfirmAccessCode}
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default withoutAuth(Login, false);
