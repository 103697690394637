import React, { useEffect, useState } from "react";
import { TableofData } from "../../ui-kits/table";
import { toast } from "react-toastify";
import axios from "axios";
import { Img } from "../../ui-kits/kits";
import withAuth from "../../hoc/WithAuth";

const commissionHead = [
  { name: "finderName", label: "Nom de l'apporteur" },
  { name: "number", label: "Numéro de l'apporteur" },
  { name: "whatsapp", label: "Whatsapp de l'apporteur" },
  { name: "cost", label: "Commission" },
  { name: "status", label: "Statut" },
  { label: "Date de la demande de paiement", name: "createdAt" },
];

const AllCommission = () => {
  const [commissionData, setCommissionData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllCommissionData = async () => {
    try {
      const response = await axios.get(`/commission-request/all`);
      setCommissionData(
        response?.data?.data?.data?.map((item) => ({
          ...item,
          status: item.commissionPaid ? "approved" : "pending",
          finderName: item.businessFinderId.fullName,
          number: item.businessFinderId.phone,
          whatsapp: item.businessFinderId.whatsapp,
        }))
      );
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllCommissionData();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading === true ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Toutes les commissions sur Refi</h2>
          {Array.isArray(commissionData) && commissionData.length > 0 ? (
            <>
              <TableofData
                tableHead={commissionHead}
                tableBody={commissionData}
                tableType={"businness"}
                hideTop
                workerFilter={true}
                filterOption={[
                  {
                    value: "cost",
                    label: "Montant de la commission",
                  },
                  {
                    value: "clientRequestId",
                    label: "ID du dossier",
                  },
                ]}
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "50px",
                fontSize: "20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <Img img="nothingFound.png" />
              Aucune commission pour le moment
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(AllCommission, ["superAdmin"]);
