/* eslint-disable react/prop-types */

const Steppers = ({ currentStep, steps, ...props }) => {
  return (
    <div className="simpleSteppers" {...props}>
      <span className="length"></span>
      <span
        className="currentLength"
        style={{ width: `${(currentStep * 100) / steps.length}%` }}
      ></span>
      {steps.map((step, index) => (
        <div className={`step`} key={index}>
          <span
            className={`${
              currentStep === index + 1
                ? "activeStep"
                : currentStep > index + 1
                ? "completeStep"
                : ""
            }`}
          >
            {index + 1}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Steppers;
