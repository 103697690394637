import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Img } from "../../../ui-kits/kits";
import axios from "axios";
import { TableofData } from "../../../ui-kits/table";
import {
  ApprouvedButton,
  SeeDocumentButton,
} from "../../../ui-kits/button/button";
import { ConfirmModal, OperationStatusModal } from "../../../ui-kits/Modal";
import withAuth from "../../../hoc/WithAuth";
import { useModal } from "../../../context/Modal";

const InvestigatorMissionRequests = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const { showModal } = useModal();

  const getRequests = async () => {
    try {
      const response = await axios.get(
        `/client-requests/${id}/investigator/all`
      );
      setRequests(response.data.data);
      setLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
      setLoading(false);
    }
  };
  const handleApprouveRequest = async (requestId) => {
    try {
      await axios.put(
        `/client-requests/investigator/participation-request/approve`,
        { requestId: requestId }
      );
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Cet investigateur a été accepté pour faire l'enquête`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
    }
  };

  const handle = (type, item) => {
    if (type === "update") {
      showModal(
        <ConfirmModal
          text={`Etes vous sur de vouloir accepter cet investigateur?`}
          handle={() => {
            handleApprouveRequest(item._id);
          }}
        />
      );
    } else {
      alert("wait");
    }
  };

  const invetigatorHead = [
    { name: "fullName", label: "Nom & Prénoms" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Telephone" },
    { name: "whatsapp", label: "Numéro Whatsapp" },
    { name: "municipalitie", label: "Commune de l'investigateur" },
    { name: "status", label: "Statut" },
    { name: "createdAt", label: "Date de la demande" },
    { name: "option", label: "Action" },
  ];

  const action = [
    {
      action: "update",
      icon: <ApprouvedButton content="Approuver" />,
    },
  ];
  useEffect(() => {
    getRequests();
  }, []);
  return (
    <div className="add-request-components dashboard-base-param">
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "100%",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <h2>Liste des Investigateurs souhaitant faire l'investigation</h2>
          {requests && requests.length > 0 ? (
            <TableofData
              tableHead={invetigatorHead}
              tableBody={requests.map((item) => {
                return {
                  ...item.investigatorId,
                  status: item.status,
                  _id: item._id,
                  clientReqId: item.clientRequestId,
                };
              })}
              handle={handle}
              tableType={"businness"}
              option={action}
              hideTop
              approveFilter={true}
              filterOption={[
                {
                  label: "Nom & prenoms",
                  value: "fullName",
                },
                {
                  label: "Email",
                  value: "email",
                },
              ]}
            />
          ) : (
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              Pas de demande effectuer pour le moment
            </p>
          )}
        </>
      )}
    </div>
  );
};

export default withAuth(InvestigatorMissionRequests, ["agent"]);
