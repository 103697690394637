import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { TableofData } from "../../../ui-kits/table";
import axios from "axios";
import { Img } from "../../../ui-kits/kits";
import useClientRequests from "../../../hooks/useClientRequests";
import { toast } from "react-toastify";
import { Popover } from "@material-ui/core";
import {
  PhDotsThreeOutlineFill,
  PopoverIcon,
} from "../../../assets/icons/Icon";
import { useNavigate } from "react-router-dom";
import {
  ConfirmModal,
  ModalSetFolderStatut,
  OperationStatusModal,
} from "../../../ui-kits/Modal";
import { Link } from "react-router-dom";
import withAuth from "../../../hoc/WithAuth";
import { useModal } from "../../../context/Modal";
import { useAuth } from "../../../context/Auth";

const ClientTableHead = [
  {
    name: "_id",
    label: "ID du dossier",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  {
    name: "cost",
    label: "Prix de la demande",
  },
  {
    name: "municipalitie",
    label: "Commune du client",
  },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "requestReason",
    label: "Motif de la demande",
  },
  {
    name: "status",
    label: "Statut",
  },
  {
    name: "createdAt",
    label: "Date de création ",
  },
];
const agentHead = [
  {
    name: "_id",
    label: "ID du dossier",
  },
  {
    name: "clientFullname",
    label: "Nom & Prénoms du client",
  },
  {
    name: "cost",
    label: "Prix",
  },
  {
    name: "municipalitie",
    label: "Commune du client",
  },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "requestReason",
    label: "Motif de la demande",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  {
    name: "status",
    label: "Statut",
  },
  {
    name: "createdAt",
    label: "Date de création",
  },
  { name: "option", label: "Option" },
];
const investigatorHead = [
  {
    name: "_id",
    label: "ID du dossier",
  },
  {
    name: "clientFullname",
    label: "Nom & Prénoms du client",
  },
  {
    name: "cost",
    label: "Prix",
  },
  {
    name: "municipalitie",
    label: "Commune du client",
  },
  {
    name: "investigationMunicipalitie",
    label: "Commune de l'investigation",
  },
  {
    name: "type",
    label: "Type de demande",
  },
  {
    name: "status",
    label: "Statut",
  },
  {
    name: "createdAt",
    label: "Date de création",
  },
  { name: "option", label: "Option" },
];
const action = [
  {
    action: "dot",
    icon: <PhDotsThreeOutlineFill />,
  },
];

const Details = () => {
  const { detail, id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const { showModal } = useModal();

  const [detailsLoading, setDetailsLoading] = useState(true);
  const [anchorElement, setAnchorElement] = useState(null);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [selectedFolder, setSF] = useState(null);

  const [whosDetails, setWhosDetails] = useState(null);
  const [listeDatas, setListesDatas] = useState([]);

  const handleManageDossier = (type, item, e) => {
    if (type === "dot") {
      setSF(item);
      setAnchorElement(e?.currentTarget);
      setVisiblePopover(true);
    }
  };

  const getUsers = async () => {
    setDetailsLoading(true);
    const users = await axios.get("/user/all");
    let filteredUsers = [];
    filteredUsers = users.data.data.filter((user) => user._id === id);
    setWhosDetails(filteredUsers[0]);
  };
  const { clientRequests, loading } = useClientRequests(id);

  const getAgentsDossier = async () => {
    try {
      const response = await axios.get("/client-requests/for-agent/" + id);
      setListesDatas(response.data.data);
      setDetailsLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
      setDetailsLoading(false);
    }
  };
  const getInvestigatorDossier = async () => {
    try {
      const response = await axios.get(
        "/client-requests/for-investigator/" + id
      );
      setListesDatas(response.data.data);
      setDetailsLoading(false);
    } catch (error) {
      toast.error("Une erreur a été rencontrer");
      setDetailsLoading(false);
    }
  };
  const ClosePopover = () => {
    setAnchorElement(null);
    setVisiblePopover(false);
  };
  const handleFolderStatus = () => {
    ClosePopover();
    showModal(<ModalSetFolderStatut dossier={selectedFolder} />);
  };

  const handleBlockAccount = async (id) => {
    try {
      await axios.put(`admin/suspende/${id}`);
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Cet compte a été suspendu`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
    }
  };
  const handleUnBlockAccount = async (id) => {
    try {
      await axios.put(`admin/desuspende/${id}`);
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Cet compte a été débloqué`,
          }}
        />
      );
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
    }
  };

  const handleDeleteAccount = async (id) => {
    try {
      await axios.put(`admin/delete/${id}`);
      showModal(
        <OperationStatusModal
          props={{
            type: "success",
            message: `Ce compte a été supprimé.`,
          }}
        />
      );
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error) {
      toast.error("Une erreur a été rencontrée");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    if (whosDetails) {
      if (detail === "agents agrées") {
        getAgentsDossier();
      } else if (
        detail === "clients" ||
        detail === "apporteurs" ||
        detail === "staffs"
      ) {
        if (!loading) {
          setDetailsLoading(false);
        }
      } else {
        getInvestigatorDossier();
      }
    }
  }, [whosDetails, clientRequests]);
  return (
    <>
      {detailsLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      ) : (
        <>
          <Popover
            id="popover"
            open={visiblePopover}
            anchorEl={anchorElement}
            onClose={ClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <ul>
              <li
                onClick={() => {
                  navigate(
                    `/Reports/${selectedFolder && selectedFolder._id}/${
                      whosDetails._id
                    }`
                  );
                }}
              >
                <PopoverIcon /> Voir les comptes rendu sur ce dossier
              </li>
              <li onClick={() => handleFolderStatus()}>
                <PopoverIcon /> Changez le statut
              </li>
            </ul>
          </Popover>
          <div className="add-request-components dashboard-base-param">
            <h2>Détail {detail.slice(0, -1)}</h2>
            <div className="detail-field">
              <table>
                <td>
                  <tr>Nom & Prenoms</tr>
                  <tr>Email</tr>
                  <tr>Téléphone</tr>
                  <tr>Whatsapp</tr>
                  {detail === "apporteurs" ? <tr>Commission</tr> : ""}

                  <tr>
                    <button
                      onClick={() =>
                        showModal(
                          <ConfirmModal
                            text={
                              whosDetails.suspended
                                ? "Voulez vous debloquer le compte ?"
                                : `Etes vous sur de vouloir bloquer ce compte?`
                            }
                            handle={() => {
                              whosDetails.suspended
                                ? handleUnBlockAccount(id)
                                : handleBlockAccount(id);
                            }}
                          />
                        )
                      }
                      style={{
                        background: "#94a8db",
                        borderRadius: "5px",
                      }}
                    >
                      {whosDetails.suspended
                        ? "Débloquer le compte"
                        : "Suspendre le compte"}
                    </button>
                  </tr>
                  {user.role === "superAdmin" && (
                    <tr>
                      <button
                        onClick={() =>
                          showModal(
                            <ConfirmModal
                              text={`Etes vous sur de vouloir supprimer ce compte?`}
                              handle={() => {
                                handleDeleteAccount(id);
                              }}
                            />
                          )
                        }
                        style={{
                          background: "red",
                          color: "white",
                          borderRadius: "5px",
                        }}
                      >
                        Effacer le compte
                      </button>
                    </tr>
                  )}
                </td>
                <td>
                  <tr>{whosDetails.fullName}</tr>
                  <tr>{whosDetails.email}</tr>
                  <tr>{whosDetails.phone}</tr>
                  <tr>{whosDetails.whatsapp}</tr>
                  {detail === "apporteurs" ? (
                    <tr>
                      <Link to={`/CommissionPaiementAsked/${whosDetails?._id}`}>
                        Voir les commissions
                      </Link>
                    </tr>
                  ) : (
                    ""
                  )}
                </td>
              </table>
            </div>
            {(() => {
              if (detail === "clients") {
                return (
                  <>
                    <h2>Les demandes du client</h2>
                    {clientRequests.length > 0 ? (
                      <TableofData
                        tableHead={ClientTableHead}
                        tableBody={clientRequests}
                        filterOption={[
                          {
                            label: "ID du dossier",
                            value: "_id",
                          },
                          {
                            label: "Type de demande",
                            value: "type",
                          },
                          {
                            label: "Commune du client",
                            value: "municipalitie",
                          },
                        ]}
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <Img img="nothingFound.png" />
                        Aucune Demande
                      </div>
                    )}
                  </>
                );
              } else if (detail === "agents agrées") {
                return (
                  <>
                    <h2>Les dossiers à la charge de l'agent</h2>

                    {listeDatas.length > 0 ? (
                      <TableofData
                        tableHead={agentHead}
                        tableBody={listeDatas}
                        handle={handleManageDossier}
                        option={action}
                        filterOption={[
                          {
                            label: "ID du dossier",
                            value: "_id",
                          },
                          {
                            label: "Type de demande",
                            value: "type",
                          },
                          {
                            label: "Nom & Prénoms",
                            value: "clientFullname",
                          },
                          {
                            label: "Commune du client",
                            value: "municipalitie",
                          },
                        ]}
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "50px",
                          fontSize: "20px",
                        }}
                      >
                        Aucun dossier attribuer
                      </div>
                    )}
                  </>
                );
              } else if (detail === "apporteurs") {
                return (
                  <>
                    <h2>Les demandes inscrites par l'apporteur</h2>
                    {clientRequests.length > 0 ? (
                      <TableofData
                        tableHead={ClientTableHead}
                        tableBody={clientRequests}
                        filterOption={[
                          {
                            label: "ID du dossier",
                            value: "_id",
                          },
                          {
                            label: "Type de demande",
                            value: "type",
                          },
                          {
                            label: "Commune du client",
                            value: "municipalitie",
                          },
                        ]}
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "50px",
                          fontSize: "20px",
                        }}
                      >
                        <Img img="nothingFound.png" />
                        Aucune Demande soumise
                      </div>
                    )}
                  </>
                );
              } else if (detail === "staffs") {
                return "";
              } else {
                return (
                  <>
                    <h2>Les dossiers à la charge de l'investigateur</h2>

                    {listeDatas.length > 0 ? (
                      <TableofData
                        tableHead={investigatorHead}
                        tableBody={listeDatas.map(
                          (item) => item.clientRequestId
                        )}
                        handle={handleManageDossier}
                        option={action}
                        filterOption={[
                          {
                            label: "ID du dossier",
                            value: "_id",
                          },
                          {
                            label: "Type de demande",
                            value: "type",
                          },
                          {
                            label: "Nom & Prénoms",
                            value: "clientFullname",
                          },
                          {
                            label: "Commune du client",
                            value: "municipalitie",
                          },
                        ]}
                      />
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "50px",
                          fontSize: "20px",
                        }}
                      >
                        Aucun dossier attribuer
                      </div>
                    )}
                  </>
                );
              }
            })()}
          </div>
        </>
      )}
    </>
  );
};

export default withAuth(Details, ["superAdmin", "secretary"]);
