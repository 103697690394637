import * as Yup from "yup";
import { langSelector } from "./langs";

const l = langSelector("authPage").signUp.yupValidation;

export const signupValidationSchema = Yup.object({
  email: Yup.string().email(l.email.valid).required(l.required),
  municipalitie: Yup.string().required(l.required),
  phone: Yup.number().required("Veuillez remplir ce champ"),
  whatsapp: Yup.number().required("Veuillez remplir ce champ"),
  fullName: Yup.string().required(l.required),
  sponsorToken: Yup.string().required(l.required),
});

export const changeMailValidation = Yup.object({
  email: Yup.string().email(l.email.valid).required(l.required),
});
export const codeMailValidation = Yup.object({
  verificationCode: Yup.string().required(l.required),
});
export const contactValidation = Yup.object({
  contact: Yup.string().required(l.required),
});
export const fileValidation = Yup.object({
  newFile: Yup.mixed()
    .test(
      "fileType",
      "Seuls les fichiers PDF, PNG, JPG, JPEG et HEIC sont acceptés",
      (value) => {
        if (value) {
          const acceptedFileTypes = [
            "application/pdf",
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/heic",
          ];
          return acceptedFileTypes.includes(value.type);
        }
        return true;
      }
    )
    .required("Veuillez sélectionner un fichier"),
});
export const agentValidation = Yup.object({
  agentId: Yup.string().required(l.required),
});
export const priceValidation = Yup.object({
  price: Yup.number()
    .min(1, "Le prix doit etre supérieur à zéro")
    .required(l.required),
});
export const statusValidation = Yup.object({
  status: Yup.string().required(l.required),
  statusReasons: Yup.string().required(l.required),
});
export const partnersValidation = Yup.object({
  partner_name: Yup.string().required(l.required),
  logo: Yup.mixed()
    .test(
      "fileType",
      "Seuls les fichiers PNG, JPG, JPEG et HEIC sont acceptés",
      (value) => {
        if (value) {
          const acceptedFileTypes = [
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/heic",
          ];
          return acceptedFileTypes.includes(value.type);
        }
        return true;
      }
    )
    .required("Veuillez sélectionner un logo"),
  partner_link: Yup.string()
    .url("Veuillez saisir un lien URL valide")
    .required("Ce champ est requis"),
});

export const refiNewsValidation = Yup.object({
  title: Yup.string().required(l.required),
  picture: Yup.mixed()
    .test(
      "fileType",
      "Seuls les fichiers PNG, JPG, JPEG et HEIC sont acceptés",
      (value) => {
        if (value) {
          const acceptedFileTypes = [
            "image/png",
            "image/jpeg",
            "image/jpg",
            "image/heic",
          ];
          return acceptedFileTypes.includes(value.type);
        }
        return true;
      }
    )
    .required("Veuillez sélectionner un logo"),
  link: Yup.string()
    .url("Veuillez saisir un lien URL valide")
    .required("Ce champ est requis"),
});
export const testimonyValidation = Yup.object({
  username: Yup.string().required(l.required),
  content: Yup.string().required(l.required),
  // img: Yup.mixed()
  //   .test(
  //     "fileType",
  //     "Seuls les fichiers PNG, JPG, JPEG et HEIC sont acceptés",
  //     (value) => {
  //       if (value) {
  //         const acceptedFileTypes = [
  //           "image/png",
  //           "image/jpeg",
  //           "image/jpg",
  //           "image/heic",
  //         ];
  //         return acceptedFileTypes.includes(value.type);
  //       }
  //       return true;
  //     }
  //   )
  //   .required("Veuillez sélectionner un logo"),
});
