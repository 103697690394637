import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import mobileMenuReducer from "./reducers/mobileMenuReducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

let appStore = createStore(
  combineReducers({
    mobileMenu: mobileMenuReducer,
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default appStore;
