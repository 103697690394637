import React from "react";
import { DashNav } from "../routesComponents/global/navs";
import { useAuth } from "../context/Auth";
import { DashSidebar } from "../routesComponents/global/sidebars";
import { useDispatch, useSelector } from "react-redux";
import mobileMenuSelector from "../store/selectors/mobileMenuSelector";
import { closeMenu } from "../store/actions/mobileMenuActions";

const DashBoardLayout = ({ children }) => {
  const { user } = useAuth();
  const mobileMenu = useSelector(mobileMenuSelector);
  const dispatch = useDispatch();
  const handleCloseMenu = (e) => {
    if (e.target.className.includes("left")) {
      dispatch(closeMenu());
    }
  };

  return (
    <>
      <div className="dashboardLayout">
        <div
          className={`left ${mobileMenu.open ? "showNav" : ""}`}
          onClick={(e) => handleCloseMenu(e)}
          close={true}
        >
          <div className="dl-l-nav-container">
            <DashSidebar
              props={{
                userRole: user.role ? user.role : "businessFinder",
                id: user._id,
              }}
            />
          </div>
        </div>
        <div className="content">
          <DashNav />
          {children}
        </div>
      </div>
    </>
  );
};

export default DashBoardLayout;
