import React from "react";
import withoutAuth from "../hoc/WithoutAuth";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <div className="afterAboutBanner" style={{ marginBottom: "40px" }}></div>

    <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>

    <section>
      <h2>1. CONSENTEMENT POUR LE TRAITEMENT ET LE TRANSFERT</h2>
      <p>
        Chers utilisateurs de <a href="www.refi.bj ">www.refi.bj </a>, veuillez
        lire attentivement cette Politique de protection des données
        personnelles qui nous engage. En fournissant vos Données Personnelles
        sur le site Web et par le biais des adresses de contact, vous déclarez
        que vous avez pris connaissance de la présente politique et que vous y
        consentez expressément, ainsi qu’au traitement et au transfert de vos
        données.
      </p>
    </section>

    <section>
      <h2>2. RESPONSABLE DU TRAITEMENT ET ENGAGEMENTS</h2>
      <p>
        Le responsable du traitement de vos Données Personnelles est REAL
        ESTATES FRAUD INVESTIGATOR, connue sous le sigle REFI.
      </p>
      <h3>ENGAGEMENTS DU RESPONSABLE DU TRAITEMENT</h3>
      <ul>
        <li>
          <h4>Engagement de transparence :</h4>
        </li>
        <li>
          - Nous détaillons tout au long de ce document la manière dont sont
          obtenues, stockées, et traitées les différentes données vous
          concernant.
        </li>
        <li>
          <h4>ENGAGEMENT DE SÉCURISATION :</h4>
        </li>
        <li>
          - Des mesures techniques et opérationnelles de sécurisation répondant
          aux standards du secteur sont appliquées à tous les niveaux de
          traitement de l'information, qu'il s'agisse de la collecte, du
          stockage, ou des sauvegardes.
        </li>
        <li>
          <h4>ENGAGEMENT DE NON-DIFFUSION :</h4>
        </li>
        <li>
          Aucune donnée ne fait l'objet de diffusion automatisée auprès de
          prestataires ou de tiers.
        </li>
      </ul>
      <h3>ENGAGEMENT DE RESPECT DES DROITS DES PERSONNES CONCERNÉES :</h3>
      <ul>
        <li>
          Les droits permettant l'accès en consultation, rectification et
          suppression de certaines de vos données personnelles sont assurés.
        </li>
      </ul>

      <h3>ENGAGEMENT DE RESPECT DES LÉGISLATIONS :</h3>
      <ul>
        <li>
          - Le système d’information est en conformité avec les législations en
          matière de protection des données personnelles.
        </li>
        <li>
          - Nous avons mis en place des mesures de sécurité technique et
          organisationnelle appropriées pour éviter la destruction, la perte, la
          falsification, la modification, l’accès non autorisé ou la
          notification erronée à des tiers des Données Personnelles collectées
          ainsi que tout autre traitement non autorisé de ces données.
        </li>
        <li>
          - Nous garantissons un niveau de protection suffisant en rendant les
          obligations applicables aux collaborateurs.
        </li>
        <li>
          - Vous êtes seul responsable de l’utilisation faite à partir du site
          Web de votre ordinateur, de votre adresse IP et de vos données
          d’identification, ainsi que de leur confidentialité. Vous devez à tout
          moment respecter les règles de sécurité.
        </li>
      </ul>
    </section>

    <section>
      <h2>3. FORMALITÉS PRÉALABLES</h2>
      <ul>
        <li>
          {" "}
          - La présente politique de protection des données personnelles est
          fondée sur l’article 415 figurant au cinquième livre du code du
          numérique relatif à la Protection des Données à caractère personnel.
          Elle fait également office de ( autres Conditions Générales
          d’Utilisation.)
        </li>
        <li>
          {" "}
          - Elle mentionne la preuve de l’accomplissement des formalités
          préalables auprès de l’Autorité de Protection des Données Personnelles
          (APDP) et vous aide à comprendre quelles données personnelles nous
          collectons et traitons dans notre Institution, les finalités visées
          ainsi que les dispositions qui ont été prises aux fins de respecter la
          législation en vigueur en République du Bénin en matière de protection
          des données personnelles et de la vie privée.
        </li>
      </ul>
    </section>

    <section>
      <h2>4. LES DONNÉES PERSONNELLES OBJET DU TRAITEMENT</h2>
      <ul>
        <li>
          {" "}
          - Les données traitées sont les données personnelles que vous nous
          fournissez lors de votre inscription, de vos visites sur le site{" "}
          <a href=" www.refi.bj"> www.refi.bj</a> pour demander la prestation de
          l'un des services que nous prestons ou de l’utilisation de nos
          contacts téléphoniques et adresses mails.
        </li>
        <li>
          {" "}
          - Il s’agit de : nom, prénoms, email, adresses et localisation,
          passeport, pièce d'identité, CIP ou NIP, numéro de téléphone, casier
          judiciaire, IFU, documents de bien immobilier objet de la demande d'un
          service auprès de REFI.
        </li>
      </ul>
    </section>

    <section>
      <h2>5. LES OPÉRATIONS DU TRAITEMENT</h2>
      <ul>
        <li>
          {" "}
          - REFI ne collecte des informations personnelles relatives aux
          utilisateurs et les données collectées auprès d’eux que pour le besoin
          exclusif des services proposés par REFI.
        </li>
        <li>
          {" "}
          - Les informations recueillies font l’objet d’un traitement destiné à
          l’atteinte des finalités ci-dessous mentionnées.
        </li>
        <li>
          {" "}
          - LES TRAITEMENTS OPÉRÉS SONT : collecte, copie, consultation,
          exploitation, transfert, conservation, enregistrement, extraction,
          modification, effacement, destruction.
        </li>
      </ul>
    </section>

    <section>
      <h2>6. LA FINALITÉ DES TRAITEMENTS</h2>
      <ul>
        <li>
          {" "}
          - Les informations sont collectées pour les finalités suivantes :
        </li>
        <li>- Renseignement et informations des utilisateurs</li>
        <li>- Information sur nos produits et services</li>
        <li>- Investigations immobilières</li>
        <li>- Conseils & Orientations</li>
        <li>- Veille Immobilière</li>
        <li>- Le recueil et le traitement des plaintes</li>
      </ul>
    </section>

    <section>
      <h2>
        {" "}
        7. DESTINATAIRE DES DONNÉES PERSONNELLES COMMUNICATION ET TRANSFERT
      </h2>
      <ul>
        <li> - Le destinataire des données collectées est REFI.</li>
        <li>
          {" "}
          - REFI ne vendra pas, ne louera pas, ne distribuera pas ou ne rendra
          pas vos Données Personnelles commercialement accessibles à des tiers.
        </li>
        <li>
          - Les Données Personnelles sont conservées et stockées comme suit :
        </li>
      </ul>
    </section>

    <section>
      <h2>8. MODALITÉS DE CONSERVATION</h2>
      <ul>
        <li> - Stockage sous la forme de dossier papier</li>
        <li> - Archives REFI</li>
        <li> - Sous la forme de dossier électronique</li>
        <li> - Sur le OneDrive des Archives REFI</li>
      </ul>
    </section>

    <section>
      <h2> 9. LES DROITS DES PERSONNES CONCERNÉES À PROPOS DES TRAITEMENTS</h2>
      <p>
        La loi vous donne sur vos données personnelles les droits suivants :
      </p>
      <ul>
        <li> - Droit d’accès aux informations collectées</li>
        <li> - Droit d’interrogation</li>
        <li> - Droit de modification</li>
        <li> - Droit de rectification</li>
        <li> - Droit à la portabilité</li>
        <li>- Droit d’opposition au traitement</li>
        <li>- Droit de suppression de données</li>
        <li> - Droit à l’oubli</li>
        <li> - Droit à réparation</li>
        <li>
          {" "}
          - Vous pouvez consulter la définition de ces droits au code du
          numérique ou sur le site web de l’APDP BÉNIN{" "}
          <a
            href="http://www.apdp.bj"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.apdp.bj
          </a>
        </li>
      </ul>
      <p>
        Vous pouvez exercer vos droits, demander des informations ou déposer une
        plainte en appelant ou en écrivant à REAL ESTATES FRAUD INVESTIGATOR :
      </p>
      <ul>
        <li>
          - Tél <a href="tel:+22951178080">(+229) 51178080</a> : appel et
          WhatsApp
        </li>
        <li>
          - E-mail : <a href="mailto:contact@refi.bj">contact@refi.bj</a>
        </li>
      </ul>
    </section>
  </div>
);

export default withoutAuth(PrivacyPolicy);
