import React from "react";
import { useState } from "react";
// import { useLocation } from "react-router";
import ClientRequestAddType from "./type.js";
import ClientRequestAddSteppers from "./stepComponents/index.js";

const ClientRequestAdd = () => {
  const [currentDemandStep, setCurrentDemandStep] = useState(0);

  // const location = useLocation();
  // const { pathname } = location;
  // const [active, setActive] = useState(0);
  const [typeOfAskChoosed, setTypeOfAskChoosed] = useState("");
  const demandeOrder = [
    <ClientRequestAddType
      customProps={{
        typeOfAskChoosed,
        setTypeOfAskChoosed,
        setCurrentDemandStep,
      }}
    />,
    <ClientRequestAddSteppers
      customProps={{ typeOfAskChoosed, setCurrentDemandStep }}
    />,
  ];

  return (
    <div className="add-request-components">
      {/* <div className="ask-header">
        <ul>
          {["Faire une Demande"].map((item, index) => (
            <li
              key={"client request " + index}
              className={active === index ? "active" : ""}
              onClick={() => setActive(index)}>
              {item}
            </li>
          ))}
        </ul>
      </div> */}
      {demandeOrder[currentDemandStep]}
    </div>
  );
};

export default ClientRequestAdd;
