export function Facebook(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
export function Instagram(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
      ></path>
    </svg>
  );
}
export function Twitter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="m245.7 77.7l-30.2 30.1c-6 69.9-65 124.2-135.5 124.2c-14.5 0-26.5-2.3-35.6-6.8c-7.3-3.7-10.3-7.6-11.1-8.8a8 8 0 0 1 3.9-11.9c.2-.1 23.8-9.1 39.1-26.4a108.6 108.6 0 0 1-24.7-24.4c-13.7-18.6-28.2-50.9-19.5-99.1a8.1 8.1 0 0 1 5.5-6.2a8 8 0 0 1 8.1 1.9c.3.4 33.6 33.2 74.3 43.8V88a48.3 48.3 0 0 1 48.6-48a48.2 48.2 0 0 1 41 24H240a8 8 0 0 1 7.4 4.9a8.4 8.4 0 0 1-1.7 8.8Z"
      ></path>
    </svg>
  );
}

export function TiktokFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"></path>
        <path
          fill="currentColor"
          d="M14 2a2 2 0 0 1 2 2a3.004 3.004 0 0 0 2.398 2.94a2 2 0 0 1-.796 3.92A6.962 6.962 0 0 1 16 10.325V16a6 6 0 1 1-7.499-5.81a2 2 0 0 1 .998 3.873A2.002 2.002 0 0 0 10 18a2 2 0 0 0 2-2V4a2 2 0 0 1 2-2Z"
        ></path>
      </g>
    </svg>
  );
}
export function Telegram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10S4.477 0 10 0Zm4.442 6c-.381.007-.966.207-3.779 1.362a485.41 485.41 0 0 0-5.907 2.512c-.48.189-.73.373-.753.553c-.044.346.46.453 1.094.657c.517.166 1.213.36 1.575.368c.328.007.694-.127 1.098-.4c2.76-1.84 4.183-2.769 4.273-2.789c.063-.014.15-.032.21.02c.059.052.053.15.046.177c-.05.211-2.641 2.538-2.79 2.691l-.072.072c-.55.543-1.105.898-.147 1.521c.866.563 1.37.922 2.26 1.5c.57.368 1.017.805 1.605.752c.271-.025.55-.276.693-1.026c.335-1.77.995-5.608 1.147-7.19a1.742 1.742 0 0 0-.017-.393a.42.42 0 0 0-.144-.27c-.121-.098-.309-.118-.392-.117Z"
      ></path>
    </svg>
  );
}

export function Whatsapp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"
      ></path>
    </svg>
  );
}
export function PhLinkedinLogoBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M96 120v56a12 12 0 0 1-24 0v-56a12 12 0 0 1 24 0Zm28 68a12 12 0 0 0 12-12v-28a16 16 0 0 1 32 0v28a12 12 0 0 0 24 0v-28a40 40 0 0 0-58.6-35.4A12 12 0 0 0 112 120v56a12 12 0 0 0 12 12ZM232 44v168a20.1 20.1 0 0 1-20 20H44a20.1 20.1 0 0 1-20-20V44a20.1 20.1 0 0 1 20-20h168a20.1 20.1 0 0 1 20 20Zm-24 4H48v160h160ZM84 96a16 16 0 1 0-16-16a16 16 0 0 0 16 16Z"
      ></path>
    </svg>
  );
}

export function PhArrowDownBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="m208.5 152.5l-72 72a12.1 12.1 0 0 1-17 0l-72-72a12 12 0 0 1 17-17L116 187V40a12 12 0 0 1 24 0v147l51.5-51.5a12 12 0 0 1 17 17Z"
      ></path>
    </svg>
  );
}

export function PhArrowUpBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208.5 120.5a12.1 12.1 0 0 1-17 0L140 69v147a12 12 0 0 1-24 0V69l-51.5 51.5a12 12 0 0 1-17-17l72-72a12 12 0 0 1 17 0l72 72a12 12 0 0 1 0 17Z"
      ></path>
    </svg>
  );
}
export function PhArrowRightBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="m224.5 136.5l-72 72a12.1 12.1 0 0 1-17 0a12 12 0 0 1 0-17L187 140H40a12 12 0 0 1 0-24h147l-51.5-51.5a12 12 0 0 1 17-17l72 72a12 12 0 0 1 0 17Z"
      ></path>
    </svg>
  );
}

export function PhArrowLeftBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M228 128a12 12 0 0 1-12 12H69l51.5 51.5a12 12 0 0 1 0 17a12.1 12.1 0 0 1-17 0l-72-72a12 12 0 0 1 0-17l72-72a12 12 0 0 1 17 17L69 116h147a12 12 0 0 1 12 12Z"
      ></path>
    </svg>
  );
}
export function PhFolderNotchOpenBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M245 112.4a19.8 19.8 0 0 0-16.3-8.4H220V88a20.1 20.1 0 0 0-20-20h-68l-26.7-20a19.9 19.9 0 0 0-12-4H40a20.1 20.1 0 0 0-20 20v144a12.2 12.2 0 0 0 2 6.6a11.9 11.9 0 0 0 10 5.4h176a11.8 11.8 0 0 0 11.3-8.1l28.3-81.3a19.7 19.7 0 0 0-2.6-18.2ZM92 68l26.7 20a19.9 19.9 0 0 0 12 4H196v12h-49.6a20.1 20.1 0 0 0-11.1 3.4L116.4 120H69.3a19.9 19.9 0 0 0-18.4 12.2L44 148.6V68Zm107.5 128H50.1L72 144h45.6a20.1 20.1 0 0 0 11.1-3.4l18.9-12.6h75.5Z"
      ></path>
    </svg>
  );
}
export function PhMoneyBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M232 52H24A20.1 20.1 0 0 0 4 72v112a20.1 20.1 0 0 0 20 20h208a20.1 20.1 0 0 0 20-20V72a20.1 20.1 0 0 0-20-20ZM92.6 180l-58.5-52l58.5-52h70.8l58.5 52l-58.5 52ZM228 101.3L199.6 76H228ZM56.4 76L28 101.3V76ZM28 154.7L56.4 180H28ZM199.6 180l28.4-25.3V180ZM128 88a40 40 0 1 0 40 40a40 40 0 0 0-40-40Zm0 56a16 16 0 1 1 16-16a16 16 0 0 1-16 16Z"
      ></path>
    </svg>
  );
}
export function PhUserBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M235.4 210a124.2 124.2 0 0 0-61.8-53.2a76 76 0 1 0-91.2 0A124.2 124.2 0 0 0 20.6 210a12 12 0 0 0 20.8 12a100 100 0 0 1 173.2 0a12.1 12.1 0 0 0 10.4 6a11.7 11.7 0 0 0 6-1.6a12 12 0 0 0 4.4-16.4ZM76 96a52 52 0 1 1 52 52a52 52 0 0 1-52-52Z"
      ></path>
    </svg>
  );
}
export function PhPlusCircleBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M128 20a108 108 0 1 0 108 108A108.1 108.1 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84a84.1 84.1 0 0 1-84 84Zm52-84a12 12 0 0 1-12 12h-28v28a12 12 0 0 1-24 0v-28H88a12 12 0 0 1 0-24h28V88a12 12 0 0 1 24 0v28h28a12 12 0 0 1 12 12Z"
      ></path>
    </svg>
  );
}

export function PhDotsThreeOutlineVerticalBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M128 96a32 32 0 1 0 32 32a32.1 32.1 0 0 0-32-32Zm0 40a8 8 0 1 1 8-8a8 8 0 0 1-8 8Zm0-56a32 32 0 1 0-32-32a32.1 32.1 0 0 0 32 32Zm0-40a8 8 0 1 1-8 8a8 8 0 0 1 8-8Zm0 136a32 32 0 1 0 32 32a32.1 32.1 0 0 0-32-32Zm0 40a8 8 0 1 1 8-8a8 8 0 0 1-8 8Z"
      ></path>
    </svg>
  );
}
export function PhMapPinLineBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M200.1 220h-39.2c5.1-5 10.7-10.7 16.3-17.1c28.1-32.3 42.9-66.5 42.9-98.9a92 92 0 0 0-184 0c0 50 34.1 91.9 59.2 116H56.1a12 12 0 0 0 0 24h144a12 12 0 1 0 0-24Zm-140-116a68 68 0 0 1 136 0c0 33.3-19.9 63.4-36.7 82.7a247.8 247.8 0 0 1-31.3 30.2a247.8 247.8 0 0 1-31.3-30.2c-16.7-19.3-36.7-49.4-36.7-82.7Zm68 44a44 44 0 1 0-44-44a44 44 0 0 0 44 44Zm0-64a20 20 0 1 1-20 20a20.1 20.1 0 0 1 20-20Z"
      ></path>
    </svg>
  );
}
export function PhEnvelopeSimpleBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M224 44H32a12 12 0 0 0-12 12v136a20.1 20.1 0 0 0 20 20h176a20.1 20.1 0 0 0 20-20V56a12 12 0 0 0-12-12Zm-30.8 24L128 127.7L62.8 68ZM44 188V83.3l75.9 69.5a11.9 11.9 0 0 0 16.2 0L212 83.3V188Z"
      ></path>
    </svg>
  );
}
export function PhPhoneBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M176 228C94.4 228 28 161.6 28 80a60 60 0 0 1 52.4-59.5a19.9 19.9 0 0 1 20.8 12l20.1 46.8a19.9 19.9 0 0 1-1.6 18.9l-15.4 23.4a72.3 72.3 0 0 0 30.3 30.2l23.1-15.4a20.1 20.1 0 0 1 19-1.7l46.8 20.1a19.9 19.9 0 0 1 12 20.8A60 60 0 0 1 176 228ZM80.4 44.8A36 36 0 0 0 52 80a124.1 124.1 0 0 0 124 124a36 36 0 0 0 35.2-28.4l-42-18l-23.2 15.5a20.1 20.1 0 0 1-19.7 1.4a96.5 96.5 0 0 1-44.6-44.4a19.8 19.8 0 0 1 1.3-19.7l15.4-23.6Zm56.3 108.1Z"
      ></path>
    </svg>
  );
}

export function PhMedalBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M220 96a92 92 0 1 0-152 69.7V240a12 12 0 0 0 5.7 10.2a11.9 11.9 0 0 0 11.7.5l42.6-21.3l42.6 21.3a11.6 11.6 0 0 0 5.4 1.3a11.8 11.8 0 0 0 6.3-1.8A12 12 0 0 0 188 240v-74.3A91.9 91.9 0 0 0 220 96ZM60 96a68 68 0 1 1 68 68a68.1 68.1 0 0 1-68-68Zm104 124.6l-30.6-15.3a12.1 12.1 0 0 0-10.8 0L92 220.6v-39.9a92.4 92.4 0 0 0 72 0ZM128 148a52 52 0 1 0-52-52a52 52 0 0 0 52 52Zm0-80a28 28 0 1 1-28 28a28.1 28.1 0 0 1 28-28Z"
      ></path>
    </svg>
  );
}
export function IonManSharp(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <circle cx="256" cy="56" r="56" fill="currentColor"></circle>
      <path
        fill="currentColor"
        d="M336 128H176a32 32 0 0 0-32 32v160h48V192h8v320h52V328h8v184h52V192h8v128h48V160a32 32 0 0 0-32-32Z"
      ></path>
    </svg>
  );
}
export function IonWoman(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <circle cx="255.75" cy="56" r="56" fill="currentColor"></circle>
      <path
        fill="currentColor"
        d="m394.63 277.9l-10.33-34.41v-.11l-22.46-74.86h-.05l-2.51-8.45a44.87 44.87 0 0 0-43-32.08h-120a44.84 44.84 0 0 0-43 32.08l-2.51 8.45h-.06l-22.46 74.86v.11l-10.37 34.41c-3.12 10.39 2.3 21.66 12.57 25.14a20 20 0 0 0 25.6-13.18l25.58-85.25l2.17-7.23a8 8 0 0 1 15.53 2.62a7.78 7.78 0 0 1-.17 1.61L155.43 347.4a16 16 0 0 0 15.32 20.6h29v114.69c0 16.46 10.53 29.31 24 29.31s24-12.85 24-29.31V368h16v114.69c0 16.46 10.53 29.31 24 29.31s24-12.85 24-29.31V368h30a16 16 0 0 0 15.33-20.6l-43.74-145.81a7.52 7.52 0 0 1-.16-1.59a8 8 0 0 1 15.54-2.63l2.17 7.23l25.57 85.25A20 20 0 0 0 382.05 303c10.27-3.44 15.69-14.71 12.58-25.1Z"
      ></path>
    </svg>
  );
}

export function PhXBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208.5 191.5a12 12 0 0 1 0 17a12.1 12.1 0 0 1-17 0L128 145l-63.5 63.5a12.1 12.1 0 0 1-17 0a12 12 0 0 1 0-17L111 128L47.5 64.5a12 12 0 0 1 17-17L128 111l63.5-63.5a12 12 0 0 1 17 17L145 128Z"
      ></path>
    </svg>
  );
}

export function PhCheckCircleBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M180.7 95.7a12 12 0 0 1-.4 17l-58.7 56a12 12 0 0 1-8.3 3.3a12.3 12.3 0 0 1-8.3-3.3l-29.3-28a12 12 0 1 1 16.6-17.4l21 20.1l50.4-48.1a12 12 0 0 1 17 .4ZM236 128A108 108 0 1 1 128 20a108.1 108.1 0 0 1 108 108Zm-24 0a84 84 0 1 0-84 84a84.1 84.1 0 0 0 84-84Z"
      ></path>
    </svg>
  );
}
export function PhWarningOctagonFill(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="m227.3 80.2l-51.5-51.5a16.1 16.1 0 0 0-11.3-4.7h-73a16.1 16.1 0 0 0-11.3 4.7L28.7 80.2A16.1 16.1 0 0 0 24 91.5v72.9a16.1 16.1 0 0 0 4.7 11.4l51.5 51.5a16.1 16.1 0 0 0 11.3 4.7h73a16.1 16.1 0 0 0 11.3-4.7l51.5-51.5a16.1 16.1 0 0 0 4.7-11.4V91.5a16.1 16.1 0 0 0-4.7-11.3ZM120 80a8 8 0 0 1 16 0v56a8 8 0 0 1-16 0Zm8 104a12 12 0 1 1 12-12a12 12 0 0 1-12 12Z"
      ></path>
    </svg>
  );
}
export function PhHouseSimpleBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208 228H48a20.1 20.1 0 0 1-20-20v-92.5a20 20 0 0 1 6.6-14.8L114.5 28a20 20 0 0 1 27 0l80 72.7a20.3 20.3 0 0 1 6.5 14.8V208a20.1 20.1 0 0 1-20 20ZM52 204h152v-86.7l-76-69.1l-76 69.1Z"
      ></path>
    </svg>
  );
}
export function PhLightbulbFilamentFill(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M176 232a8 8 0 0 1-8 8H88a8 8 0 0 1 0-16h80a8 8 0 0 1 8 8Zm40-128a87.7 87.7 0 0 1-33.6 69.2A16.1 16.1 0 0 0 176 186v6a16 16 0 0 1-16 16H96a16 16 0 0 1-16-16v-6a16.2 16.2 0 0 0-6.2-12.7A87.8 87.8 0 0 1 40 104.5c-.3-47.7 38.3-87.4 85.9-88.5a87.9 87.9 0 0 1 90.1 88Zm-50.3 2.3a8.1 8.1 0 0 0-11.4 0L128 132.7l-26.3-26.4a8.1 8.1 0 0 0-11.4 11.4l29.7 29.6V184a8 8 0 0 0 16 0v-36.7l29.7-29.6a8.1 8.1 0 0 0 0-11.4Z"
      ></path>
    </svg>
  );
}

export function PhListChecksBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M228 128a12 12 0 0 1-12 12h-80a12 12 0 0 1 0-24h80a12 12 0 0 1 12 12Zm-92-52h80a12 12 0 0 0 0-24h-80a12 12 0 0 0 0 24Zm80 104h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24ZM83.9 39.2L57.3 63.7l-9.2-8.5a12 12 0 1 0-16.2 17.6l17.3 16a12 12 0 0 0 16.3 0l34.6-32a12 12 0 0 0-16.2-17.6Zm0 64l-26.6 24.5l-9.2-8.5a12 12 0 0 0-16.2 17.6l17.3 16a12 12 0 0 0 16.3 0l34.6-32a12 12 0 1 0-16.2-17.6Zm0 64l-26.6 24.5l-9.2-8.5a12 12 0 0 0-16.2 17.6l17.3 16a12 12 0 0 0 16.3 0l34.6-32a12 12 0 1 0-16.2-17.6Z"
      ></path>
    </svg>
  );
}

export function PhPaperPlaneTiltBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M226.6 29.4a20.2 20.2 0 0 0-19.6-5.1L20.7 76.9a20 20 0 0 0-3.2 37.3l84.4 39.9l39.9 84.4a19.7 19.7 0 0 0 18 11.4h1.7a19.9 19.9 0 0 0 17.6-14.5L231.7 49a20.2 20.2 0 0 0-5.1-19.6Zm-67.7 189.1l-33.6-70.9l39.3-39.2a12 12 0 0 0-17-17l-39.2 39.3l-70.9-33.6l169.1-47.7Z"
      ></path>
    </svg>
  );
}

export function PhEyeBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M251 123.1a149.8 149.8 0 0 0-28.5-39.6C196.6 57.7 164 44 128 44S59.4 57.7 33.5 83.5A149.8 149.8 0 0 0 5 123.1a12.8 12.8 0 0 0 0 9.8a149.8 149.8 0 0 0 28.5 39.6C59.4 198.3 92 212 128 212s68.6-13.7 94.5-39.5a149.8 149.8 0 0 0 28.5-39.6a12.8 12.8 0 0 0 0-9.8Zm-46.1 33C183.5 177.3 157.6 188 128 188s-55.5-10.7-76.9-31.9A131.4 131.4 0 0 1 29.5 128a128.9 128.9 0 0 1 21.6-28.1C72.5 78.7 98.4 68 128 68s55.5 10.7 76.9 31.9a131.4 131.4 0 0 1 21.6 28.1a131.4 131.4 0 0 1-21.6 28.1ZM128 84a44 44 0 1 0 44 44a44 44 0 0 0-44-44Zm0 64a20 20 0 1 1 20-20a20.1 20.1 0 0 1-20 20Z"
      ></path>
    </svg>
  );
}

export function PhEyeClosedBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M234.4 160.8a12 12 0 0 1-10.4 18a11.8 11.8 0 0 1-10.4-6l-16.3-28.2a126 126 0 0 1-29.4 13.5l5.2 29.4a11.9 11.9 0 0 1-9.7 13.9l-2.1.2a12 12 0 0 1-11.8-9.9l-5.1-28.7a123.5 123.5 0 0 1-16.4 1a146.3 146.3 0 0 1-16.5-1l-5.1 28.7a12 12 0 0 1-11.8 9.9l-2.1-.2a11.9 11.9 0 0 1-9.7-13.9l5.2-29.4a125.3 125.3 0 0 1-29.3-13.5L42.3 173a12.1 12.1 0 0 1-10.4 6a11.7 11.7 0 0 1-6-1.6a12 12 0 0 1-4.4-16.4l17.9-31a142.4 142.4 0 0 1-16.7-17.6a12 12 0 1 1 18.6-15.1C57.1 116.8 84.9 140 128 140s70.9-23.2 86.7-42.7a12 12 0 1 1 18.6 15.1a150.3 150.3 0 0 1-16.7 17.7Z"
      ></path>
    </svg>
  );
}

export function PhListBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M228 128a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h176a12 12 0 0 1 12 12ZM40 76h176a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm176 104H40a12 12 0 0 0 0 24h176a12 12 0 0 0 0-24Z"
      ></path>
    </svg>
  );
}

export function PhMagnifyingGlassBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="m232.5 215.5l-40.7-40.7A94.9 94.9 0 0 0 212 116a96 96 0 1 0-96 96a94.9 94.9 0 0 0 58.8-20.2l40.7 40.7a12.1 12.1 0 0 0 17 0a12 12 0 0 0 0-17ZM44 116a72 72 0 1 1 72 72a72.1 72.1 0 0 1-72-72Z"
      ></path>
    </svg>
  );
}

export function PhCaretLeftBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M160 220a12.2 12.2 0 0 1-8.5-3.5l-80-80a12 12 0 0 1 0-17l80-80a12 12 0 0 1 17 17L97 128l71.5 71.5a12 12 0 0 1 0 17a12.2 12.2 0 0 1-8.5 3.5Z"
      ></path>
    </svg>
  );
}

export function PhCaretRightBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M96 220a12.2 12.2 0 0 1-8.5-3.5a12 12 0 0 1 0-17L159 128L87.5 56.5a12 12 0 0 1 17-17l80 80a12 12 0 0 1 0 17l-80 80A12.2 12.2 0 0 1 96 220Z"
      ></path>
    </svg>
  );
}

export function PhCaretUpBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208 172a12.2 12.2 0 0 1-8.5-3.5L128 97l-71.5 71.5a12 12 0 0 1-17-17l80-80a12 12 0 0 1 17 0l80 80a12 12 0 0 1 0 17a12.2 12.2 0 0 1-8.5 3.5Z"
      ></path>
    </svg>
  );
}

export function PhCaretDownBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M128 188a12.2 12.2 0 0 1-8.5-3.5l-80-80a12 12 0 0 1 17-17L128 159l71.5-71.5a12 12 0 0 1 17 17l-80 80a12.2 12.2 0 0 1-8.5 3.5Z"
      ></path>
    </svg>
  );
}

export function PhDoorBold(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M232 212h-20V40a20.1 20.1 0 0 0-20-20H64a20.1 20.1 0 0 0-20 20v172H24a12 12 0 0 0 0 24h208a12 12 0 0 0 0-24ZM68 44h120v168H68Zm104 84a16 16 0 1 1-16-16a16 16 0 0 1 16 16Z"
      ></path>
    </svg>
  );
}

export function IonCameraSharp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <circle cx="256" cy="272" r="64" fill="currentColor"></circle>
      <path
        fill="currentColor"
        d="M456 144h-83c-3 0-6.72-1.94-9.62-5L336.1 96.2C325 80 320 80 302 80h-92c-18 0-24 0-34.07 16.21L148.62 139c-2.22 2.42-5.34 5-8.62 5v-16a8 8 0 0 0-8-8H92a8 8 0 0 0-8 8v16H56a24 24 0 0 0-24 24v240a24 24 0 0 0 24 24h400a24 24 0 0 0 24-24V168a24 24 0 0 0-24-24ZM260.51 367.9a96 96 0 1 1 91.39-91.39a96.11 96.11 0 0 1-91.39 91.39Z"
      ></path>
    </svg>
  );
}

export function IonSettingsSharp(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M256 176a80 80 0 1 0 80 80a80.24 80.24 0 0 0-80-80Zm172.72 80a165.53 165.53 0 0 1-1.64 22.34l48.69 38.12a11.59 11.59 0 0 1 2.63 14.78l-46.06 79.52a11.64 11.64 0 0 1-14.14 4.93l-57.25-23a176.56 176.56 0 0 1-38.82 22.67l-8.56 60.78a11.93 11.93 0 0 1-11.51 9.86h-92.12a12 12 0 0 1-11.51-9.53l-8.56-60.78A169.3 169.3 0 0 1 151.05 393L93.8 416a11.64 11.64 0 0 1-14.14-4.92L33.6 331.57a11.59 11.59 0 0 1 2.63-14.78l48.69-38.12A174.58 174.58 0 0 1 83.28 256a165.53 165.53 0 0 1 1.64-22.34l-48.69-38.12a11.59 11.59 0 0 1-2.63-14.78l46.06-79.52a11.64 11.64 0 0 1 14.14-4.93l57.25 23a176.56 176.56 0 0 1 38.82-22.67l8.56-60.78A11.93 11.93 0 0 1 209.94 26h92.12a12 12 0 0 1 11.51 9.53l8.56 60.78A169.3 169.3 0 0 1 361 119l57.2-23a11.64 11.64 0 0 1 14.14 4.92l46.06 79.52a11.59 11.59 0 0 1-2.63 14.78l-48.69 38.12a174.58 174.58 0 0 1 1.64 22.66Z"
      ></path>
    </svg>
  );
}

export function HealthiconsMoneyBag(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.04 6c-4.517 0-8.633 1.492-11.068 2.711c-.22.11-.425.218-.616.322c-.378.206-.7.398-.956.567l2.77 4.078l1.304.519c5.096 2.571 11.93 2.571 17.027 0l1.48-.768l2.62-3.829a15.503 15.503 0 0 0-1.69-.957C32.489 7.437 28.472 6 24.04 6Zm-6.443 4.616a24.579 24.579 0 0 1-2.901-.728C16.977 8.875 20.377 7.8 24.039 7.8c2.537 0 4.936.516 6.92 1.17c-2.325.327-4.806.882-7.17 1.565c-1.86.538-4.034.48-6.192.081Zm15.96 5.064l-.245.124c-5.607 2.828-13.043 2.828-18.65 0l-.232-.118C6.008 24.927-.422 41.997 24.04 41.997c24.46 0 17.873-17.389 9.517-26.317ZM23 24a2 2 0 0 0 0 4v-4Zm2-2v-1h-2v1a4 4 0 0 0 0 8v4a2 2 0 0 1-1.886-1.333a1 1 0 1 0-1.886.666A4.001 4.001 0 0 0 23 36v1h2v-1a4 4 0 1 0 0-8v-4c.87 0 1.611.555 1.887 1.333a1 1 0 1 0 1.885-.666A4.001 4.001 0 0 0 25 22Zm0 8v4a2 2 0 1 0 0-4Z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function MaterialSymbolsEditSquareOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 23.7q-.825 0-1.413-.587T3 21.7v-14q0-.825.588-1.413T5 5.7h8.925l-2 2H5v14h14v-6.95l2-2v8.95q0 .825-.588 1.413T19 23.7H5Zm7-9Zm4.175-8.425l1.425 1.4l-6.6 6.6V15.7h1.4l6.625-6.625l1.425 1.4l-7.2 7.225H9v-4.25l7.175-7.175Zm4.275 4.2l-4.275-4.2l2.5-2.5q.6-.6 1.438-.6t1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8l-2.475 2.475Z"
      ></path>
    </svg>
  );
}

export function ZondiconsTrash(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        d="m6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z"
      ></path>
    </svg>
  );
}

export function PhDotsThreeOutlineFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 256 256"
      {...props}
    >
      <path
        fill="currentColor"
        d="M156 128a28 28 0 1 1-28-28a28 28 0 0 1 28 28ZM48 100a28 28 0 1 0 28 28a28 28 0 0 0-28-28Zm160 0a28 28 0 1 0 28 28a28 28 0 0 0-28-28Z"
      ></path>
    </svg>
  );
}

export function MdiFolderHome(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 6h-8l-2-2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2m-3 7v4h-2v-3h-2v3h-2v-4H9l5-4l5 4Z"
      ></path>
    </svg>
  );
}

export function Spinner(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".14"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".29"
          transform="rotate(30 12 12)"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".43"
          transform="rotate(60 12 12)"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".57"
          transform="rotate(90 12 12)"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".71"
          transform="rotate(120 12 12)"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".86"
          transform="rotate(150 12 12)"
        ></rect>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          transform="rotate(180 12 12)"
        ></rect>
        <animateTransform
          attributeName="transform"
          calcMode="discrete"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
        ></animateTransform>
      </g>
    </svg>
  );
}

export function EditPieceIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6 22q-.825 0-1.413-.588T4 20V4q0-.825.588-1.413T6 2h7.175q.4 0 .763.15t.637.425l4.85 4.85q.275.275.425.638t.15.762V11.1l-8 7.975V22H6Zm8.5 0q-.2 0-.35-.15T14 21.5v-1.2q0-.2.088-.4t.212-.325l4.85-4.875l2.15 2.1l-4.875 4.9q-.125.15-.325.225t-.4.075h-1.2Zm7.525-5.9L19.9 13.975l.7-.7q.3-.3.725-.3t.7.3l.7.725q.275.3.275.713t-.275.687l-.7.7ZM14 9h4l-5-5v4q0 .425.288.713T14 9Z"
      ></path>
    </svg>
  );
}

export function UploadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M7 18a4.6 4.4 0 0 1 0-9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
        <path d="m9 15l3-3l3 3m-3-3v9"></path>
      </g>
    </svg>
  );
}

export function PopoverIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        d="M256 152c-57.2 0-104 46.8-104 104s46.8 104 104 104 104-46.8 104-104-46.8-104-104-104zm0-104C141.601 48 48 141.601 48 256s93.601 208 208 208 208-93.601 208-208S370.399 48 256 48zm0 374.4c-91.518 0-166.4-74.883-166.4-166.4S164.482 89.6 256 89.6 422.4 164.482 422.4 256 347.518 422.4 256 422.4z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

export function MdiDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7Z"
      ></path>
    </svg>
  );
}

export function IonFilter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M472 168H40a24 24 0 0 1 0-48h432a24 24 0 0 1 0 48Zm-80 112H120a24 24 0 0 1 0-48h272a24 24 0 0 1 0 48Zm-96 112h-80a24 24 0 0 1 0-48h80a24 24 0 0 1 0 48Z"
      ></path>
    </svg>
  );
}

export function IcBaselinePlaylistAddCheckCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM7 7h7v2H7V7zm0 3h7v2H7v-2zm3 5H7v-2h3v2zm4.05 3.36l-2.83-2.83l1.41-1.41l1.41 1.41L17.59 12L19 13.41l-4.95 4.95z"
      ></path>
    </svg>
  );
}

export function IcBaselinePlaylistAddCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM7 7h7v2H7V7zm3 8H7v-2h3v2zm-3-3v-2h7v2H7zm12 3h-2v2h-2v-2h-2v-2h2v-2h2v2h2v2z"
      ></path>
    </svg>
  );
}

export function CashRegister(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M64 0C46.3 0 32 14.3 32 32v64c0 17.7 14.3 32 32 32h80v32H87c-31.6 0-58.5 23.1-63.3 54.4L1.1 364.1c-.7 4.7-1.1 9.5-1.1 14.3V448c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64v-69.6c0-4.8-.4-9.6-1.1-14.4l-22.7-149.6c-4.7-31.3-31.6-54.4-63.2-54.4H208v-32h80c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H64zm32 48h160c8.8 0 16 7.2 16 16s-7.2 16-16 16H96c-8.8 0-16-7.2-16-16s7.2-16 16-16zM64 432c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm48-168a24 24 0 1 1 0-48a24 24 0 1 1 0 48zm120-24a24 24 0 1 1-48 0a24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48a24 24 0 1 1 0 48zm168-104a24 24 0 1 1-48 0a24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48a24 24 0 1 1 0 48zm168-104a24 24 0 1 1-48 0a24 24 0 1 1 48 0zm-72 104a24 24 0 1 1 0-48a24 24 0 1 1 0 48z"
      ></path>
    </svg>
  );
}

export function ArrowLeftRounded(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m13.15 16.15l-3.625-3.625q-.125-.125-.175-.25T9.3 12q0-.15.05-.275t.175-.25L13.15 7.85q.075-.075.163-.112T13.5 7.7q.2 0 .35.138T14 8.2v7.6q0 .225-.15.363t-.35.137q-.05 0-.35-.15Z"
      ></path>
    </svg>
  );
}

export function DocIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
    >
      <g clip-path="url(#clip0_198_10081)">
        <path
          d="M7.3598 48.5H40.6398C42.0536 48.5 43.1998 47.3538 43.1998 45.94V10.1L33.5998 0.5H7.3598C5.94596 0.5 4.7998 1.64615 4.7998 3.06V45.94C4.7998 47.3538 5.94596 48.5 7.3598 48.5Z"
          fill="#7575F0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3994 30.4998C14.3994 29.8371 15.0134 29.2998 15.7709 29.2998H32.228C32.9854 29.2998 33.5994 29.8371 33.5994 30.4998C33.5994 31.1625 32.9854 31.6998 32.228 31.6998H15.7709C15.0134 31.6998 14.3994 31.1625 14.3994 30.4998Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3994 23.3001C14.3994 22.6374 14.8599 22.1001 15.428 22.1001H27.7709C28.3389 22.1001 28.7994 22.6374 28.7994 23.3001C28.7994 23.9628 28.3389 24.5001 27.7709 24.5001H15.428C14.8599 24.5001 14.3994 23.9628 14.3994 23.3001Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.3994 37.7C14.3994 37.0373 14.8292 36.5 15.3594 36.5H23.0394C23.5696 36.5 23.9994 37.0373 23.9994 37.7C23.9994 38.3627 23.5696 38.9 23.0394 38.9H15.3594C14.8292 38.9 14.3994 38.3627 14.3994 37.7Z"
          fill="white"
        />
        <path d="M33.5996 10.1H43.1996L33.5996 0.5V10.1Z" fill="#1414B4" />
      </g>
      <defs>
        <clipPath id="clip0_198_10081">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m12 15.4l-6-6L7.4 8l4.6 4.6L16.6 8L18 9.4l-6 6Z"
      ></path>
    </svg>
  );
}

export function MaterialSymbolsDone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="m9.55 18l-5.7-5.7l1.425-1.425L9.55 15.15l9.175-9.175L20.15 7.4L9.55 18Z"
      ></path>
    </svg>
  );
}

export function IconDocLght(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.172 3.172C3 4.343 3 6.229 3 10V14C3 17.771 3 19.657 4.172 20.828C5.343 22 7.229 22 11 22H13C16.771 22 18.657 22 19.828 20.828C21 19.657 21 17.771 21 14V10C21 6.229 21 4.343 19.828 3.172C18.657 2 16.771 2 13 2H11C7.229 2 5.343 2 4.172 3.172ZM8 9.25C7.80109 9.25 7.61032 9.32902 7.46967 9.46967C7.32902 9.61032 7.25 9.80109 7.25 10C7.25 10.1989 7.32902 10.3897 7.46967 10.5303C7.61032 10.671 7.80109 10.75 8 10.75H16C16.1989 10.75 16.3897 10.671 16.5303 10.5303C16.671 10.3897 16.75 10.1989 16.75 10C16.75 9.80109 16.671 9.61032 16.5303 9.46967C16.3897 9.32902 16.1989 9.25 16 9.25H8ZM8 13.25C7.80109 13.25 7.61032 13.329 7.46967 13.4697C7.32902 13.6103 7.25 13.8011 7.25 14C7.25 14.1989 7.32902 14.3897 7.46967 14.5303C7.61032 14.671 7.80109 14.75 8 14.75H13C13.1989 14.75 13.3897 14.671 13.5303 14.5303C13.671 14.3897 13.75 14.1989 13.75 14C13.75 13.8011 13.671 13.6103 13.5303 13.4697C13.3897 13.329 13.1989 13.25 13 13.25H8Z"
        fill="#919EAB"
      />
    </svg>
  );
}
