import React from "react";

import { useAuth } from "../context/Auth";
import { Navigate } from "react-router-dom";
import DashBoardLayout from "../layouts/DashBoardLayout";
import { Img } from "../ui-kits/kits";

const withAuth = (
  WrappedComponent,
  whosGotAcces = [
    "agent",
    "investigator",
    "superAdmin",
    "secretary",
    "client",
    "businessFinder",
  ]
) => {
  return (props) => {
    const { user, userLoading, logout } = useAuth();

    if (userLoading)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Img img="loader.svg" alt="loader " />
        </div>
      );
    if (!user) return <Navigate to="/login" replace />;
    if (user.suspended) return logout();
    if (!whosGotAcces.includes(user.role)) return <Navigate to="/" replace />;
    return (
      <>
        <DashBoardLayout>
          <WrappedComponent />
        </DashBoardLayout>
      </>
    );
  };
};

export default withAuth;
