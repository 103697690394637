import React from "react";
import { useParams } from "react-router";
import ClientRequestAdd from "../routesComponents/clientRequest/add";
import withAuth from "../hoc/WithAuth";

function ClientRequest() {
  const { action } = useParams();
  return (
    <div>
      {(() => {
        if (action === "add") {
          return <ClientRequestAdd />;
        } else {
          return "Liste";
        }
      })()}
    </div>
  );
}

export default withAuth(ClientRequest, ["client", "businessFinder"]);
