import "./assets/styles/default.css";
import "./assets/styles/app.css";
import "./assets/styles/nav.css";
import "./assets/styles/sidebar.scss";

import "./assets/styles/modal.scss";
import "./assets/styles/steppers.scss";
import "./assets/styles/footer.css";
import "./assets/styles/dashboardLayout.scss";
import "./assets/styles/pages/homeComponents/homeBanner.scss";
import "./assets/styles/pages/homeComponents/homeBrief.css";
import "./assets/styles/pages/homeComponents/homeAgent.css";
import "./assets/styles/pages/homeComponents/homeTestimony.css";
import "./assets/styles/pages/homeComponents/homeBecomeAgent.scss";
import "./assets/styles/pages/homeComponents/homeActivity.scss";
import "./assets/styles/pages/homeComponents/homeInformation.css";
import "./assets/styles/pages/askComponents/askheader.css";
import "./assets/styles/pages/askComponents/askForm.scss";
import "./assets/styles/pages/askComponents/askSteppers.css";
import "./assets/styles/pages/askComponents/typeOfAsk.css";
import "./assets/styles/pages/login.scss";
import "./assets/styles/pages/signUp.css";
import "./assets/styles/pages/about.css";
import "./assets/styles/pages/contact.css";
import "./assets/styles/pages/MentionsLegales.css";
import "./assets/styles/pages/privacyPolicy.css";

import { Route, Routes } from "react-router";

import { useLocation } from "react-router";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Modal from "./ui-kits/Modal";
import { authedRoutes, notAuthRoutes } from "./dataRaws/routes";
import "./assets/styles/pages/DashboardCss/dashboard.css";

import { useEffect, useState } from "react";
import Login from "./pages/Login";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);

      if (navigator.onLine) {
        toast.info("Vous êtes connecté à Internet.", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error(
          "Vous êtes hors ligne. Veuillez vous connecter à Internet.",
          {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
          }
        );
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <>
      <div className="App">
        <Modal />
        <ToastContainer style={{ zIndex: 10000 }} />
        <main>
          <Routes>
            {authedRoutes.map((item) => (
              <Route
                key={"Route auth" + item.path}
                path={item.path}
                element={item.element}
              />
            ))}
            {notAuthRoutes.map((item) => (
              <Route
                key={"Route not auth" + item.path}
                path={item.path}
                element={item.element}
              />
            ))}
            <Route path="*" element={<Login />} />
          </Routes>
        </main>
      </div>
    </>
  );
}
export default App;
